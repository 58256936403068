import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Icon } from '@mdi/react';
import { mdiEyeOff, mdiEye, mdiLoading } from '@mdi/js';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import queryString from 'querystring';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { getAffiliateName } from 'services/affiliate.service';
import { Card } from 'components/UI/Card';
// import FacebookButton from "components/FacebookButton";
// import GoogleButton from "components/GoogleButton";
import { useRouter } from 'hooks';
import {
  getLoginValidationSchema,
  restorePassValidationSchema,
  getCreateAccountValidationSchema,
} from 'utils/validation';
// import { Translate } from "react-localize-redux";
import { toRem, devices } from 'utils/styles';
import * as userActions from 'actions/user';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { isEmpty } from 'lodash';

const CONTAINER_WIDTH = 520;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: ${({ notStandardAccount }) => (notStandardAccount ? '50px 75px' : '50px 100px')};
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5rem;
`;

const StyledWelcomeMessage = styled.h1`
  font-size: ${toRem(22.3)};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
`;

const StyledSecondMessage = styled.h1`
  font-size: ${toRem(16)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: black;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledAffiliate = styled.div``;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '1rem',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
function SignUpFirstScreen({ loading, register, error }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname, history, location, query } = useRouter();
  const notStandardAccount = pathname.includes('/coheresignupa') || pathname.includes('/coheresignupb');
  const [showPassword, setShowPassword] = useState(false);
  const [client, setClient] = useState(false);
  const [inviterName, setInviterName] = useState(undefined);
  const { search } = useLocation();
  const { checkEmail, isLoadingEmail } = useIsEmailExist();
  const [signupFlow, setSignFlow] = useState({
    emailProvided: false,
    shouldSignin: false,
    shouldSignup: false,
  });
  const parsedSearch = queryString.parse(search.slice(1));
  useEffect(() => {
    if (parsedSearch.inviteCode) {
      getAffiliateName(parsedSearch.inviteCode).then(userName => setInviterName(userName));
    }
  }, [setInviterName]);
  useEffect(() => {
    if (pathname.includes('client')) {
      setClient(true);
    } else {
      setClient(false);
    }
  }, [pathname]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(state => !state);
  }, [setShowPassword]);

  const registerHandler = async ({ email, password }, actions) => {
    let type = '';
    if (pathname.includes('coheresignupa')) {
      type = SIGNUP_TYPES.TYPE_A;
    } else if (pathname.includes('coheresignupb')) {
      type = SIGNUP_TYPES.TYPE_B;
    }
    if (signupFlow.emailProvided && signupFlow.shouldSignup) {
      const onSuccess = () => {
        history.push(`${pathname}/continue`, {
          ...(location.state || {
            from: { pathname: '/dashboard' },
          }),
        });
      };
      const userView = pathname.includes('client') ? UserRoles.client : UserRoles.cohealer;
      register(email, password, parsedSearch.inviteCode, onSuccess, userView, type);
    } else if (signupFlow.emailProvided && signupFlow.shouldSignin && isEmpty(query)) {
      dispatch(userActions.loginUserSimple(email, password, false, type));
    } else if (signupFlow.emailProvided && signupFlow.shouldSignin && !isEmpty(query) && query.onboarding == 'true') {
      dispatch(
        userActions.loginUserSimple(
          email,
          password,
          false,
          type,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
      );
    } else if (!signupFlow.emailProvided) {
      const isExistEmail = await checkEmail(email);
      if (isExistEmail) {
        setSignFlow({
          ...signupFlow,
          emailProvided: true,
          shouldSignin: true,
          shouldSignup: false,
        });
      } else {
        setSignFlow({
          ...signupFlow,
          emailProvided: true,
          shouldSignin: false,
          shouldSignup: true,
        });
      }
    }
  };

  const chooseSchemas = () => {
    if (!signupFlow.emailProvided) {
      return restorePassValidationSchema();
    } else if (signupFlow.shouldSignin || signupFlow.shouldSignup) {
      return getCreateAccountValidationSchema();
    }
  };

  const submitBtn = () => {
    if (!signupFlow.emailProvided) {
      return 'Login or Create Account';
    } else if (signupFlow.emailProvided && signupFlow.shouldSignin) {
      return 'Login';
    } else if (signupFlow.emailProvided && signupFlow.shouldSignup) {
      return 'Create Account';
    }
  };

  return (
    <>
      <Card>
        <Container notStandardAccount={notStandardAccount}>
          <Logo />
          {inviterName && <StyledAffiliate>In partnership with {inviterName}</StyledAffiliate>}
          {client ? (
            <>
              <StyledWelcomeMessage>Transformation happens live</StyledWelcomeMessage>
              <StyledSecondMessage>Connect with coaches, teachers, and community</StyledSecondMessage>
            </>
          ) : (
            <>
              {notStandardAccount ? (
                <WelcomeMessage>Finish Activating Your Account To Access Your Special Offer</WelcomeMessage>
              ) : (
                <WelcomeMessage>Welcome to Cohere. We’re excited to connect</WelcomeMessage>
              )}
            </>
          )}

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={chooseSchemas()}
            onSubmit={registerHandler}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Input
                    className={classes.input}
                    label="Email"
                    type="text"
                    redError={true}
                    value={values.email}
                    onChange={e => {
                      setFieldValue('email', e.target.value.trim());
                    }}
                    name="email"
                    fullWidth
                  />
                  {signupFlow.emailProvided ? (
                    <Input
                      className={classes.input}
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      redError={true}
                      endAdornment={
                        <InputAdornment>
                          <IconButton onClick={handleClickShowPassword}>
                            <Icon path={showPassword ? mdiEye : mdiEyeOff} size={1} />
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  ) : null}

                  <ButtonsContainer>
                    {error.message && <CommonErrorMessage message={error.message} color={'red'} />}
                    <Button disabled={loading} type="submit">
                      {loading || isLoadingEmail ? <Icon path={mdiLoading} size={1} spin={1} /> : submitBtn()}
                    </Button>
                    {/* <FacebookButton /> */}
                    {/* <GoogleButton /> */}
                  </ButtonsContainer>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Card>

      <div className={classes.link}>
        {client ? (
          <StyledLink to="/auth/signup">Are you a service provider? Click here</StyledLink>
        ) : (
          <StyledLink to="/auth/signup/client">Not a service provider? Click here</StyledLink>
        )}
        <StyledLink to="/auth/signin">Already have an account? Login here.</StyledLink>
      </div>
    </>
  );
}

SignUpFirstScreen.propTypes = {
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

SignUpFirstScreen.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  loading: account?.isLoading,
  error: account?.error || {},
});

const actions = {
  register: userActions.register,
};

export default connect(mapStateToProps, actions)(SignUpFirstScreen);
