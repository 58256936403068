import React, { useState, useCallback, useEffect } from 'react';
import Button from 'components/FormUI/Button';
import styled, { css } from 'styled-components';
import { Grid, Typography, TextField } from '@mui/material';
import { toRem } from 'utils/styles';
import { lightOrDark } from 'utils/utils';
import { PAYMENT_OPTIONS } from '../../../../constants';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useHttp } from 'hooks/index';
import { handleOnChangeCoupon, handleRedeem } from '../PurchaseBlock/CustomPurchaseBlock';
import {
  determineColorToUse,
  determineDarkThemedColorToUse,
  getOneToOneSelfBookClient,
} from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getOnToOnePaymentInfo } from 'services/purchase.service';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { darken } from 'polished';
import { TemplateType } from 'helpers/constants/templateType';
import Loader from 'components/UI/Loader';
import CustomApplyCoupon from './CustomApplyCoupon';
import * as contributionActions from 'actions/contributions';

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;

const PriceTagContainer = styled.div`
  background-color: ${({ colorToUse }) => colorToUse?.PrimaryColorCode};
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const PriceTag = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  margin: 0px;
  font-size: 15px;
  padding: 2px;
  font-weight: 600;
`;

const PriceValue = styled.div`
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  font-family: 'Avenir';
  // margin-bottom: 10px;
`;

const LabelText = styled.span`
  font-size: ${toRem(18)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #000000;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 92%;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
  }}

${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

&:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
  font-size: 12px;
  padding: 8px 16px;
  min-width: 8rem;
`}
`;

const PurchaseClientInvitationFrom = ({
  singleSessionData,
  formatMoney,
  changeInvitedView,
  isInviteToJoin,
  loggedInUserCredit = false,
  handleSubmit,
}) => {
  const contribution = useContribution();
  const [couponError, setCouponError] = useState(false);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [couponValue, setCouponValue] = useState('');
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [singleSessionInvite, setSingleSessionInvite] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [couponId, setCouponId] = useState(null);
  const reload = () => window.location.reload();
  const { request } = useHttp();
  useEffect(() => {
    if (isCodeToJoin) {
      getSelfBookData();
    }
  }, []);

  const getSelfBookData = () => {
    getOneToOneSelfBookClient(isCodeToJoin.slice(2)).then(response => {
      setSingleSessionInvite(response);
    });
  };
  const handleRedeem = () => {
    setCouponError(false);
    request(`/Coupons/ValidateByName/${coupon}/${contribution.id}/PerSession`, 'GET')
      .then(res => {
        if (res?.id === undefined) {
          setCouponError(true);
        }
        if (res?.percentAmount > 0 || res?.discountAmount > 0) {
          setCouponId(res.id);
          dispatch(contributionActions.setCouponCode(res.id));
          getOnToOnePaymentInfo(activeContribution.id, 'PerSession', res.id).then(res => {
            setPaymentDetails(res);
          });
        }
      })
      .catch(() => {
        setCouponError(true);
      });
  };

  useEffect(() => {
    if (singleSessionInvite?.priceOption != 'Free' && singleSessionInvite?.priceOption != 'Credit') {
      getOnToOnePaymentInfo(activeContribution.id, 'PerSession').then(res => {
        setPaymentDetails(res);
      });
    }
  }, [singleSessionInvite]);
  let colorToUse = determineColorToUse(contribution);
  const borderRad = contribution.activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const TextColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return singleSessionInvite === null ? (
    <Loader />
  ) : (
    <>
      <PriceSection>
        <PriceTagContainer colorToUse={colorToUse}>
          <PriceTag style={{ color: TextColor }}>Session Price</PriceTag>
        </PriceTagContainer>
        <PriceValue>
          {singleSessionInvite?.priceOption != 'Free' &&
            singleSessionInvite?.priceOption != 'Credit' &&
            activeContribution?.defaultSymbol}
          {singleSessionInvite?.priceOption != 'Free' && singleSessionInvite?.priceOption != 'Credit'
            ? formatMoney(paymentDetails?.price)
            : singleSessionInvite?.priceOption === 'Credit'
            ? 'Free / Package Credit'
            : singleSessionInvite?.priceOption}
          {singleSessionInvite?.priceOption != 'Free' && singleSessionInvite?.priceOption != 'Credit' && (
            <span style={{ fontWeight: 300 }}> {activeContribution?.defaultCurrency.toUpperCase()}</span>
          )}
        </PriceValue>
      </PriceSection>
      <Grid container style={{ padding: '0px 20px' }}>
        {singleSessionInvite?.priceOption != 'Free' &&
          singleSessionInvite?.priceOption != 'Credit' &&
          !contribution?.isPriceHidden && (
            <Grid container style={{ margin: '0px 35px' }}>
              <Grid
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}
                item
              >
                <Typography
                  style={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    marginBottom: '10px',
                    fontFamily: 'Avenir',
                  }}
                  variant="body2"
                >
                  Processing Fee:
                </Typography>
                <Typography
                  style={{ fontWeight: '800', fontSize: '16px', lineHeight: '24px', fontFamily: 'Avenir' }}
                  variant="body2"
                >
                  <span style={{ fontWeight: 700 }}>
                    {' '}
                    {activeContribution?.defaultSymbol}
                    {formatMoney(paymentDetails?.platformFee)}
                  </span>

                  <span style={{ fontWeight: 300 }}> {activeContribution?.defaultCurrency.toUpperCase()}</span>
                </Typography>
              </Grid>

              <Grid
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}
                item
              >
                <Typography
                  style={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', fontFamily: 'Avenir' }}
                  variant="body2"
                >
                  Due Now:
                </Typography>
                <Typography
                  style={{ fontWeight: '800', fontSize: '16px', lineHeight: '24px', fontFamily: 'Avenir' }}
                  variant="body2"
                >
                  <span style={{ fontWeight: 700 }}>
                    {' '}
                    {activeContribution?.defaultSymbol}
                    {formatMoney(paymentDetails?.price + paymentDetails?.platformFee)}
                  </span>
                  <span style={{ fontWeight: 300 }}> {activeContribution?.defaultCurrency.toUpperCase()}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                {/* <Typography variant="body2">
              <span style={{ fontWeight: 700 }}>
                {' '}
                {singleSessionData?.defaultSymbol}
                {formatMoney(singleSessionData?.summary?.price)}{' '}
              </span>
              <span style={{ fontWeight: '300' }}> {singleSessionData?.defaultCurrency.toUpperCase()}</span>
            </Typography> */}
              </Grid>
            </Grid>
          )}

        <Grid item md={12} xs={12} style={{ padding: '5px 15px' }}>
          {singleSessionInvite?.priceOption != 'Free' &&
            singleSessionInvite?.priceOption != 'Credit' &&
            contribution.isCouponAvailable && (
              <CustomApplyCoupon
                handleChange={() => {}}
                couponField={true}
                setCoupon={setCoupon}
                activeTemplate={contribution.activeTemplate}
                colorToUse={colorToUse}
                couponError={couponError}
                handleRedeem={handleRedeem}
              />
            )}
        </Grid>
        <Grid container style={{ padding: '20px 30px 0px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              style={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'Avenir',
                marginBottom: '10px',
              }}
              variant="body2"
            >
              {' '}
              Session booked by:{' '}
              <span style={{ fontWeight: '800', fontSize: '16px', lineHeight: '24px', fontFamily: 'Avenir' }}>
                {activeContribution?.serviceProviderName}
              </span>
            </Typography>
            <Typography
              style={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'Avenir',
                marginBottom: '10px',
              }}
              variant="body2"
            >
              {' '}
              Session booked for:{' '}
              <span style={{ fontWeight: '800', fontSize: '16px', lineHeight: '24px', fontFamily: 'Avenir' }}>
                {singleSessionInvite?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
                  ? singleSessionInvite?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
                  : singleSessionInvite?.clientName}
              </span>
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontWeight: 800,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Avenir',
                fontSize: '13px',
              }}
            >
              <CalendarMonthIcon htmlColor={isDarkThemeEnabled ? 'white' : 'black'} fontSize="small" />
              {`${moment(singleSessionInvite?.selectedSlotStartDateTime).format('MMMM DD, YYYY hh:mm A ')} - ${moment(
                singleSessionInvite?.selectedSlotEndDateTime,
              ).format('hh:mm A')}`}
              {/* May 9th, 2023 09:00AM - 10:00AM */}
            </Typography>
            <Typography
              variant="body2"
              onClick={changeInvitedView}
              style={{
                fontWeight: 900,
                color: colorToUse.PrimaryColorCode,
                marginBottom: '10px',
                cursor: 'pointer',
                fontSize: '16px',
                fontFamily: 'Avenir',
                lineHeight: '24px',
              }}
            >
              Pick another time
            </Typography>
          </Grid>
        </Grid>
        {loggedInUserCredit && (
          <Grid item md={12} xs={12} style={{ padding: '5px 15px' }}>
            {/* <button type="submit">{submitTitle}</button> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                style={{ borderRadius: borderRad, marginBottom: '10px', color: TextColor }}
                backgroundColor={PrimaryColor}
                marginTop
                onClick={handleSubmit}
                activeTemplate={contribution?.activeTemplate}
              >
                Reserve
              </StyledButton>
            </div>
          </Grid>
          // <Grid container>
          //   <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          //     <Button style={{ width: '100%' }} onClick={handleSubmit} name="Join">
          //       Reserve
          //     </Button>
          //   </Grid>
          // </Grid>
        )}
      </Grid>
    </>
  );
};

export default PurchaseClientInvitationFrom;
