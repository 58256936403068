import React from 'react';
import classes from './NavigateionTabs.module.scss';
import Button from 'components/FormUI/Button';
import { useTheme, useMediaQuery } from '@material-ui/core';
const NavigateionTabs = ({ tabs, activeTab, onClickTab, colorToUse }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.container}>
      {tabs.map(tab => {
        const isActive = activeTab === tab;
        return (
          <Button
            className={mobileView ? classes.btnMobile : classes.btn}
            autoWidth
            onClick={() => {
              onClickTab(tab);
            }}
            invert={!isActive}
            backgroundColor={isActive ? colorToUse?.PrimaryColorCode : ''}
          >
            {tab}
          </Button>
        );
      })}
    </div>
  );
};

export default NavigateionTabs;
