import React from 'react';
import styled from 'styled-components';
import { ListItemTypes } from 'helpers/constants/listItemTypes';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { colors } from 'utils/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const StyledHeading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  ${({ last }) =>
    last &&
    `
  margin-bottom: 0px;
  `}
`;

const StyledDescription = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 20px;
  margin-left: 5px;
  text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
  ${({ clickAble }) =>
    clickAble
      ? `
    cursor: pointer;
  `
      : ``}
  
  display: flex;
  align-items: center;
`;

const StyldNumber = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 20px;
  margin-left: 5px;
  ${({ color }) =>
    color &&
    `
color: ${color};
`}
  ${({ clickAble }) =>
    clickAble
      ? `
cursor: pointer;
`
      : ``}
  display: flex;
  align-items: center;
`;

const ListItem = ({ islast, type, description, onClick, pointNum }) => {
  const clickAble = true;
  return (
    <StyledHeading last={islast}>
      <div>
        {type === ListItemTypes.completed ? (
          <CheckCircleIcon
            htmlColor={type === ListItemTypes.completed ? colors?.darkOceanBlue : 'rgba(33, 92, 115, 0.4)'}
            fontSize="small"
          />
        ) : (
          <RadioButtonUncheckedIcon sx={{ color: colors?.gray }} fontSize="small" />
        )}
      </div>
      <StyldNumber clickAble={clickAble} color={type != ListItemTypes.nextToBeDone ? colors?.gray : ''}>
        {pointNum}
      </StyldNumber>
      <StyledDescription
        clickAble={clickAble}
        color={type != ListItemTypes.nextToBeDone ? colors?.gray : ''}
        onClick={clickAble && onClick}
        noUnderline={type === ListItemTypes.completed}
      >
        {type === ListItemTypes.completed ? <del>{description}</del> : `${description}`}
      </StyledDescription>
    </StyledHeading>
  );
};

export default ListItem;
