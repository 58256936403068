import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Button from 'components/FormUI/Button';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';

import '../Banner.scss';
import { LiveVideoProvider } from 'constants.js';
import { openInNewWindow } from 'services/links';
import { UserRoles, ContributionType } from 'helpers/constants';
import { useContribution } from 'pages/ContributionView/hooks';
import { lightOrDark } from 'utils/utils';
import { determineColorToUse } from 'services/contributions.service';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const ClosestSession = ({
  closestClassForBanner,
  closestClassForBanner: {
    contributionId,
    contributionType,
    classGroupId,
    classId,
    title,
    minutesLeft,
    isRunning,
    chatSid,
    contributionLiveVideoServiceProvider,
    isPrerecorded,
  },
}) => {
  const { request } = useHttp();
  const { user, currentRole } = useAccount();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const { startVideoChat } = useVideoChatActions();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const isCoach = currentRole === UserRoles.cohealer;
  const [isHideBanner, setIsHideBanner] = useState(false);

  const isCustomLiveVideoServiceProvider = useMemo(() => {
    return (
      contributionLiveVideoServiceProvider &&
      contributionLiveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value
    );
  }, [contributionLiveVideoServiceProvider]);

  const onLaunchSession = useCallback(
    ({ RecordParticipantsOnConnect }) => {
      request('/Video/CreateRoomAndGetToken', 'POST', {
        contributionId,
        classId,
        RecordParticipantsOnConnect,
        identityName: user?.lastName ? `${user.firstName} ${user.lastName}` : undefined,
      }).then(({ room: { sid: roomId }, token }) =>
        startVideoChat({
          contributionId,
          type: contributionType,
          sessionId: classGroupId,
          classId,
          title,
          roomId,
          chatId: chatSid,
          token,
          deleteRoomOnVideoEnd: true,
        }),
      );
    },
    [
      user,
      request,
      startVideoChat,
      contributionId,
      contributionType,
      classGroupId,
      classId,
      title,
      chatSid,
      isCustomLiveVideoServiceProvider,
    ],
  );

  const onJoinSession = () => {
    if (!isRunning) {
      return;
    }
    if (isCustomLiveVideoServiceProvider) {
      openInNewWindow(contributionLiveVideoServiceProvider.customLink);
    } else {
      request('/Video/GetClientToken', 'POST', {
        contributionId,
        classId,
        identityName: `${user.firstName} ${user.lastName}`,
      }).then(({ token }) => startVideoChat({ contributionId, chatId: chatSid, token }));
    }
  };

  const onSubmit = () => {
    onLaunchSession({ RecordParticipantsOnConnect: true });
  };
  const onCancel = () => {
    onLaunchSession({ RecordParticipantsOnConnect: false });
  };
  const launchRecodModalAndHideBanner = () => {
    launchRecodModal(setIsHideBanner);
  };
  const modalOptions = {
    onSubmit,
    onCancel,
    classId,
    contributionType,
    contributionId,
    liveVideoServiceProvider: contributionLiveVideoServiceProvider,
    zoomStartUrl: closestClassForBanner?.zoomStartUrl,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);
  if (isHideBanner) return null;
  return (
    !isEmpty(closestClassForBanner) &&
    closestClassForBanner.title &&
    closestClassForBanner?.isPurchased === true &&
    !isPrerecorded &&
    contributionType !== ContributionType.contributionCourse && (
      <div
        className={`banner-container ${mobileView && 'banner-container__mobile'}`}
        style={contribution ? { backgroundColor: colorToUse.AccentColorCode } : {}}
      >
        <p style={contribution ? { color: textColor } : {}} className="banner-container__text">{`${
          contributionType === ContributionType.contributionOneToOne ? 'Session with ' : ''
        }${title} is starting in ${minutesLeft}
        minutes.`}</p>
        {isCoach ? (
          <Button
            backgroundColor={colorToUse.AccentColorCode}
            textColor={textColor}
            variant="primary"
            className="banner-container__button"
            autoWidth
            onClick={launchRecodModalAndHideBanner}
          >
            Start Session
          </Button>
        ) : (
          <BannerButton
            backgroundColor={colorToUse.AccentColorCode}
            textColor={textColor}
            variant={isRunning ? '' : 'primary'}
            className="banner-container__button"
            autoWidth
            onClick={onJoinSession}
          >
            {isRunning ? 'Join Session' : 'Stand By'}
          </BannerButton>
        )}
        <RecordModal />
      </div>
    )
  );
};

ClosestSession.defaultProps = {
  closestClassForBanner: {},
};

ClosestSession.propTypes = {
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string,
    type: PropTypes.string,
    classGroupId: PropTypes.string,
    classId: PropTypes.string,
    title: PropTypes.string,
    minutesLeft: PropTypes.number,
    contributionType: PropTypes.string,
    isRunning: PropTypes.bool,
    chatSid: PropTypes.string,
  }),
};

export default ClosestSession;
