import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classes from './Contacts.module.scss';
import Contact from '../Contact/Contact';
import useClientNote from 'hooks/useClientNote';
import NotesModal from 'components/Notes/NotesModal';
import { updateClientNotes } from 'services/clients.service';
import { useDispatch, useSelector } from 'react-redux';
import useSaveOnTypping from 'pages/ContributionView/components/NoteModal/hooks/useSaveOnTypping';
import { setCloseDisabled, setNotesStatus } from 'actions/contributions';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
    padding: 10,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const ContactsTable = ({ contacts = [], isChecked, onCheckClicked }) => {
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const { user } = useAccount();
  const [contactWithOpenDropDown, setContactWithOpenDropDown] = useState(null);
  const [openNotesOf, setOpenNotesOf] = useState(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { saveOnTyping } = useSaveOnTypping();
  const closeDisabled = useSelector(state => state?.contributions?.closeDisabled);
  const setId = id => {
    setContactWithOpenDropDown(id);
  };

  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    isNoteExist,
  } = useClientNote(openNotesOf);
  const handleSubmit = () => {
    setLoadingNotes(true);
    updateClientNotes({ clientId: openNotesOf, userId: user.id, note: editorDataUpdated || '' }).finally(() => {
      setLoadingNotes(false);
      setOpenNotesOf(null);
      setShowNotes(false);
      dispatch(setNotesStatus(''));
      dispatch(setCloseDisabled(false));
    });
  };
  useEffect(() => {
    if (closeDisabled) {
      saveOnTyping({ clientId: openNotesOf, userId: user.id, note: editorDataUpdated });
    }
  }, [editorDataUpdated]);

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Full Name</BoldTableCell>
              <BoldTableCell>Email</BoldTableCell>
              <BoldTableCell>Contributions/Source</BoldTableCell>
              <BoldTableCell>Revenue</BoldTableCell>
              <BoldTableCell>Subscribed</BoldTableCell>
              <BoldTableCell>Tags</BoldTableCell>
              <BoldTableCell>Type</BoldTableCell>
              <BoldTableCell align="center">Chat</BoldTableCell>
              <BoldTableCell align="center">Notes</BoldTableCell>
              <BoldTableCell>Action</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts?.map((row, index) => (
              <Contact
                key={index}
                index={index}
                row={row}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                contactWithOpenDropDown={contactWithOpenDropDown}
                setId={setId}
                setOpenNotesOf={setOpenNotesOf}
                totalCount={contacts.length}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setLoading={setLoadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={setOpenNotesOf}
        onSave={() => {
          handleSubmit();
        }}
        isNoteExist={isNoteExist}
      />
    </>
  );
};

export default ContactsTable;
