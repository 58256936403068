import React, { useEffect, useMemo, useState } from 'react';
import sumBy from 'lodash/sumBy';
import PropTypes, { bool } from 'prop-types';
import range from 'lodash/range';
import orderBy from 'lodash/orderBy';
import BookSessionTime from './Session/BookButton/BookSessionTime';
import Session from './Session';
import { FILTER_TABS_OPTIONS, SESSIONS_TYPES } from '../../constants.js';
import { TemplateType } from 'helpers/constants/templateType';
import { useMediaQuery, useTheme } from '@material-ui/core';
// import "./SessionsContainer.scss";
import { determineColorToUse } from 'services/contributions.service';
import { useDispatch } from 'react-redux';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import { setCouponCode, setRescheduleFormerSessionDetail } from 'actions/contributions';
import { setEasyBookingData } from 'actions/easyBooking';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parseQuery } from 'utils/parseData';
import * as R from 'ramda';

const getBookedTimes = R.chain(R.prop('bookedTimes'));

const renderSessions = (
  {
    type,
    activeTemplate,
    packagePurchases,
    availabilityTimes,
    clientPackages,
    id,
    isCustomBrandingColorsActive,
    coachSelectedBrandingColors,
    brandingColors,
    timeZoneId,
  },
  isCoach,
  renderActions,
  calendarEl,
  executeCalendarScroll,
  setRescheduleMode,
  rescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  completionFilter,
  user,
  filteredClients,
  setShowModal,
  showModal,
  setShowRescheduleModal,
  showRescheduleModal,
  setRescheduleSession,
  rescheduleSession,
  showCount,
  setShowCount,
  dispatch,
  selectedSession,
  setSelectedSession,
  setSelectedFilterTab,
) => {
  const contr = {
    isCustomBrandingColorsActive,
    coachSelectedBrandingColors,
    brandingColors,
  };
  let colorToUse = determineColorToUse(contr);
  const shouldChangeStyle = !isCoach && activeTemplate != TemplateType.TemplateThree;
  const btnColor = shouldChangeStyle ? colorToUse.PrimaryColorCode : '#C9B382';
  const handleBook = () => {};

  const computeSessions = () => {
    let resultant = [];
    for (let time of availabilityTimes) {
      for (let booked of time.bookedTimes) {
        let shouldInclude = false;
        if (isCoach && booked.participantId) {
          shouldInclude = true;
        } else if (booked.participantId === user?.id && booked.isPurchaseConfirmed) {
          shouldInclude = true;
        }
        if (shouldInclude) {
          if (completionFilter === SESSIONS_TYPES.COMPLETED) {
            if (booked.isCompleted) shouldInclude = true;
            else shouldInclude = false;
          } else if (completionFilter === SESSIONS_TYPES.PENDING) {
            if (booked.isPurchaseConfirmed || booked.isCompleted) shouldInclude = false;
            else shouldInclude = true;
          } else if (completionFilter === SESSIONS_TYPES.UPCOMING) {
            if (booked.isCompleted || booked.isPurchaseConfirmed === false) shouldInclude = false;
            else shouldInclude = true;
          }
        }

        if (shouldInclude) {
          let temp = {
            ...booked,
            startTime: time.startTime,
            timeId: time.id,
          };
          resultant.push(temp);
        }
      }
    }
    if (clientPackages) {
      for (let i = 0; i < range(Math.max(sumBy(clientPackages, 'freeSessionNumbers'), 0)).length; i++) {
        resultant.push({
          hasPackage: true,
          index: i,
        });
      }

      let filteredResultant = [];
      if (filteredClients?.length > 0) {
        filteredResultant = resultant?.filter(s => filteredClients.includes(s.participantId));
      } else {
        filteredResultant = resultant;
      }
      let prevSessionIndex = null;
      const outputArray = filteredResultant.map((item, index) => {
        if (item.sessionIndex !== undefined) {
          prevSessionIndex = item.sessionIndex;
          return { ...item, index: prevSessionIndex };
        } else {
          return { ...item, index: prevSessionIndex + 1 + index };
        }
      });

      if (selectedSession && outputArray.filter(session => session.id === selectedSession)?.length === 0) {
        const bookedTimes = getBookedTimes(availabilityTimes);
        if (bookedTimes.filter(session => session.id === selectedSession)?.length > 0) {
          setSelectedFilterTab(FILTER_TABS_OPTIONS[2].value);
        } else {
          setSelectedSession(null);
        }
      }

      return orderBy(outputArray, ['startTime'], ['asc']).map((booked, secInd) => {
        if (booked.hasPackage) {
          return (
            completionFilter != 'completed' && (
              <Session
                key={booked.index}
                number={secInd + 1}
                contributionId={id}
                packagePurchases={packagePurchases}
                rescheduleMode={rescheduleMode}
                timeDetails={booked}
                contributionTimeZoneId={timeZoneId}
                setRescheduleMode={setRescheduleMode}
                executeCalendarScroll={executeCalendarScroll}
                setCurrentRescheduleSession={setCurrentRescheduleSession}
                calendarEl={calendarEl}
                selectedSession={selectedSession}
                setSelectedSession={setSelectedSession}
                renderActions={() =>
                  booked?.hasPackage && (
                    <>
                      {' '}
                      <BookSessionTime
                        backgroundColor={btnColor}
                        onSubmit={() => {
                          dispatch(setEasyBookingThankYouData.setData({ selectedSlot: null, selectedDate: moment() }));
                          dispatch(setEasyBookingData.setData({ selectedSlot: null, selectedDate: moment() }));
                          dispatch(setRescheduleFormerSessionDetail({}));
                          setRescheduleMode(false);
                          setShowModal(true);
                          setShowCount(secInd);
                          dispatch(setCouponCode(null));
                        }}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        rescheduleSession={rescheduleSession}
                        setRescheduleSession={setRescheduleSession}
                        showCount={showCount}
                        setShowCount={setShowCount}
                        index={secInd}
                      />
                    </>
                  )
                }
              />
            )
          );
        } else {
          return (
            <Session
              key={booked.id}
              number={secInd + 1}
              session={booked}
              packagePurchases={packagePurchases}
              rescheduleMode={rescheduleMode}
              contributionId={id}
              timeDetails={booked}
              renderActions={renderActions}
              contributionTimeZoneId={timeZoneId}
              executeCalendarScroll={executeCalendarScroll}
              setRescheduleMode={setRescheduleMode}
              setCurrentRescheduleSession={setCurrentRescheduleSession}
              serviceProviderName={serviceProviderName}
              calendarEl={calendarEl}
              selectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
            />
          );
        }
      });
    }
  };

  // const realSessions = orderBy(availabilityTimes, ['startTime'], ['asc']).map((time, index) => {
  //   const userTimes = isCoach
  //     ? time.bookedTimes.filter(booked => booked.participantId && booked.isPurchaseConfirmed)
  //     : time.bookedTimes.filter(booked => booked.participantId === user?.id && booked.isPurchaseConfirmed);
  //   return userTimes.map((booked, secInd) => (
  //     <Session
  //       key={booked.id}
  //       number={index + 1}
  //       session={booked}
  //       packagePurchases={packagePurchases}
  //       rescheduleMode={rescheduleMode}
  //       contributionId={id}
  //       renderActions={renderActions}
  //       contributionTimeZoneId={timeZoneId}
  //       executeCalendarScroll={executeCalendarScroll}
  //       setRescheduleMode={setRescheduleMode}
  //       setCurrentRescheduleSession={setCurrentRescheduleSession}
  //       serviceProviderName={serviceProviderName}
  //     />
  //   ));
  // });

  // if (clientPackages) {
  //   range(sumBy(clientPackages, 'freeSessionNumbers')).map(i => {
  //     realSessions.push(
  //       <Session
  //         key={i}
  //         number={i + 1 + clientPackages[0]?.bookedSessionNumbers}
  //         contributionId={id}
  //         packagePurchases={packagePurchases}
  //         rescheduleMode={rescheduleMode}
  //         contributionTimeZoneId={timeZoneId}
  //         setRescheduleMode={setRescheduleMode}
  //         executeCalendarScroll={executeCalendarScroll}
  //         setCurrentRescheduleSession={setCurrentRescheduleSession}
  //         renderActions={() => i === 0 && <BookSessionTime backgroundColor={btnColor} onSubmit={handleBook} />}
  //       />,
  //     );
  //   });
  // }

  return computeSessions();
};

const SessionsContainer = ({
  contribution,
  isCoach,
  renderActions,
  calendarEl,
  executeCalendarScroll,
  setRescheduleMode,
  rescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  completionFilter,
  filteredClients,
  user,
  setSelectedFilterTab,
}) => {
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCount, setShowCount] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedSession, setSelectedSession] = useState(parseQuery(location?.search)?.sessionId ?? null);

  useEffect(() => {
    if (selectedSession === null) {
      window.history.replaceState({}, '', location.pathname);
    }
  }, [selectedSession]);

  const renderedSessions = useMemo(() => {
    return renderSessions(
      contribution,
      isCoach,
      renderActions,
      calendarEl,
      executeCalendarScroll,
      setRescheduleMode,
      rescheduleMode,
      setCurrentRescheduleSession,
      serviceProviderName,
      completionFilter,
      user,
      filteredClients,
      setShowModal,
      showModal,
      setShowRescheduleModal,
      showRescheduleModal,
      setRescheduleSession,
      rescheduleSession,
      showCount,
      setShowCount,
      dispatch,
      selectedSession,
      setSelectedSession,
      setSelectedFilterTab,
    );
  }, [
    contribution,
    isCoach,
    renderActions,
    calendarEl,
    executeCalendarScroll,
    setRescheduleMode,
    rescheduleMode,
    setCurrentRescheduleSession,
    serviceProviderName,
    completionFilter,
    user,
    filteredClients,
    showModal,
    showRescheduleModal,
    rescheduleSession,
    showCount,
    selectedSession,
    setSelectedFilterTab,
  ]);

  return <div>{renderedSessions}</div>;
};

SessionsContainer.defaultProps = {
  contribution: {
    id: null,
    availabilityTimes: [],
    clientPackages: [{}],
  },
  calendarEl: null,
  serviceProviderName: null,
};

SessionsContainer.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    availabilityTimes: PropTypes.arrayOf(PropTypes.object),
    clientPackages: PropTypes.arrayOf(PropTypes.shape({ isCompleted: PropTypes.bool })),
    paymentInfo: PropTypes.shape({
      paymentOptions: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  calendarEl: PropTypes.shape({}),
  renderActions: PropTypes.func.isRequired,
  setRescheduleMode: PropTypes.func.isRequired,
  executeCalendarScroll: PropTypes.func.isRequired,
  setCurrentRescheduleSession: PropTypes.func.isRequired,
  serviceProviderName: PropTypes.string,
  completionFilter: PropTypes.string.isRequired,
  setSelectedFilterTab: PropTypes.func.isRequired,
};

export default SessionsContainer;
