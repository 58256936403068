import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Icon } from '@mdi/react';
import { mdiChat, mdiDelete } from '@mdi/js';
import styled from 'styled-components';
import useShallowEqualSelector from '../../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../../constants';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { contributionParticipants, determineColorToUse, getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { ContributionType } from 'helpers/constants';
import { fetchCohealerContribution } from 'actions/contributions';
import * as contributionActions from 'actions/contributions';
import Button from 'components/FormUI/Button';
import { MemberPodsAssignment, MemberPodsCreation, useLoadMemberPodsByContribution } from 'components/MemberPods';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import { usePartnerCoach, useRouter } from 'hooks';
import { getThemedColors, deleteClient } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import chatService from 'services/chat.service';
import CloseIcon from '@material-ui/icons/Close';
import { UserRoles, ContributionThemedColors } from 'helpers/constants';
import useAccount from 'hooks/useAccount';
import usePaidTier from 'hooks/usePaidTier';

import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { addhttp } from 'utils/utils';
import ChatIcon from '@material-ui/icons/Chat';
import { useStyles } from './hooks/useStyles';

const ClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 0 auto;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  justify-content: space-between;
  align-items: center;
`;

const UserWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 60px !important;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
  height: 2rem !important;
  width: 2rem !important;
`;

const CourseClients = () => {
  const { history, domain } = useRouter();
  const dispatch = useDispatch();
  const { currentRole, user } = useAccount();
  const [participants, setParticipants] = useState([]);
  const contribution = useSelector(state => state.contributions?.activeContribution);
  // const { isScalePlan } = usePaidTier();

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  let colorToUse = determineColorToUse(contribution);
  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  const goToViewPage = profile => {
    if (profile.isLead) {
      redirectToAppIfIsCoachDomain(domain, `/lead/${profile.id}`);
    } else {
      const fullName = `${profile?.firstName} ${profile?.lastName}`;
      redirectToAppIfIsCoachDomain(domain, `/contacts/all/${profile?.id}/${fullName}/${profile?.email}`);
    }
  };

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);
  useLoadMemberPodsByContribution({ contributionId: contribution.id, userId: user.id });

  const { partnerCoaches } = usePartnerCoach(contribution.id);
  const isPartnerCoach = partnerCoaches.some(obj => obj.userId === user.id);
  const isMemberPodsFeatureAvailable = currentRole === UserRoles.cohealer && isScalePlan && !isPartnerCoach;
  const isHasAccessRemove =
    user.id === contribution.userId || find(contribution.contributionPartners, { userId: user.id });
  const participantsLoader = useSelector(state => state.contributions?.loadingParticipants);
  const contributionId = useSelector(state => state.contributions.activeContribution.id);
  const [isOpenModalCreatePod, setIsOpenModalCreatePod] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isNotAllowedConfirmation, setIsNotAllowedConfirmation] = useState(false);
  const [participantIdToDelete, setParticipantIdToDelete] = useState('');
  const [participantNameToDelete, setParticipantNameToDelete] = useState('');
  const { themedColor, themedBackgroundColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const styledProps = {
    backgroundColor: themedBackgroundColor,
    color: themedColor,
  };
  const classNames = useStyles(styledProps);

  const onStartDirectChat = useCallback(
    participantId => {
      window.location.href = addhttp(domain?.concat(`/conversations/all?chatId=${participantId}`));
    },
    [domain],
  );

  useEffect(() => {
    contributionParticipants(contributionId).then(res => {
      setParticipants(res);
      let contrib = {
        ...contribution,
        participants: res,
      };
      dispatch(contributionActions.fetchContributionActions.success(contrib));
      dispatch(contributionActions.setLoadingParticipants(false));
      setIsOpenConfirmation(false);
    });
  }, []);

  const handleDeleteParticipant = useCallback(() => {
    deleteClient(contributionId, participantIdToDelete)
      .then(() => {
        dispatch(contributionActions.setLoadingParticipants(true));
        contributionParticipants(contributionId).then(res => {
          setParticipants(res);
          let contrib = {
            ...contribution,
            participants: res,
          };
          dispatch(contributionActions.fetchContributionActions.success(contrib));
          dispatch(contributionActions.setLoadingParticipants(false));
          setIsOpenConfirmation(false);
        });
      })
      .catch(() => {
        setIsNotAllowedConfirmation(true);
      });
  }, [contributionId, dispatch, participantIdToDelete, contribution]);

  const handleOpenConfirmation = useCallback((participantId, firstName, lastName) => {
    setIsOpenConfirmation(true);
    setParticipantNameToDelete(`${firstName} ${lastName}`);
    setParticipantIdToDelete(participantId);
  }, []);
  const handleCancel = useCallback(() => {
    setIsOpenConfirmation(false);
  }, []);
  const handleCancelNotAlowed = useCallback(() => {
    setIsOpenConfirmation(false);
    setIsNotAllowedConfirmation(false);
  }, []);

  return (
    <Grid
      style={{ backgroundColor: themedCardBackgroundColor }}
      className={classNames.wrapper}
      container
      direction="column"
    >
      <Grid alignItems="center" className={classNames.headerWrapper} container justify="space-between">
        <Typography className={classNames.header}>Clients ({participants.length})</Typography>
        {currentRole === UserRoles.cohealer && (
          <Button
            autoWidth
            style={{ backgroundColor: !isMemberPodsFeatureAvailable ? '#ededed' : colorToUse?.PrimaryColorCode }}
            className={classNames.button}
            disabled={!isMemberPodsFeatureAvailable}
            onClick={() => setIsOpenModalCreatePod(true)}
          >
            Create Cohorts
          </Button>
        )}
      </Grid>
      <Grid>
        {participantsLoader && (
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '20px' }}>
            {' '}
            <CircularProgress />
          </div>
        )}
        {participantsLoader === false &&
          participants.map(item => (
            <ClientContainer key={item.id}>
              <UserWrapper className={classNames.listItem} key={item.id}>
                <Avatar
                  onClick={() => {
                    goToViewPage(item);
                  }}
                  style={{ cursor: 'pointer' }}
                  src={item.avatarUrl}
                >
                  {`${item.firstName[0]}${item.lastName[0]}`.toUpperCase()}
                </Avatar>
                <Typography
                  onClick={() => {
                    goToViewPage(item);
                  }}
                  style={{ cursor: 'pointer' }}
                  className={classNames.listItemName}
                >
                  {item.firstName} {item.lastName}
                </Typography>
              </UserWrapper>
              <IconButton
                onClick={() => onStartDirectChat(item.id)}
                className="pl-0 py-0"
                style={{ color: themedColor }}
                fontSize="small"
              >
                <ChatIcon />
              </IconButton>
              {isMemberPodsFeatureAvailable && <MemberPodsAssignment contributionParticipant={item} />}
              {isHasAccessRemove && (
                // <StyledCloseIcon onClick={() => handleOpenConfirmation(item.id, item.firstName, item.lastName)} />
                <StyledIcon
                  path={mdiDelete}
                  size={2}
                  onClick={() => handleOpenConfirmation(item.id, item.firstName, item.lastName)}
                />
              )}
            </ClientContainer>
          ))}
      </Grid>
      {isOpenConfirmation && (
        <Modal
          applyTheming
          isOpen={isOpenConfirmation}
          onCancel={handleCancel}
          onSubmit={handleDeleteParticipant}
          title="Remove client"
        >
          <LabelText style={{ color: themedColor }}>
            Are you sure you wish to remove {participantNameToDelete}?
          </LabelText>
        </Modal>
      )}
      {isNotAllowedConfirmation && (
        <Modal
          isOpen={isNotAllowedConfirmation}
          onSubmit={handleCancelNotAlowed}
          submitTitle={'Ok'}
          hiddenCancel
          title="Not Allowed"
        >
          <LabelText>Only coaches are allowed to remove participants.</LabelText>
        </Modal>
      )}
      {isMemberPodsFeatureAvailable && (
        <Modal
          cancelInvert={false}
          cancelTitle="Close"
          disableConfirm
          disableOverlayClick
          isOpen={isOpenModalCreatePod}
          onCancel={() => setIsOpenModalCreatePod(false)}
          title="Create Cohorts"
          applyTheming
          contribution={contribution}
          disableFooter
        >
          <MemberPodsCreation />
        </Modal>
      )}
    </Grid>
  );
};

CourseClients.propTypes = {
  contribution: PropTypes.shape({
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUrl: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default CourseClients;
