import Button from 'components/FormUI/Button';
import Modal from 'components/UI/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { redirectTo } from 'services/links';
import { DatePicker } from '@material-ui/pickers';
import { getCoachContributionTimes, getContribution } from 'services/contributions.service';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { isArray, uniqBy } from 'lodash';
import useAccount from 'hooks/useAccount';
import { CONTRIBUTION_COLORS, TIMEZONES, TOOLTIP } from 'constants.js';
import Loader from 'components/UI/Loader';
import { isEmpty } from 'ramda';
import { colors } from 'utils/styles';
import { date } from 'yup';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
import { Checkbox, FormControlLabel, Grid, List, ListItem, Tooltip, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import GoogleColorIcon from 'assets/google-color-icon.png';
import isEmail from 'validator/lib/isEmail';
import { FieldArray, Form } from 'formik';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';

const FormControlLabelStyled = styled(FormControlLabel)`
  /* align-items: flex-start;

  .MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.87);
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 1.5rem;

    display: inline-block;
    max-width: 635px;
    width: 100%;
    word-wrap: break-word;
  } */
`;

const BlueCheckbox = withStyles({
  root: {
    padding: '0 9px',
    '&$checked': {
      color: colors.darkOceanBlue,
    },
    '&$disabled': {
      color: colors.gray,
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const ListItemStyled = props => {
  const { className, children, isStepsMode } = props;

  return <ListItem className={classnames(className, 'list-item', isStepsMode && 'steps-mode')}>{children}</ListItem>;
};
ListItemStyled.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isStepsMode: PropTypes.bool,
};
ListItemStyled.defaultProps = {
  className: '',
  children: <></>,
  isStepsMode: false,
};

const ExternalCalendarSettingsPopupContent = ({
  connectedCalendar,
  showConfirmationMakeDefaultForGroup,
  setShowConfirmationMakeDefaultForGroup,
  showConfirmationMakeDefaultForOneToOne,
  setShowConfirmationMakeDefaultForOneToOne,
  showExternalCalendarConnectionCompleteStepsModal,
  setShowExternalCalendarConnectionCompleteStepsModal,
  formikProps,
  oneToOneDefaultExists,
}) => {
  const { values, handleChange, setFieldValue, handleReset } = formikProps;
  const inStepsMode =
    showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
    showExternalCalendarConnectionCompleteStepsModal.showStep2;
  const chooseProviderIcon = mailProvider => {
    switch (mailProvider) {
      case 'gmail':
        return GoogleColorIcon;
      default:
        return null;
    }
  };
  const ProviderImg = chooseProviderIcon(connectedCalendar?.provider);
  return (
    <>
      {!inStepsMode && (
        <div className="account-summary">
          {ProviderImg && <img className="provider-logo" src={ProviderImg} alt="provider-logo" />}
          <div className="email-address-title">{connectedCalendar?.emailAddress}</div>
        </div>
      )}
      <div className="account-settings">
        {(!inStepsMode || showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
          <>
            {showExternalCalendarConnectionCompleteStepsModal.showStep2 && (
              <div className={classnames('list-item step-description', inStepsMode && 'steps-mode')}>
                Select which sub-calendars from your&nbsp;
                <a className="email" href={`mailto:${connectedCalendar?.emailAddress}`}>
                  {connectedCalendar?.emailAddress}
                </a>
                &nbsp;account that you want to display on Cohere.
              </div>
            )}
            <List className="sub-group sub-group--sub-calendars">
              {!showExternalCalendarConnectionCompleteStepsModal.showStep2 && (
                <ListItemStyled isStepsMode={inStepsMode}>
                  <div style={{ display: 'flex', gap: '0.25rem' }}>
                    <div className="sub-group-title">Sub-calendars</div>
                    <Tooltip
                      placement="right"
                      title="Sub calendars are supported with Google calendars only."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                </ListItemStyled>
              )}
              <FieldArray name="subCalendars">
                {fieldArrayHelpers => {
                  const subCalendarsChangeHandler = inx => e => {
                    const { push, remove, replace, form } = fieldArrayHelpers;
                    const {
                      target: { name, checked, value },
                    } = e;
                    // push(value);
                    // setFieldValue('subCalendars', [...(values.subCalendars||[]), value])
                    const updatedOne = {
                      ...values?.subCalendars?.at(inx),
                      isSelected: checked,
                    };
                    replace(inx, updatedOne);
                    // const updatedSubCalendars = values?.subCalendars?.map((k, i) => (i === inx ? updatedOne : k));
                    // setFieldValue('subCalendars', updatedSubCalendars);
                    // if (checked) {
                    //   fieldArrayHelpers.push(value);
                    // } else {
                    //   fieldArrayHelpers.remove(inx);
                    // }
                  };
                  return isArray(values.subCalendars) && values.subCalendars?.length ? (
                    values.subCalendars.map((subCal, inx) => {
                      const isEmailAddress = isEmail(subCal?.name || '') || false;
                      return (
                        <ListItemStyled key={inx} isStepsMode={inStepsMode} className="step-description">
                          <div className="setting-item">
                            <FormControlLabelStyled
                              name={`subCalendars[${inx}]`}
                              control={<BlueCheckbox color="primary" />}
                              label={
                                <>
                                  {isEmailAddress ? (
                                    <a href={`mailto:${subCal?.name}`}>{subCal?.name}</a>
                                  ) : (
                                    subCal?.name
                                  )}
                                  {subCal?.is_primary && <span className="primary-calendar-label">(Primary)</span>}
                                </>
                              }
                              value={subCal?.id}
                              onChange={subCalendarsChangeHandler(inx)}
                              checked={subCal?.is_primary || subCal?.isSelected || false}
                              disabled={subCal?.is_primary}
                            />
                          </div>
                        </ListItemStyled>
                      );
                    })
                  ) : (
                    <ListItemStyled isStepsMode={inStepsMode}>
                      <div>No sub-calendars available</div>
                    </ListItemStyled>
                  );
                }}
              </FieldArray>
            </List>
          </>
        )}
        {(!inStepsMode || showExternalCalendarConnectionCompleteStepsModal.showStep1) && (
          <>
            {showExternalCalendarConnectionCompleteStepsModal.showStep1 && (
              <div className={classnames('list-item step-description', inStepsMode && 'steps-mode')}>
                Please finish customizing the settings for your connected calendar on cohere.
              </div>
            )}
            <List className="sub-group sub-group--1-1-services">
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="sub-group-title">1:1 Services</div>
              </ListItemStyled>
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="setting-item">
                  <FormControlLabelStyled
                    name="preventBookingConflict"
                    control={<BlueCheckbox color="primary" />}
                    label="Synchronize with this calendar to prevent double booking"
                    onChange={handleChange}
                    checked={values?.preventBookingConflict}
                    disabled={false}
                  />
                </div>
              </ListItemStyled>
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="setting-item">
                  <FormControlLabelStyled
                    name="defaultForOneToOne"
                    control={<BlueCheckbox color="primary" />}
                    label="Make this my default calendar for 1:1 invites on Cohere for draft and new services"
                    onChange={e => {
                      if (e.target.checked && oneToOneDefaultExists) {
                        return setShowConfirmationMakeDefaultForOneToOne(true);
                      }
                      return handleChange(e);
                    }}
                    checked={values?.defaultForOneToOne}
                    disabled={false}
                  />
                </div>
              </ListItemStyled>
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="setting-item">
                  <FormControlLabelStyled
                    name="isDefaultForAllOneToOneContributions"
                    control={<BlueCheckbox color="primary" />}
                    label="Make this my default calendar for 1:1 invites for existing services. "
                    onChange={handleChange}
                    checked={values?.isDefaultForAllOneToOneContributions}
                    disabled={false}
                  />
                </div>
              </ListItemStyled>
            </List>
            <List className="sub-group sub-group--group-services">
              <ListItemStyled isStepsMode={inStepsMode}>
                <div style={{ display: 'flex', gap: '0.25rem' }}>
                  <div className="sub-group-title">Group Services</div>
                  <Tooltip
                    placement="right"
                    title="If checked, calendar invites for group services will be sent from your connected calendar and your clients will see each others emails. Please note, if left unchecked, your clients will still receive a calendar invite from noreply@cohere.live & will ensure client privacy."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                  </Tooltip>
                </div>
              </ListItemStyled>
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="setting-item">
                  <FormControlLabelStyled
                    name="defaultForGroup"
                    control={<BlueCheckbox color="primary" />}
                    label={
                      <>
                        Make this my default calendar for group service calendar invites&nbsp;
                        {/* <span className="not-recommended">(not recommended)</span> */}
                      </>
                    }
                    onChange={e =>
                      e?.target?.checked ? setShowConfirmationMakeDefaultForGroup(true) : handleChange(e)
                    }
                    checked={values?.defaultForGroup}
                    disabled={false}
                  />
                </div>
              </ListItemStyled>
            </List>
            <List className="sub-group sub-group--general-calendar-settings">
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="sub-group-title">General Calendar Settings</div>
              </ListItemStyled>
              <ListItemStyled isStepsMode={inStepsMode}>
                <div className="setting-item">
                  <FormControlLabelStyled
                    name="showEventsInInternalCalendar"
                    control={<BlueCheckbox color="primary" />}
                    label={<>I&apos;d like to view my external calendar events inside my Cohere Dashboard Calendar</>}
                    onChange={handleChange}
                    checked={values?.showEventsInInternalCalendar}
                    disabled={false}
                  />
                </div>
              </ListItemStyled>
            </List>
          </>
        )}
      </div>
    </>
  );
};

ExternalCalendarSettingsPopupContent.propTypes = {
  connectedCalendar: PropTypes.object,
  showConfirmationMakeDefaultForGroup: PropTypes.bool,
  setShowConfirmationMakeDefaultForGroup: PropTypes.func,
  showConfirmationMakeDefaultForOneToOne: PropTypes.bool,
  setShowConfirmationMakeDefaultForOneToOne: PropTypes.func,
  showExternalCalendarConnectionCompleteStepsModal: PropTypes.object,
  setShowExternalCalendarConnectionCompleteStepsModal: PropTypes.func,
  formikProps: PropTypes.object,
  oneToOneDefaultExists: PropTypes.bool,
};

ExternalCalendarSettingsPopupContent.defaultProps = {
  connectedCalendar: null,
  showConfirmationMakeDefaultForGroup: false,
  setShowConfirmationMakeDefaultForGroup: () => {},
  showConfirmationMakeDefaultForOneToOne: false,
  setShowConfirmationMakeDefaultForOneToOne: () => {},
  showExternalCalendarConnectionCompleteStepsModal: { showStep1: false, showStep2: false },
  setShowExternalCalendarConnectionCompleteStepsModal: () => {},
  oneToOneDefaultExists: false,
};

export default ExternalCalendarSettingsPopupContent;
