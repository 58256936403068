import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ProgressBar from '@ramonak/react-progress-bar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TemplateType } from 'helpers/constants/templateType';
import { mdiDelete } from '@mdi/js';
import { Icon } from '@mdi/react';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import SessionTime from './SessionTime';
import { BottomPanel } from './BottomPanel/BottomPanel';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import './Session.scss';
import { makeStyles, Typography, Tooltip, Grid } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import useAccount from 'hooks/useAccount';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { toRem, colors } from 'utils/styles';
import { TIMEZONES, TOOLTIP } from 'constants.js';
import { contributionParticipants, deleteOneToOneSession, determineColorToUse } from 'services/contributions.service';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { fetchTimeZones } from 'actions/timeZone';
import { useShallowEqualSelector } from 'hooks';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getThemedColors } from 'services/contributions.service';
import {
  fetchCohealerContribution,
  fetchContributionActions,
  setLoadingParticipants,
  setMyAvailabilityTime,
  setRescheduleFormerSessionDetail,
  setRescheduleModal,
  setRescheduleParticipantInfo,
} from 'actions/contributions';
import { useRouter } from 'hooks';
import TextArea from 'components/FormUI/TextArea';
import EasyBooking from '../../EasyBooking/EasyBooking';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import SessionBookingThankyou from '../../EasyBooking/components/SessionBookingThankyou';
import { getItem } from 'services/localStorage.service';
import { setEasyBookingData } from 'actions/easyBooking';
import Button from 'components/FormUI/Button';
import getInitials from 'utils/getInitials';
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime';

const Row = styled.div`
  display: flex;
`;

const StyledSessionRemaining = styled.div`
  color: ${({ clr }) => clr};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AvatarComponentForSession = styled(Avatar)`
  width: 55px;
  height: 55px;
  border-radius: ${({ mobileView }) => mobileView && '27px'};
`;

const StyledTimeZoneLabel = styled.a`
  display: inline-block;
  font-size: ${({ mobileView }) => (mobileView ? '10px' : '12.9px')};
  background-color: ${({ color }) => color};
  padding: ${({ mobileView }) => (mobileView ? '4px 5px' : '4px 8px')};
  border-radius: 4px;
  margin-right: ${({ mobileView }) => mobileView && '4px'};
  cursor: pointer;
  color: white;
  font-weight: 400;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  font-family: Avenir;
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.15px;
  resize: none;
  color: #000000de;
  &:focus-visible {
    outline: none;
  }
`;
const StytledSessionInfo = styled.div`
  font-size: ${({ expanded }) => (expanded ? '10px' : '12.9px')};
  font-weight: 400;
  font-family: Avenir;
  color: #4a4a4a;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: ${({ mobileView }) => (mobileView ? '4px 5px' : '4px 8px')};
`;
const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
`;
const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  ${({ mobileView }) => mobileView && 'width : 65%'};
`;
const useStyles = makeStyles(theme => ({
  round: {
    position: 'inherit',
  },
  iconButton: {
    '& .MuiIconButton-root': {
      padding: '0px',
      position: 'absolute',
      right: '22px',
      top: '30px',
    },
  },
}));
const StyledDateView = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledOpenClosePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const StyledRescheduleBtn = styled.button`
  background-color: white;
  width: 100%;
  padding: 11px 24px;
  border-radius: 5px;
  margin-top: 10px;
`;
const getInitialSymbol = R.compose(R.toUpper, R.head);
const getTimeZoneLabel = ({ contributionTimeZoneId, timeZones = [] }) => {
  const timeZone = timeZones.find(item => item?.countryName === contributionTimeZoneId);

  if (timeZone) {
    return timeZone.name;
  }

  return TIMEZONES[contributionTimeZoneId]; // fallback value
};
const Session = ({
  number,
  session,
  timeDetails,
  session: { id, isCompleted, startTime, endTime, attachments, participantId },
  packagePurchases,
  contributionId,
  renderActions,
  executeCalendarScroll,
  setRescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  contributionTimeZoneId,
  calendarEl,
  selectedSession,
  setSelectedSession,
}) => {
  const participantPackage = packagePurchases?.find(pkg => pkg?.userId === participantId);
  const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = participantPackage || {};
  const percentageCompleted = (bookedSessionNumbers / sessionNumbers) * 100;
  const theme = useTheme();
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const participantInfo = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(client => client?.id === participantId) || {},
  );
  const { protocol, domain, history } = useRouter();
  const userProfileUrl = new URL('/account/profile', `${protocol}//${domain}`).toString();
  const { error, timeZones, loading } = useSelector(state => state.timeZone);
  const isTodaySession =
    moment(session.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0 || number == 1;
  const isSelectedSession = selectedSession && session.id === selectedSession;
  const isPastSession = moment(session.startTime).startOf('day').diff(moment().startOf('day'), 'days') < 0;
  const contribution = useContribution();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  let colorToUse = determineColorToUse(contribution);
  const dispatch = useDispatch();
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const shouldChangeColor = !isCoach && activeTemplate != TemplateType.TemplateThree;
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(contribution);
  const [expanded, setExpanded] = useState(isTodaySession || isSelectedSession);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletedSuccessfullyModalOpen, setIsDeletedSuccessfullyModalOpen] = useState(false);
  const [deleteSession, setDeleteSession] = useState(false);
  const [reasonForDeletion, setReasonForDeletion] = useState('');
  const classes = useStyles();
  const expansionPanelRef = useRef(null);
  const handleRescheduleCoach = e => {
    dispatch(setMyAvailabilityTime(true));
    dispatch(
      setEasyBookingData.setData({
        selectedSlot: session,
        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
          'h:mmA',
        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format('MMMM DD, yyyy')}`,
        helpingMaterial: getItem('helpingMaterial'),
        isBookedTime: true,
      }),
    );
    dispatch(
      setRescheduleFormerSessionDetail({
        selectedSlot: session,
        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
          'h:mmA',
        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format('MMMM DD, yyyy')}`,
        isBookedTime: true,
        helpingMaterial: getItem('helpingMaterial'),
      }),
    );
    dispatch(setRescheduleParticipantInfo(participantInfo));
    e.stopPropagation();
    setRescheduleSession(true);
    setShowRescheduleModal(true);
  };

  const handleReschedule = e => {
    dispatch(
      setEasyBookingData.setData({
        selectedSlot: session,
        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
          'h:mmA',
        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format('MMMM DD, yyyy')}`,
        helpingMaterial: getItem('helpingMaterial'),
        isBookedTime: true,
      }),
    );
    dispatch(
      setRescheduleFormerSessionDetail({
        selectedSlot: session,
        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
          'h:mmA',
        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format('MMMM DD, yyyy')}`,
        isBookedTime: true,
        helpingMaterial: getItem('helpingMaterial'),
      }),
    );
    dispatch(setRescheduleParticipantInfo(participantInfo));
    e.stopPropagation();
    setRescheduleSession(true);
    setShowRescheduleModal(true);
  };

  const handleDelete = e => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };
  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if (!timeZones?.length && !loading && !error) {
      dispatch(fetchTimeZones());
    }
  }, [dispatch, error, loading, timeZones]);

  const handleSubmit = async () => {
    dispatch(setRescheduleModal(true));
    setRescheduleMode(true);
    if (!shouldChangeColor) {
      executeCalendarScroll();
    }
    setRescheduleModalState(false);
    setCurrentRescheduleSession(session);
    if (mobileView && calendarEl.current) {
      calendarEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  const handleDeleteSession = async () => {
    const data = {
      contributionId: contributionId,
      availabilityTimeId: session.timeId,
      isDeleted: true,
      reason: reasonForDeletion,
      clientId: session.participantId,
      oldAvailabilityTimeId: session.timeId,
      startTime: session.startTime,
      endTime: session.endTime,
    };
    await deleteOneToOneSession(data).then(() => {
      // dispatch(fetchCohealerContribution(contributionId));
      setIsDeleteModalOpen(false);
      setIsDeletedSuccessfullyModalOpen(true);
    });
    // if (deleteSession) {
    //   setIsDeleteModalOpen(false);
    // }
  };

  const isRescheduleDisabled = isCoach
    ? false
    : !moment(session.startTime).isAfter(moment().add(24, 'h')) || session.isCompleted;

  const height = mobileView ? '' : '77px';

  const timezone = timeZones.find(tz => tz.countryName === contributionTimeZoneId);
  const timeZoneLabel = timezone?.shortName;
  const Styledspan = styled.span`
    height: 29px;
    width: 29px;
    background-color: ${props => (props.color ? props.color : '')};
  `;

  const shouldBeDisabled = session => {
    let isDisabled = false;
    if (session?.isCompleted || !session.isPurchaseConfirmed) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }
    return isDisabled;
  };

  useEffect(() => {
    if (isSelectedSession) {
      const panel = document.getElementById(`expansion-panel-${selectedSession}`);
      if (panel) {
        setTimeout(() => {
          const topPosition = panel.getBoundingClientRect().top + window.scrollY - (mobileView ? 303 : 144);
          window.scrollTo({
            top: topPosition,
            behavior: 'smooth',
          });
          setSelectedSession(null);
        }, 400);
      }
    }
  }, [isSelectedSession, mobileView, selectedSession, expansionPanelRef.current]);

  const isDeleteDisabled = shouldBeDisabled(session);
  return !isCoach && !id ? (
    <div
      style={{
        height: '80px',
        borderBottom: '1px solid #DFE3E4',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: mobileView ? '0px 20px' : '0px 35px',
      }}
    >
      <Row>
        <Column>
          {number && (
            <Styledspan
              color={themedBackgroundColor}
              className="one-to-one-session-summary__number-badge"
              style={
                activeTemplate === TemplateType.TemplateThree || isCoach
                  ? {}
                  : {
                      fontSize: mobileView ? '13px' : '15px',
                      borderRadius: '20px',
                      padding: '4px 10px',
                      backgroundColor: btnColor,
                    }
              }
            >
              {number}
            </Styledspan>
          )}
        </Column>
        <Column>
          <Row style={{ marginLeft: mobileView ? '' : '8px' }}>
            {activeTemplate != TemplateType.TemplateThree && contribution.serviceProviderName && !isCoach && (
              <>
                <StylesProvider injectFirst>
                  <AvatarComponentForSession
                    className="mx-2"
                    alt={`${user.firstName} ${user.lastName}`}
                    src={contribution.coachAvatarUrl}
                    mobileView={mobileView}
                  >
                    {`${contribution?.serviceProviderName && getInitials(contribution.serviceProviderName)}`}
                  </AvatarComponentForSession>
                </StylesProvider>
              </>
            )}
            <div
              style={{
                fontSize: mobileView ? '16px' : '20px',
                fontWeight: '800',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: themedColor,
              }}
            >
              {session.sessionName ? session?.sessionName : 'Session'}
            </div>
          </Row>
        </Column>
      </Row>
      {renderActions(session)}
    </div>
  ) : (
    <>
      <ExpansionPanel
        className="one-to-one-session"
        classes={activeTemplate === TemplateType.TemplateThree || isCoach ? {} : { root: classes.round }}
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        TransitionProps={{ unmountOnExit: true }}
        style={
          activeTemplate === TemplateType.TemplateThree || isCoach
            ? { marginBottom: '15px', boxShadow: 'none', border: `1px solid #DFE3E4`, borderRadius: '4px' }
            : {
                borderBottom: expanded ? `1px solid #DFE3E4` : '',
                // marginBottom: '10px',
                boxShadow: 'none',
              }
        }
        id={`expansion-panel-${session?.id}`}
      >
        <ExpansionPanelSummary
          style={{
            height: activeTemplate === TemplateType.TemplateThree || isCoach ? 'auto' : height,
            backgroundColor: themedCardBackgroundColor,
            color: themedColor,
            borderTopLeftRadius: isCoach ? '4px' : '',
            borderTopRightRadius: isCoach ? '4px' : '',
            borderBottomLeftRadius: isCoach && !expanded ? '4px' : '',
            borderBottomRightRadius: isCoach && !expanded ? '4px' : '',
          }}
          ref={expansionPanelRef}
          expandIcon={<ExpandMoreIcon style={{ color: themedColor }} />}
          className={mobileView && classes.iconButton}
        >
          {mobileView && activeTemplate != TemplateType.TemplateThree && !isCoach ? (
            <StyledOpenClosePanel>
              <StyledRow>
                <Column>
                  {number && (
                    <Styledspan
                      color={themedBackgroundColor}
                      className="one-to-one-session-summary__number-badge"
                      style={
                        activeTemplate === TemplateType.TemplateThree || isCoach
                          ? {}
                          : {
                              fontSize: mobileView ? '13px' : '15px',
                              borderRadius: '20px',
                              padding: '4px 10px',
                              backgroundColor: btnColor,
                            }
                      }
                    >
                      {number}
                    </Styledspan>
                  )}
                </Column>

                <Column>
                  {activeTemplate != TemplateType.TemplateThree && serviceProviderName && !isCoach && (
                    <>
                      <StylesProvider injectFirst>
                        <AvatarComponentForSession
                          className="mx-2"
                          alt={`${user.firstName} ${user.lastName}`}
                          src={contribution.coachAvatarUrl}
                          mobileView={mobileView}
                        >
                          {`${serviceProviderName && getInitials(serviceProviderName)}`}
                        </AvatarComponentForSession>
                      </StylesProvider>
                    </>
                  )}
                </Column>

                <StyledColumn mobileView={mobileView}>
                  <div
                    style={{
                      fontSize: mobileView ? '16px' : '20px',
                      fontFamily: 'Avenir',
                      fontWeight: '900',
                      // marginTop: '5px',
                      // marginLeft: '10px',
                      color: themedColor,
                    }}
                  >
                    {`Session with ${contribution?.serviceProviderName}`}
                  </div>

                  <Row>
                    {activeTemplate != TemplateType.TemplateThree && !isCoach && expanded && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                          }}
                        >
                          {/* <div style={{ fontSize: '20px', fontWeight: '800', marginTop: '5px' }}>Session</div> */}
                          <StytledSessionInfo mobileView>{isCompleted ? 'Completed' : 'Upcoming'}</StytledSessionInfo>
                        </div>
                      </>
                    )}
                  </Row>
                </StyledColumn>
              </StyledRow>
              {!expanded && (
                <>
                  <StyledDateView>
                    <Row>
                      {activeTemplate != TemplateType.TemplateThree && !isCoach && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                            }}
                          >
                            {/* <div style={{ fontSize: '20px', fontWeight: '800', marginTop: '5px' }}>Session</div> */}
                            <StytledSessionInfo mobileView expanded>
                              {isCompleted ? 'Completed' : 'Upcoming'}
                            </StytledSessionInfo>
                          </div>
                        </>
                      )}
                    </Row>
                    <Row>
                      {activeTemplate != TemplateType.TemplateThree && !isCoach && startTime && (
                        <>
                          {/* <StyledTimeZoneLabel>{moment().tz(TIMEZONES[user.timeZoneId]).zoneAbbr()}</StyledTimeZoneLabel> */}
                          <StyledTimeZoneLabel
                            style={{ backgroundColor: '#F5F5F5', color: '#4A4A4A' }}
                            mobileView={mobileView}
                          >
                            {`${moment(startTime).format(
                              mobileView ? 'MMMM Do h:mmA' : 'MMMM Do YYYY h:mmA',
                            )} - ${moment(endTime).format('h:mmA')} ${timeZoneLabel}`}
                          </StyledTimeZoneLabel>
                        </>
                      )}
                    </Row>
                  </StyledDateView>
                  <Row>
                    {session?.id && (
                      <div
                        style={
                          mobileView && !isCoach ? { display: 'flex', gap: '10px', width: '100%' } : { width: '100%' }
                        }
                      >
                        {renderActions(session)}
                      </div>
                    )}
                  </Row>
                </>
              )}
            </StyledOpenClosePanel>
          ) : (
            <>
              <div
                className="course-session-summary-container"
                style={
                  activeTemplate === TemplateType.TemplateThree || isCoach
                    ? { width: mobileView && '100%' }
                    : { width: isIpad ? '80%' : '100%', justifyContent: 'space-between' }
                }
              >
                <div className="course-session-summary" style={{ width: mobileView && '100%' }}>
                  <h3
                    style={{
                      marginRight: '10px',
                      color: themedColor,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    className="one-to-one-session-summary__header"
                  >
                    {number && (
                      <Styledspan
                        color={themedBackgroundColor}
                        className="one-to-one-session-summary__number-badge"
                        style={
                          activeTemplate === TemplateType.TemplateThree || isCoach
                            ? { color: textColor, backgroundColor: btnColor }
                            : {
                                fontSize: '15px',
                                marginLeft: '15px',
                                borderRadius: '20px',
                                padding: '4px 10px',
                                color: textColor,
                                backgroundColor: btnColor,
                              }
                        }
                      >
                        {number}
                      </Styledspan>
                    )}
                    {isCoach && (
                      <StylesProvider injectFirst>
                        <AvatarComponentForSession
                          className="mx-2"
                          alt={`${participantInfo.firstName} ${participantInfo.lastName}`}
                          src={participantInfo.avatarUrl}
                          mobileView={mobileView}
                        >
                          {`${getInitials(`${participantInfo?.firstName} ${participantInfo?.lastName}`)}`}
                        </AvatarComponentForSession>
                      </StylesProvider>
                    )}
                    {(activeTemplate === TemplateType.TemplateThree || isCoach) && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        {id && isCoach && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{
                                width: '188px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <Tooltip
                                title={
                                  session.isGuestUserSession
                                    ? session?.guestUserName
                                    : `${participantInfo?.firstName || ''} ${participantInfo?.lastName || ''}`
                                }
                                arrow
                              >
                                <div
                                  style={{
                                    marginLeft: mobileView ? '0px' : '',
                                    fontSize: mobileView ? '15px' : '20px',
                                    fontFamily: 'Avenir',
                                    fontWeight: 900,
                                    color: themedColor,
                                    width: 'min-content',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {session.isGuestUserSession
                                    ? session?.guestUserName
                                    : `${participantInfo?.firstName || ''} ${participantInfo?.lastName || ''}`}
                                </div>
                              </Tooltip>
                            </div>
                            {id && isCoach && !mobileView && (
                              <div
                                style={{
                                  backgroundColor: '#F5F5F5',
                                  padding: '4px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                  marginLeft: '16px',
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: 'Avenir',
                                    fontSize: '12.9px',
                                    fontWeight: 400,
                                    color: '#4A4A4A',
                                  }}
                                >
                                  {isCompleted
                                    ? 'Completed'
                                    : session.isPurchaseConfirmed
                                    ? 'Upcoming'
                                    : 'Pending Client Approval'}
                                </div>
                              </div>
                            )}
                            {id && isCoach && !mobileView && !expanded && (
                              <div
                                style={{
                                  backgroundColor: '#F5F5F5',
                                  padding: '4px 8px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '4px',
                                  marginLeft: '5px',
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: 'Avenir',
                                    fontSize: '12.9px',
                                    fontWeight: 400,
                                    color: '#4A4A4A',
                                  }}
                                >
                                  {`${moment(startTime).format('MMMM Do YYYY h:mm a')} - ${moment(endTime).format(
                                    'h:mm a',
                                  )} ${timeZoneLabel}`}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {isCoach && id && expanded && mobileView && (
                          <Tooltip
                            title={`Session with ${
                              session.isGuestUserSession
                                ? session?.guestUserName
                                : `${participantInfo?.firstName || ''} ${participantInfo?.lastName || ''}`
                            }`}
                            arrow
                          >
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: 500,
                                backgroundColor: themedCardBackgroundColor,
                                color: themedColor,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '200px',
                              }}
                            >
                              {`Session with ${
                                session.isGuestUserSession
                                  ? session?.guestUserName
                                  : `${participantInfo?.firstName || ''} ${participantInfo?.lastName || ''}`
                              }`}
                              &nbsp;
                            </div>
                          </Tooltip>
                        )}
                        {isCoach && id && expanded && !mobileView && (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontSize: '16px',
                              fontWeight: 500,
                              backgroundColor: themedCardBackgroundColor,
                              color: themedColor,
                            }}
                          >
                            {`Session with ${
                              session.isGuestUserSession
                                ? session?.guestUserName
                                : `${participantInfo?.firstName || ''} ${participantInfo?.lastName || ''}`
                            }`}
                            &nbsp;
                          </div>
                        )}
                      </div>
                    )}
                  </h3>

                  {activeTemplate != TemplateType.TemplateThree && serviceProviderName && !isCoach && (
                    <>
                      <StylesProvider injectFirst>
                        <AvatarComponentForSession
                          className="mx-2"
                          alt={`${user.firstName} ${user.lastName}`}
                          src={contribution.coachAvatarUrl}
                          mobileView={mobileView}
                        >
                          {`${serviceProviderName && getInitials(serviceProviderName)}`}
                        </AvatarComponentForSession>
                      </StylesProvider>
                    </>
                  )}
                  {id && isCoach && mobileView && !expanded && (
                    <Grid style={{ display: 'flex', paddingTop: '10px' }}>
                      <Grid item>
                        <div
                          style={{
                            backgroundColor: '#F5F5F5',
                            padding: '4px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            // marginLeft: '5px',
                          }}
                        >
                          <div style={{ fontFamily: 'Avenir', fontSize: '10px', fontWeight: 400, color: '#4A4A4A' }}>
                            {isCompleted
                              ? 'Completed'
                              : session.isPurchaseConfirmed
                              ? 'Upcoming'
                              : 'Pending Client Approval'}
                          </div>
                        </div>
                      </Grid>
                      <Grid>
                        <div
                          style={{
                            backgroundColor: '#F5F5F5',
                            padding: '4px 8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            marginLeft: '8px',
                          }}
                        >
                          <div style={{ fontFamily: 'Avenir', fontSize: '10px', fontWeight: 400, color: '#4A4A4A' }}>
                            {`${moment(startTime).format(
                              mobileView ? 'MMMM Do h:mmA' : 'MMMM Do YYYY h:mmA',
                            )} - ${moment(endTime).format('h:mmA')} ${timeZoneLabel}`}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {!expanded && session?.id && !isCompleted && mobileView && session.isPurchaseConfirmed === true && (
                    <LaunchSessionTime
                      user={user}
                      contribution={contribution}
                      sessionTime={session}
                      participantName={`${participantInfo.firstName} ${participantInfo.lastName}`}
                      mobileView={mobileView}
                      mobileViewCoachLaunch={true}
                    />
                  )}
                  {!expanded && session?.id && !isCompleted && mobileView && session.isPurchaseConfirmed === true && (
                    <StyledRescheduleBtn
                      type="button"
                      onClick={handleRescheduleCoach}
                      disabled={isRescheduleDisabled}
                      style={
                        isRescheduleDisabled
                          ? { border: `1px solid #9b9b9b`, fontFamily: 'Avenir', fontWeight: 900 }
                          : {
                              color: colorToUse?.AccentColorCode,
                              border: `1px solid ${colorToUse?.AccentColorCode}`,
                              fontFamily: 'Avenir',
                              fontWeight: 900,
                            }
                      }
                    >
                      Reschedule
                    </StyledRescheduleBtn>
                  )}

                  {activeTemplate != TemplateType.TemplateThree && !isCoach && (
                    <>
                      {/* <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                        }}
                      > */}
                      <div style={{ fontSize: '20px', fontWeight: '800', color: themedColor }}>
                        {`Session with ${contribution?.serviceProviderName}`}
                      </div>
                      {startTime && (
                        <div
                          style={{
                            backgroundColor: '#F5F5F5',
                            padding: '4px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            marginLeft: '5px',
                          }}
                        >
                          <div style={{ fontSize: mobileView ? '10px' : '12.9px', color: '#4A4A4A' }}>
                            {isCompleted ? 'Completed' : 'Upcoming'}
                          </div>
                        </div>
                      )}
                      {/* </div> */}
                    </>
                  )}
                  {activeTemplate != TemplateType.TemplateThree && !isCoach && startTime && !expanded && (
                    <>
                      {/* <StyledTimeZoneLabel>{moment().tz(TIMEZONES[user.timeZoneId]).zoneAbbr()}</StyledTimeZoneLabel> */}
                      <div
                        style={{
                          backgroundColor: '#F5F5F5',
                          padding: '4px 8px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '4px',
                          marginLeft: '5px',
                        }}
                      >
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontSize: mobileView ? '10px' : '12.9px',
                            fontWeight: 400,
                            color: '#4A4A4A',
                          }}
                        >
                          {`${moment(startTime).format('MMMM Do YYYY h:mmA')} - ${moment(endTime).format(
                            'h:mmA',
                          )} ${timeZoneLabel}`}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* {id && isCoach && mobileView && !expanded && (
                  <>
                    <span style={{ display: 'flex', left: '145px', position: 'absolute', top: '64px' }}>
                      <p>{moment(startTime).format('MMMM Do')}</p>
                    </span>
                  </>
                )} */}

                {id &&
                  (activeTemplate === TemplateType.TemplateThree || isCoach) &&
                  !isEmpty(participantPackage) &&
                  expanded && (
                    <Tooltip
                      title="The progress bar charts the sessions booked by the client in relation to the count. It's a helpful visual tool to easily track the booked sessions for both coach and clients.  The bar will  continue to show the total booked sessions by the client irrespective of the deleted sessions by the coach."
                      arrow
                      placement="right"
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <div
                        style={{
                          position: !mobileView && 'absolute',
                          right: mobileView ? '37%' : '100px',
                          width: mobileView ? '100%' : '540px',
                          marginBottom: mobileView ? '0px' : '0px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: mobileView ? 'flex-start' : 'center',
                          flexDirection: mobileView ? 'column' : 'row',
                          marginTop: mobileView ? '0px' : '0px',
                          paddingTop: mobileView ? '10px' : '0px',
                        }}
                      >
                        <StyledSessionRemaining clr={colorToUse?.AccentColorCode}>
                          <span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>
                            Sessions Booked
                          </span>
                          <span style={{ marginLeft: '5px', fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>
                            {`${bookedSessionNumbers > sessionNumbers ? sessionNumbers : bookedSessionNumbers}`}/
                            {`${sessionNumbers}`}
                          </span>
                        </StyledSessionRemaining>

                        <ProgressBar
                          completed={percentageCompleted}
                          bgColor={colorToUse?.AccentColorCode}
                          height="10px"
                          isLabelVisible={false}
                          width={mobileView ? '293px' : '350px'}
                        />
                      </div>
                    </Tooltip>
                  )}

                {activeTemplate === TemplateType.TemplateThree || isCoach ? (
                  <>
                    {/* {id && (
                      <p className="one-to-one-session-summary__status">{isCompleted ? 'Completed' : 'Upcoming'}</p>
                    )} */}
                    {!expanded && session?.id && !isCompleted && !mobileView && session.isPurchaseConfirmed === true && (
                      <button
                        type="button"
                        onClick={handleRescheduleCoach}
                        disabled={isRescheduleDisabled}
                        className="course-session-summary__reschedule"
                        style={
                          isRescheduleDisabled
                            ? { border: `1px solid #9b9b9b` }
                            : {
                                color: colorToUse?.AccentColorCode,
                                border: `1px solid ${colorToUse?.AccentColorCode}`,
                              }
                        }
                      >
                        Reschedule
                      </button>
                    )}
                    {!expanded && session?.id && !isCompleted && !mobileView && session.isPurchaseConfirmed === true && (
                      <div className="course-session-summary__launch">
                        <LaunchSessionTime
                          user={user}
                          contribution={contribution}
                          sessionTime={session}
                          participantName={`${participantInfo.firstName} ${participantInfo.lastName}`}
                          isOneOnOneExpansion={true}
                        />
                      </div>
                    )}

                    {session?.id &&
                      isCoach &&
                      (isDeleteDisabled ? (
                        <Tooltip title="Please mark this session complete prior to deleting it." arrow>
                          <div
                            className="course-session-summary__delete"
                            style={
                              isDeleteDisabled
                                ? {
                                    color: colorToUse?.AccentColorCode,
                                    opacity: 0.3,
                                    backgroundColor: 'transparent',
                                    border: '0px solid transparent',
                                  }
                                : {
                                    color: colorToUse?.AccentColorCode,
                                    backgroundColor: 'transparent',
                                    border: '0px solid transparent',
                                  }
                            }
                          >
                            <Icon path={mdiDelete} size={1} />
                            {/* Delete */}
                          </div>
                        </Tooltip>
                      ) : (
                        <button
                          type="button"
                          title={isDeleteDisabled ? 'Please mark this session complete prior to deleting it' : ''}
                          onClick={handleDelete}
                          disabled={isDeleteDisabled}
                          className="course-session-summary__delete"
                          style={
                            isDeleteDisabled
                              ? {
                                  backgroundColor: 'transparent',
                                  border: '0px solid transparent',
                                  color: btnColor,
                                  opacity: 0.3,
                                }
                              : {
                                  backgroundColor: 'transparent',
                                  border: '0px solid transparent',
                                  color: btnColor,
                                }
                          }
                        >
                          <Icon color={colorToUse?.AccentColorCode} path={mdiDelete} size={1} />
                          {/* Delete */}
                        </button>
                      ))}
                  </>
                ) : (
                  <>
                    {session?.id && (
                      <div className="one-to-one-session-time__actions">
                        {expanded && (
                          <Button
                            type="button"
                            invert
                            autoWidth
                            onClick={handleReschedule}
                            disabled={isRescheduleDisabled}
                            style={
                              isRescheduleDisabled
                                ? { marginRight: '10px', border: '0px solid transparent' }
                                : { marginRight: '10px' }
                            }
                            borderColor={isRescheduleDisabled ? 'transparent' : colorToUse?.AccentColorCode}
                            textColor={isRescheduleDisabled ? 'transparent' : colorToUse?.AccentColorCode}
                          >
                            Reschedule
                          </Button>
                        )}
                        {renderActions(session)}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            color: themedColor,
            backgroundColor: themedCardBackgroundColor,
            borderBottomLeftRadius: !mobileView ? '4px' : '',
            borderBottomRightRadius: !mobileView ? '4px' : '',
          }}
        >
          <div className="one-to-one-session-details">
            <h4 style={{ color: themedColor }} className="one-to-one-session-details__header">
              {id ? '' : `Confirm a time for this session by clicking 'Book Session Time'`}
              {/* {id && timeZoneLabel && (
                <StyledTimeZoneLabel href={userProfileUrl} style={{ backgroundColor: btnColor, color: textColor }}>
                  {timeZoneLabel}{' '}
                </StyledTimeZoneLabel>
              )} */}
            </h4>
            <div>
              <div className="one-to-one-session-details-session-times">
                <SessionTime
                  className="one-to-one-session-details-session-times__item"
                  startTime={startTime}
                  endTime={endTime}
                  participantId={participantId}
                  session={session}
                  timeDetails={timeDetails}
                  renderActions={renderActions}
                  activeTemplate={activeTemplate}
                  isRescheduleDisabled={isRescheduleDisabled}
                  handleReschedule={handleReschedule}
                  btnColor={btnColor}
                  textColor={textColor}
                  mobileView={mobileView}
                  timeZoneLabel={timeZoneLabel}
                  handleRescheduleCoach={handleRescheduleCoach}
                  accentColor={colorToUse?.AccentColorCode}
                  contributionId={contributionId}
                  sessionId={id}
                  bottomPanelAttachments={attachments}
                />
              </div>
              {id && !mobileView && (
                <BottomPanel
                  contributionId={contributionId}
                  sessionId={id}
                  attachments={attachments}
                  session={session}
                />
              )}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {!isCoach && !expanded && <div style={{ height: '1px', backgroundColor: 'rgb(223, 227, 228)' }} />}
      <Modal
        minWidth={mobileView ? '300px' : ''}
        height={'650px'}
        isOpen={showRescheduleModal}
        onSubmit={() => {
          setShowRescheduleModal(false);
        }}
        title={'Reschedule your session'}
        disableFooter={true}
        onCancel={() => {
          setShowRescheduleModal(false);
        }}
        noPadding={true}
        isBookingModal={true}
        CrossIconHide={!rescheduleSession}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule={true}
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal={true}
            isCoach={isCoach}
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal={true}
            handleCancel={() => {
              setRescheduleModalState(false);
              setShowRescheduleModal(false);
            }}
          />
        )}
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        headerBorder
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        PrimaryColor={btnColor}
        textColor={textColor}
        onSubmit={handleDeleteSession}
        title="Are you sure you want to delete?"
        submitTitle={mobileView ? 'Yes' : 'Yes, Delete For Me And My Client'}
        cancelTitle="Never mind"
        disableSubmitClick={!deleteSession || (deleteSession === true && reasonForDeletion.length === 0)}
        reduceCancelButton
      >
        <BodyText color={themedColor}>
          {`By deleting ${
            session?.sessionName === null ? `Session ${number}` : session?.sessionName
          }, your client will also lose access and history of this session and you will be
          responsible for any possible refunds that may need to be issued.`}
          <br />
          <br />
          Are you sure you wish to continue?
          <br />
          <div style={{ marginTop: '15px' }}>
            <FormControlLabel
              checked={deleteSession}
              onChange={() => {
                setDeleteSession(!deleteSession);
              }}
              control={<Checkbox style={{ color: colorToUse.PrimaryColorCode }} color="primary" />}
              label={<Typography style={{ fontWeight: '350' }}>Yes, I understand</Typography>}
              name="deleteSession"
              style={{ fontWeight: '350' }}
            />
          </div>
          {/* <br /> */}
          {deleteSession && (
            <>
              <div style={{ fontWeight: '350', fontSize: '14px' }}>
                <strong style={{ fontWeight: '500', fontSize: '16px' }}>Reason</strong> (Your client will be notified
                with this)
              </div>
              <StyledTextArea
                name="reasonForDeletion"
                rows="4"
                value={reasonForDeletion}
                onChange={e => {
                  setReasonForDeletion(e.target.value);
                }}
              />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  {reasonForDeletion.length > 200 && (
                    <div style={{ color: 'red' }}>Maximum characters allowed: 200</div>
                  )}
                </div>
                <div
                  style={{
                    color: themedColor,
                    fontSize: '0.75rem',
                    fontWeight: 'normal',
                    lineHeight: '2rem',
                    fontFamily: 'Avenir',
                    letterSpacing: '0.08px',
                  }}
                >
                  {reasonForDeletion.length || 0}/200
                </div>
              </div>
            </>
          )}
        </BodyText>
      </Modal>
      <Modal
        isOpen={isDeletedSuccessfullyModalOpen}
        title="Deleted Successfully"
        submitTitle="Ok"
        onSubmit={() => {
          dispatch(fetchCohealerContribution(contributionId));
          setIsDeletedSuccessfullyModalOpen(false);
          dispatch(setLoadingParticipants(true));
          contributionParticipants(contributionId)
            .then(res => {
              let contrib = {
                ...contribution,
                participants: res,
              };
              dispatch(fetchContributionActions.success(contrib));
              dispatch(setLoadingParticipants(false));
            })
            .catch(err => {
              dispatch(setLoadingParticipants(false));
            });
        }}
        onCancel={() => {
          dispatch(fetchCohealerContribution(contributionId));
          setIsDeletedSuccessfullyModalOpen(false);
          dispatch(setLoadingParticipants(true));
          contributionParticipants(contributionId)
            .then(res => {
              let contrib = {
                ...contribution,
                participants: res,
              };
              dispatch(fetchContributionActions.success(contrib));
              dispatch(setLoadingParticipants(false));
            })
            .catch(err => {
              dispatch(setLoadingParticipants(false));
            });
        }}
        reduceCancelButton
        hiddenCancel
      >
        <p style={{ color: themedColor }}>Your session has been deleted successfully.</p>
      </Modal>
    </>
  );
};

Session.defaultProps = {
  session: {},
  renderActions: null,
  serviceProviderName: null,
};

Session.propTypes = {
  number: PropTypes.number.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  contributionId: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  executeCalendarScroll: PropTypes.func.isRequired,
  setRescheduleMode: PropTypes.func.isRequired,
  setCurrentRescheduleSession: PropTypes.func.isRequired,
  serviceProviderName: PropTypes.string,
  selectedSession: PropTypes.string.isRequired,
};

export default Session;
