import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { useHttp, useVideoChat } from 'hooks';
import { ContributionType } from 'helpers/constants';
import Button from 'components/FormUI/Button';
import './NotesPanel.scss';
import { determineColorToUse } from 'services/contributions.service';
import NotesEditor from 'components/Notes/NotesEditor';
import useSessionNotes from 'hooks/useSessionNotes';
import SavingIcon from 'components/Icons/SavingIcon';
import SavedIcon from 'components/Icons/SavedIcon';

const CHARACTER_LIMIT = 100000;

const NotesPanel = ({ className, onEnabledChange, sessionTime }) => {
  const { request, loading } = useHttp();
  const dispatch = useDispatch();
  const contribution = useSelector(state => state.contributions?.activeContribution);
  const { contributionId, classId, sessionId, title, type } = useVideoChat();
  const [editor, setEditor] = useState();
  const [characterCount, setCharacterCount] = useState(0);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const classIdToRequest =
    type === ContributionType.contributionCourse ||
    type === ContributionType.contributionCommunity ||
    type === ContributionType.contributionMembership
      ? sessionId
      : classId;
  const { data: editorData, notesMeta, showNotes, setData: setEditorDataUpdated, isNoteExist } = useSessionNotes({
    contributionId,
    classId: classIdToRequest,
    subClassId: sessionTime ? sessionTime.id : null,
    isPrerecorded: !!sessionTime,
  });

  useEffect(() => {
    setLoadedFirstTime(true);
    if (loadedFirstTime) {
      handleAutoSubmit();
    }
  }, [editorData]);

  const handleSubmit = useCallback(() => {
    if (!isNoteExist) onEnabledChange(false);
    const noteRequest = () => {
      return notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId: classIdToRequest,
            title: notesMeta?.title || title,
            subClassId: sessionTime ? sessionTime.id : null,
            textContent: editorData,
            isPrerecorded: Boolean(sessionTime),
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId: classIdToRequest,
            title,
            textContent: editorData,
          });
    };

    noteRequest().then().catch(console.dir);
  }, [contributionId, classIdToRequest, title, notesMeta, editorData, request]);

  const handleAutoSubmit = useCallback(() => {
    if (!isNoteExist) onEnabledChange(false);
    const noteRequest = () => {
      return notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId: classIdToRequest,
            title: notesMeta?.title || title,
            subClassId: sessionTime ? sessionTime.id : null,
            textContent: editorData,
            isPrerecorded: Boolean(sessionTime),
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId: classIdToRequest,
            title,
            textContent: editorData,
          });
    };
    setSaving(true);
    noteRequest()
      .then(() => {
        setSaving(false);
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 500);
      })
      .catch(console.dir);
  }, [contributionId, classIdToRequest, title, notesMeta, editorData, request]);

  useEffect(() => {
    if (sessionTime) {
      request(`/api/Note/${contributionId}/${classIdToRequest}/?subClassId=${sessionTime.id}`).catch(console.dir);
    } else {
      request(`/api/Note/${contributionId}/${classIdToRequest}`).catch(console.dir);
    }
  }, [request, contributionId, classIdToRequest]);

  const colorToUse = determineColorToUse(contribution);
  const btnColor = colorToUse.PrimaryColorCode;

  return (
    <div className={classNames(className, 'video-chat-notes-panel')}>
      <p className="video-chat-notes-panel__title">
        Add a Note
        <span style={{ marginLeft: '10px' }}>
          {saving && <SavingIcon />}
          {saved && <SavedIcon />}
          {saving ? '  Saving...' : saved ? '  Saved.' : ''}
        </span>
      </p>
      {showNotes && (
        <NotesEditor
          editorData={editorData}
          setEditorDataUpdated={setEditorDataUpdated}
          setEditor={setEditor}
          characterLimit={CHARACTER_LIMIT}
          setCharacterCount={setCharacterCount}
          characterCount={characterCount}
        />
      )}
      <div className="video-chat-notes-panel__statusBar">
        <p style={{ color: characterCount > CHARACTER_LIMIT ? 'red' : '' }}>
          {characterCount}/{CHARACTER_LIMIT}
        </p>
      </div>
      <div className="video-chat-notes-panel__buttons">
        <Button
          backgroundColor={btnColor}
          style={{ backgroundColor: `${btnColor} !important` }}
          disabled={loading}
          onClick={() => onEnabledChange(false)}
          autoWidth
          invert
        >
          {isNoteExist ? 'Close' : 'Exit'}
        </Button>
        <Button
          backgroundColor={btnColor}
          style={{ backgroundColor: `${btnColor} !important` }}
          disabled={loading || characterCount > CHARACTER_LIMIT}
          form="note-form"
          type="submit"
          onClick={handleSubmit}
          autoWidth
        >
          {isNoteExist ? 'Update' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

NotesPanel.propTypes = {
  className: PropTypes.string.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
  sessionTime: PropTypes.func.isRequired,
};

export default NotesPanel;
