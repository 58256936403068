import styled, { css } from 'styled-components';
import { toRem } from 'utils/styles';

const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nexa;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.675rem;
  font-weight: 500;
  margin: 0px;
  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const PageTitleGettingStarted = styled.div`
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  font-family: Avenir;
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const PageTitleVideoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 0px;
  width: 80%;
  ${({ mobileView }) => mobileView && `font-size: ${toRem(18)};`}
  ${({ mobileView }) =>
    mobileView &&
    css`
      width: 100%;
      margin-left: 25px;
    `}
`;

const PageTitleVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir;
  color: rgba(0, 0, 0, 0.87);
  font-size: 40px;
  font-weight: 900;
  margin: 0px;
  ${({ mobileView }) => mobileView && `font-size: ${toRem(18)};`}
`;
const PageTitleText = styled.h1`
  font-family: 'Avenir';
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 400;
  line-height: 32.16px;
  display: inline-block;
  margin: 0px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* ${({ mobileView }) => mobileView && `font-size: ${toRem(13)}; width: 75px;`} */
  ${({ mobileView }) => mobileView && `font-size: ${toRem(13)};`}
`;

const PageTitleSecond = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Avenir';
  ${({ mobileView }) =>
    mobileView &&
    css`
      font-size: 18px;
      font-weight: 400;
      margin: 0px;
      font-family: Avenir;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      text-transform: capitalize;
    `}
  ${({ styleOverrides }) => styleOverrides || ''};
`;

const SimpleText = styled.span`
  font-family: Brandon Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.15px;
`;

const PageSubtitle = styled.h3`
  // font-size: ${toRem(16)};
  // font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #282b2b;
  font-family: Brandon Text;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const PageSubtitleCoach = styled.h3`
  font-size: 1rem;
  font-family: Avenir;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: rgb(0, 0, 0);
`;

const LabelText = styled.span`
  font-size: ${toRem(18)};
  font-family: Avenir;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : '#000000')};

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;
const LabelText2 = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #282c2c;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const BodyOrLeftText = styled.p`
  font-size: ${toRem(16)};
  line-height: 1.25;
  font-family: Brandon Text;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const BodyMainText = styled.p`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24.69px;
  color: #000000;
  width: 100%;
`;

const BodyText = styled.p`
  font-size: ${toRem(16)};
  line-height: ${toRem(24)};
  font-family: 'Avenir';
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-style: normal;
  margin-top: 10px;
  ${({ color }) => color && `color: ${color};`}
  ${({ mobileView }) => mobileView && `margin-top: 10px`}
`;

export {
  PageTitle,
  PageTitleSecond,
  PageSubtitle,
  PageSubtitleCoach,
  BodyOrLeftText,
  BodyMainText,
  BodyText,
  LabelText,
  PageTitleText,
  PageTitleVideo,
  SimpleText,
  PageTitleVideoText,
  PageTitleGettingStarted,
};
