import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { colors } from 'utils/styles';
import * as oneToOneDataActions from 'actions/oneToOneData';

const StyledPickerContainer = styled.div`
  display: flex;
  margin: 15px 0 10px 0;
  justify-content: space-between;
  width: ${({ mobileView }) => (mobileView ? '100%' : '420px')};
`;

const StyledPickerItem = styled.div`
  background: #e7e7e74a;
  font-family: 'Brandon Text';
  font-weight: 600;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  width: ${({ mobileView }) => (mobileView ? '35px' : '45px')};
  height: ${({ mobileView }) => (mobileView ? '35px' : '45px')};
  align-items: center;
  justify-content: center;
  color: ${({ selected }) => (selected ? colors.white : colors.darkGray)};
  // border: 1px solid ${colors.darkBlue};
  background: ${({ selected }) => selected && colors.darkOceanBlue};
`;

export const getWeekdays = withoutWeekend => {
  const workdays = [
    { title: 'M', value: 'Mon' },
    { title: 'T', value: 'Tue' },
    { title: 'W', value: 'Wed' },
    { title: 'Th', value: 'Thu' },
    { title: 'F', value: 'Fri' },
  ];

  return withoutWeekend ? workdays : [...workdays, { title: 'S', value: 'Sat' }, { title: 'Su', value: 'Sun' }];
};

const WeekdayPicker = ({ disabled, itemIndex, selectedWeeks, setSelectedWeeks }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = el => {
    setSelectedWeeks(itemIndex, el);
  };

  const isDaySelected = item => selectedWeeks[itemIndex].days.find(weekday => weekday.value === item.value);

  return (
    <StyledPickerContainer mobileView={mobileView} disabled={disabled}>
      {getWeekdays().map(el => (
        <StyledPickerItem
          key={el.title}
          selected={(() => isDaySelected(el))()}
          onClick={() => {
            handleClick(el);
          }}
          mobileView={mobileView}
        >
          {el.title}
        </StyledPickerItem>
      ))}
    </StyledPickerContainer>
  );
};

WeekdayPicker.propTypes = {
  disabled: PropTypes.bool,
  itemIndex: PropTypes.number,
};

WeekdayPicker.defaultProps = {
  disabled: false,
  itemIndex: 0,
};

const mapStateToProps = ({ oneToOneData }) => ({
  selectedWeeks: oneToOneData?.selectedWeeks,
});

const actions = {
  setSelectedWeeks: oneToOneDataActions.setSelectedWeeks,
};

export default connect(mapStateToProps, actions)(WeekdayPicker);
