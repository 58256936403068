import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled, { css } from 'styled-components';
import { fetchCohealerLeadMagnet, fetchClientLeadMagnet } from 'actions/leadMagnets';
import { updateUser } from 'actions/update-user';

import LeadMagnetHeader from 'components/UI/LeadMagnet/LeadMagnetHeader';
import useScreenView from 'hooks/useScreenView';
import { UserRoles } from 'helpers/constants';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import LeadMagnetLandingTempOne from 'pages/LeadMagnetView/LeadMagnetLandingTempOne';
import useRouter from 'hooks/useRouter';
import LeadMagnetThankYouPage from 'pages/LeadMagnetView/LeadMagnetThankYouPage';
import LeadMagnetThankYouEmail from 'pages/LeadMagnetView/LeadMagnetThankYouEmail';
import { ROUTES } from '../../constants';
import useAccount from 'hooks/useAccount';
import Loader from 'components/UI/Loader';
import axiosInstance from 'utils/axiosInstance';
import { addhttp } from 'utils/utils';
import { getProfileLinkNameByLeadMagnetId } from 'services/leadMagnets.service';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import { CommonRoute, ProtectedRoute, PublicRoute } from 'components/Routing';
import { useHeader } from 'hooks';
const StyledMainContainer = styled.div`
  background-color: #fafafa;
  background-color: ${props => props.themedBackgroundColor};
  color: ${props => props.themedColor};
  min-height: 100vh;
  display: flex;
`;
const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const StickyPortion = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
  position: sticky;
  top: 0;
  z-index: 20;  
`}
`;
const StyledMainSection = styled.div`
  flex-grow: 1;

  background-color: ${props => props.themedBackgroundColor};
  padding: ${({ mobileView, islandingPage }) => (islandingPage ? (mobileView ? '2.5rem 0rem' : '0rem 5rem') : '')};
  padding-top: ${({ isSticky, type }) => (isSticky ? '0px' : '')};
  /* padding: ${({ xsView, mobileView }) => (xsView && '0') || (mobileView && '8px')}; */
  ${({ isLoggedIn, devModeSessionsTab, isSessionsTab, isNonOneToOne }) =>
    devModeSessionsTab &&
    isLoggedIn &&
    isSessionsTab &&
    isNonOneToOne &&
    css`
      padding-left: 0;
      padding-right: 0;
      padding: 0;
    `}

  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    `
    pointer-events: none;
  `}
`;

const StyledMainSectionBorder = styled.div`
  height: 0.5rem;
  flex-shrink: 0;
  background: ${props => props.bgColor};
`;

const StyledP = styled.p`
  &:hover {
    text-decoration: underline;
  }
`;

const LeadMagnetViewContainer = () => {
  const { mobileView } = useScreenView();
  const currentRole = useSelector(state => state.account?.currentRole);
  const isCoach = currentRole === UserRoles.cohealer;
  const { themedColors, colorToUse } = useLeadMagnetTheme();
  const { subdomain, parentDomain, history, location, query, domain, pathname } = useRouter();
  const { user } = useAccount();
  const profileLinkName = user?.profilePageViewModel?.profileLinkName;
  const islandingPage = pathname?.includes('landingpage');
  const clientPreviewMode = pathname?.includes('clientpreview');
  const dispatch = useDispatch();
  const [loadingDomainName, setLoadingDomainName] = useState(true);
  const leadMagnet = useLeadMagnet();

  const {
    path,
    url,
    params: { id },
  } = useRouteMatch();

  useEffect(() => {
    if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      dispatch(fetchCohealerLeadMagnet(id))
        .then(res => {
          let u = {
            ...user,
            userProgressbarData: res.payload.userProgressbarData,
            progressBarPercentage: res.payload.progressBarPercentage,
          };
          dispatch(updateUser(u));
        })
        .catch(e => {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
        });
    } else if (currentRole === UserRoles?.client) {
      dispatch(fetchClientLeadMagnet(id));
      // fetchClientLeadMagnet(id, query?.code?.includes('p_') ? query?.code : null).then(res => {
      //   const anss = res?.payload?.contributionPartners?.filter(x => x?.userId === user?.id);
      //   if (anss?.length > 0) {
      //     history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
      //   }
      // });
    } else {
      dispatch(fetchClientLeadMagnet(id));
    }
  }, [id, currentRole, fetchCohealerLeadMagnet]);

  // const getApiData = useCallback(
  //   () => axiosInstance.get(`/ProfilePage/GetProfileLinkNameByContributionId?ContributionId=${id}`),
  //   [id],
  // );

  useEffect(() => {
    if (profileLinkName || subdomain) {
      setLoadingDomainName(false);
      return;
    }
    // console.info('new url:', addhttp(`${profileLinkName}.${parentDomain}${pathname}`))
    // window.location.replace(addhttp(`${profileLinkName}.${parentDomain}${pathname}`));
    if (loadingDomainName) {
      getProfileLinkNameByLeadMagnetId(id)
        .then(res => {
          if (res) {
            window.location.replace(addhttp(`${res}.${parentDomain}${pathname}`));
          } else {
            setLoadingDomainName(false);
          }
        })
        .catch(() => {
          setLoadingDomainName(false);
        });
    }
  }, [id]);
  useEffect(() => {
    document.title = leadMagnet?.title || 'Cohere';
  }, [leadMagnet]);

  if (loadingDomainName) {
    return <Loader />;
  }

  return (
    <>
      <StyledMainContainer
        themedColor={themedColors.themedColor}
        themedBackgroundColor={themedColors.themedCardBackgroundColor}
        {...{ mobileView }}
      >
        <StyledRightSection style={{ maxWidth: mobileView || isCoach ? '100%' : 'none' }}>
          <LeadMagnetHeader />
          <StyledMainSection
            {...{ mobileView, islandingPage, clientPreviewMode }}
            style={{ backgroundColor: leadMagnet?.isDarkModeEnabled ? '#252728' : '' }}
          >
            <Switch>
              <CommonRoute path={`${path}/:code?/landingpage`} component={LeadMagnetLandingTempOne} />
              <CommonRoute path={`${path}/:code?/thankyoupage`} component={LeadMagnetThankYouPage} />
              <ProtectedRoute path={`${path}/:code?/thankyoupage`} component={LeadMagnetThankYouPage} />
              <ProtectedRoute path={`${path}/:code?/thankyouemail`} component={LeadMagnetThankYouEmail} />
              <ProtectedRoute path={`${path}/:code?/clientpreview/landingpage`} component={LeadMagnetLandingTempOne} />
              <ProtectedRoute path={`${path}/:code?/clientpreview/thankyoupage`} component={LeadMagnetThankYouPage} />
              <Redirect to={`${path}/:code?/landingpage`} />
            </Switch>
            {leadMagnet?.isFooterEnabled && leadMagnet?.privacyPolicyUrl && (
              <StyledP
                className="privacy-policy-link"
                style={{
                  cursor: 'pointer',
                  fontSize: '16px',
                  color: leadMagnet?.isDarkModeEnabled ? 'white' : 'rgba(74, 74, 74, 1)',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
                onClick={() => window.open(leadMagnet?.privacyPolicyUrl, '_blank')}
              >
                Privacy Policy
              </StyledP>
            )}
          </StyledMainSection>
        </StyledRightSection>
      </StyledMainContainer>
      <StyledMainSectionBorder bgColor={colorToUse.AccentColorCode} />
    </>
  );
};

export default LeadMagnetViewContainer;
