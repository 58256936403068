import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ExpansionPanel } from './shared';
import {
  MediaIconMovie,
  MediaIconMusic,
  MediaIconVideo,
  MediaIconDocument,
  MediaIconImage,
  MediaIconQuiz,
  MediaIconLink,
} from './icons';
import clsx from 'clsx';
import { cloneDeep, isEmpty, isEqual, set } from 'lodash';
import { useContribution } from 'pages/ContributionView/hooks';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { hexToRgba, lightOrDark } from 'utils/utils';
import { VerticalTimelineItem } from './VerticalTimeline';
import useAccount from 'hooks/useAccount';
import { TOOLTIP } from '../../../../constants';
import { Tooltip } from '@material-ui/core';
import useScreenView from 'hooks/useScreenView';
import { useChangedProps } from 'hooks/index';

const LabelTagText = styled.div`
  font-family: Avenir;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.5rem; /* 240% */
`;
const LabelTagTextWrapper = styled.div`
  display: flex;
  min-height: 1.25rem;
  padding: 0rem 0.4375rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Primary-Blue, #215c73);
`;

const SessionTypeLabelTagTextWrapper = styled(LabelTagTextWrapper)`
  background: ${({ backgroundColor }) => backgroundColor || 'var(--Cohere-Primary-White, #fff)'};
  border-color: ${({ borderColor }) => borderColor || 'var(--Cohere-Primary-Blue, #215c73)'};
`;

const SessionTypeLabelTagText = styled(LabelTagText)`
  color: ${({ textColor }) => textColor || 'var(--Cohere-Primary-Blue, #215c73)'};
`;

const SummaryStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.625rem;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25rem;
  min-height: 3.875rem;
  /* gap: 0.5rem; */
  padding-bottom: 0.5rem;
  .tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    ${({ isClient }) =>
      isClient &&
      css`
        margin-left: -17px;
      `}

    /* border: 1px solid green; */

      .tag {
    }
  }
  .summary {
    display: flex;
    flex-direction: row;
    .drag-icon-wrapper {
      line-height: 1;
      .drag-icon {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
        color: ${({ dragIconColor }) => (dragIconColor ? `rgba(${hexToRgba(dragIconColor, 0.4)})` : '#b2cae0')};
        /* ${({ dragDisabled }) =>
          dragDisabled
            ? css`
                cursor: default;
              `
            : css`
                cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
              `} */
      }
    }
    .title-container {
      display: flex;
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      line-height: 1.49625rem; /* 149.625% */

      max-width: calc(
        ${({ mobileView }) => (mobileView ? '100vw' : '336px')} -
          ${({ isClient, isSelfPaced }) => (isClient ? (isSelfPaced ? 77 : 101) : 77)}px -
          ${({ isSelfPaced }) => (isSelfPaced ? '24px' : '0px')}
      );
      > div:first-child {
        white-space: nowrap;
      }
    }
    .title {
      /* display: flex;
        flex-direction: row;
        justify-content: flex-start; */

      color: ${({ isDarkModeEnabled, isCoach, themedColor, newThemedTextColor }) =>
        isDarkModeEnabled ? (isCoach ? themedColor : newThemedTextColor) : '#282b2b'};
      font-family: Avenir;
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      line-height: 1.49625rem; /* 149.625% */

      overflow: ${({ isExpanded }) => (isExpanded ? 'unset' : 'hidden')};
      text-overflow: ${({ isExpanded }) => (isExpanded ? 'unset' : 'ellipsis')};
      white-space: ${({ isExpanded }) => (isExpanded ? 'unset' : 'nowrap')};
      /* max-width: ${({ mobileView }) => (mobileView ? 498 : 249)}px; */
    }
  }
  .sub-title {
    color: ${({ isDarkModeEnabled, isCoach, newThemedTextColor, themedColor }) =>
      isDarkModeEnabled ? (isCoach ? themedColor : newThemedTextColor) : '#000'};
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.49625rem; /* 171% */
  }
`;

const SessionTypeLabelTag = ({ children, textColor = null, borderColor = null, backgroundColor = null }) => {
  return (
    <SessionTypeLabelTagTextWrapper {...{ borderColor, backgroundColor }}>
      <SessionTypeLabelTagText {...{ textColor }}>{children}</SessionTypeLabelTagText>
    </SessionTypeLabelTagTextWrapper>
  );
};

const TodayLabelTag = ({ children, textColor = null, borderColor = null, backgroundColor = null }) => {
  const TodayLabelTagText = styled(LabelTagText)`
    color: ${textColor || 'var(--Cohere-Primary-White, #fff)'};
  `;
  const TodayLabelTagTextWrapper = styled(LabelTagTextWrapper)`
    background: ${backgroundColor || 'var(--Cohere-Primary-Blue, #215c73)'};
    border-color: ${borderColor || 'var(--Cohere-Primary-Blue, #215c73)'};
  `;
  return (
    <TodayLabelTagTextWrapper>
      <TodayLabelTagText>{children}</TodayLabelTagText>
    </TodayLabelTagTextWrapper>
  );
};
const SummaryBase = props => {
  const {
    index = 0,
    title = '',
    tags = <></>,
    subTitle = null,
    isExpanded = false,
    dragDisabled = false,
    dragProps = {},
    dragIconColor = null,
    isSelfPaced = false,
  } = props;
  const { isCoach, isClient } = useAccount();
  const contribution = useContribution();
  const { isDarkModeEnabled } = contribution;
  const { themedColor, newThemedTextColor } = getThemedColors(contribution);
  const { mobileView } = useScreenView();
  const isDragging = !dragDisabled && dragProps?.dragSnapshot.isDragging;

  const name = `${index + 1}. ${title}`;
  const TitleComponent = !isExpanded ? Tooltip : Fragment;
  return (
    <SummaryStyled
      {...{
        mobileView,
        dragIconColor,
        dragDisabled,
        isDragging,
        isDarkModeEnabled,
        isCoach,
        themedColor,
        newThemedTextColor,
        isClient,
        isSelfPaced,
        isExpanded,
      }}
    >
      <div className="tags">
        {tags.map(k => (
          <>{k}</>
          // <div className='tag'>{k}</div>
        ))}
      </div>
      <div className="summary">
        {!dragDisabled && (
          <div
            className="drag-icon-wrapper"
            {...dragProps?.dragProvided?.dragHandleProps}
            style={{
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
            // onClick={e => {
            //   alert(12);
            //   e.preventDefault();
            //   e.stopPropagation();
            // }}
          >
            <DragIndicatorIcon className="drag-icon" />
          </div>
        )}
        <TitleComponent title={name} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
          <div className="title-container">
            <div>{index + 1}.&nbsp;</div>
            <div className="title">{title}</div>
          </div>
        </TitleComponent>
      </div>
      {subTitle && <div className="sub-title">{subTitle}</div>}
    </SummaryStyled>
  );
};
const SessionCardContentWrapper = styled.div`
  display: flex;
  /* width: 18.875rem; */
  padding: 0.625rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};

  user-select: ${({ disableUserSelect = false }) => (disableUserSelect ? 'none' : 'auto')};

  border-radius: 0.25rem;
  border: ${({ isClient, borderColor }) =>
    isClient ? 'unset' : `1px solid ${borderColor || '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)'}`};
  &:hover {
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid
      ${({ borderColorHover, borderColor }) =>
        borderColorHover || borderColor || 'var(--Cohere-Greys-Grey-Tint, #dfe3e4)'};
    background: ${({ backgroundColorHover, backgroundColor }) => backgroundColorHover || backgroundColor || '#fff'};

    /* cards dropshadow */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }
  &.active {
    border-radius: 0.25rem;
    border: ${({ isClient, borderColorActive, borderColor }) =>
      isClient
        ? 'unset'
        : `1px solid ${borderColorActive || borderColor || '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)'}`};
    background: ${({ backgroundColorActive, backgroundColor }) =>
      backgroundColorActive || backgroundColor || 'var(--Cohere-Greys-Background-Grey, #f5f5f5)'};
  }
`;
const SessionCardContentWrapperTimelineItem = styled(VerticalTimelineItem)`
  display: flex;
  /* width: 18.875rem; */
  padding: 0.625rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};

  user-select: ${({ disableUserSelect = false }) => (disableUserSelect ? 'none' : 'auto')};

  border-radius: 0.25rem;
  border: ${({ isClient, borderColor }) =>
    isClient ? 'unset' : `1px solid ${borderColor || '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)'}`};
  &:hover {
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid
      ${({ borderColorHover, borderColor }) =>
        borderColorHover || borderColor || 'var(--Cohere-Greys-Grey-Tint, #dfe3e4)'};
    background: ${({ backgroundColorHover, backgroundColor }) => backgroundColorHover || backgroundColor || '#fff'};

    /* cards dropshadow */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }
  &.active {
    border-radius: 0.25rem;
    border: ${({ isClient, borderColorActive, borderColor }) =>
      isClient
        ? 'unset'
        : `1px solid ${borderColorActive || borderColor || '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)'}`};
    background: ${({ backgroundColorActive, backgroundColor }) =>
      backgroundColorActive || backgroundColor || 'var(--Cohere-Greys-Background-Grey, #f5f5f5)'};
  }
`;
const SessionCardContent = styled.div`
  display: flex;
  min-height: 3rem;
  /* padding: 0.5rem 1rem; */
  padding: 0rem 1rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
`;
const SessionCardTitle = styled.div`
  max-width: 18.5875rem;
  min-height: 1.225rem;

  color: ${({ textColor }) => textColor || 'var(--cohere-greys-000000, #000)'};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49625rem; /* 171% */

  max-width: 100%;
  /* ${({ isSelfPaced = false, isCoach = false }) =>
    isSelfPaced
      ? css`
          max-width: calc(100% - 0.625rem - ${isCoach ? '10px' : '0px'});
        `
      : css`
          max-width: 100%;
        `} */
  ${({ mobileView, isSelfPaced, isCoach, isClient }) =>
    // mobileView &&
    isSelfPaced &&
    isClient &&
    (mobileView
      ? css`
          min-width: 18rem;
        `
      : css`
          min-width: 13rem;
        `)}
`;
const SessionCardContentTypeIcon = styled.div`
  display: flex;
  align-items: center;
  ${({ styles }) => styles}
`;
const Left = styled.div`
  svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${({ dragIconColor }) =>
      dragIconColor ? `rgba(${hexToRgba(dragIconColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)'};
    ${({ dragDisabled, isDragging, isClient }) =>
      isClient || dragDisabled
        ? css`
            cursor: default;
          `
        : css`
            cursor: ${isDragging ? 'grabbing' : 'grab'};
          `}
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;

  max-width: 100%;
  ${({ mobileView, isSelfPaced, isCoach, isClient }) =>
    isSelfPaced &&
    (mobileView
      ? css`
          max-width: calc(100% - 1rem - ${(isCoach && 19) || (isClient && 0) || 0}px);
        `
      : css`
          max-width: calc(100% - 1rem - ${(isCoach && 5) || 0}px);
        `)}
`;

export const LiveSessionExpansionCard = memo(props => {
  const {
    children,
    title,
    subTitle = null,
    serialNo,
    isToday = false,
    disableDrag = true,
    dragProps = null,
    expanded = false,
    isClient = false,
    timeline = {},
    id = null,
    ...rest
  } = props;
  const [isExpanded, setExpanded] = useState(expanded);
  const dragDisabled = disableDrag || isEmpty(dragProps);
  const contribution = useContribution();
  const { isDarkModeEnabled } = contribution;
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  const textColorAccent =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const sessionTypeLabelProps = {
    textColor: accentColor,
    borderColor: accentColor,
    backgroundColor: isDarkModeEnabled && 'transparent',
  };
  const todayLabelProps = {
    textColor: textColorAccent,
    borderColor: accentColor,
    backgroundColor: accentColor,
  };

  // useEffect(() => {
  //   setExpanded(expanded);
  // }, [expanded])

  const Summary = (
    <SummaryBase
      subTitle={isExpanded ? null : subTitle || 'Upcoming on Dec 13 2023 at 9:00am'}
      index={serialNo}
      tags={[
        <SessionTypeLabelTag {...sessionTypeLabelProps}>Live Session</SessionTypeLabelTag>,
        isToday && <TodayLabelTag {...todayLabelProps}>Today</TodayLabelTag>,
      ].filter(k => !!k)}
      isExpanded={isExpanded}
      {...{
        title,
        dragDisabled,
        dragProps,
        isSelfPaced: false,
      }}
    />
  );
  const itm = (
    <ExpansionPanel summaryTitle={Summary} rootElProps={{ id }} expanded={isExpanded || expanded} {...rest}>
      {expanded => {
        setExpanded(expanded);
        return typeof children === 'function' ? children(isExpanded) : children;
      }}
    </ExpansionPanel>
  );
  return isClient ? <VerticalTimelineItem {...timeline}>{itm}</VerticalTimelineItem> : itm;
}, isEqual);
export const SelfPacedModuleExpansionCard = props => {
  const {
    children,
    title,
    subTitle = null,
    serialNo,
    isToday = false,
    disableDrag = false,
    dragProps = null,
    expanded = false,
    isClient = false,
    timeline = {},
    id = null,
    ...rest
  } = props;
  const [isExpanded, setExpanded] = useState(expanded);
  const dragDisabled = disableDrag || isEmpty(dragProps);
  const contribution = useContribution();
  const { isDarkModeEnabled } = contribution;
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  const textColorAccent =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const sessionTypeLabelProps = {
    textColor: accentColor,
    borderColor: accentColor,
    backgroundColor: isDarkModeEnabled && 'transparent',
  };
  const todayLabelProps = {
    textColor: textColorAccent,
    borderColor: accentColor,
    backgroundColor: accentColor,
  };

  // useEffect(() => {
  //   setExpanded(expanded);
  // }, [expanded])

  const Summary = (
    <SummaryBase
      // subTitle={isExpanded ? null : subTitle || ''}
      index={serialNo}
      tags={[
        <SessionTypeLabelTag {...sessionTypeLabelProps}>Self-Paced</SessionTypeLabelTag>,
        isToday && !isExpanded && <TodayLabelTag {...todayLabelProps}>Today</TodayLabelTag>,
      ].filter(k => !!k)}
      {...{
        isExpanded,
        title,
        dragDisabled,
        dragProps,
        dragIconColor: accentColor,
        isSelfPaced: true,
      }}
    />
  );

  const itm = (
    <ExpansionPanel
      summaryTitle={Summary}
      rootElProps={{
        id,
        ...(dragDisabled
          ? {}
          : {
              ref: dragProps?.dragProvided?.innerRef,
              ...dragProps?.dragProvided?.draggableProps,
              // ...dragProps?.dragProvided?.dragHandleProps,
              // style: {
              //   // background: dragProps?.dragSnapshot?.isDragging ? '#263B4A' : 'unset',
              //   boxShadow: 'none',
              //   border: dragProps?.dragSnapshot?.isDragging
              //     ? '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)'
              //     : 'none',
              //     ...dragProps?.dragProvided?.draggableProps?.style,
              // },
            }),
      }}
      style={{
        // background: dragProps?.dragSnapshot?.isDragging ? '#263B4A' : 'unset',
        // boxShadow: 'none',
        // border: dragProps?.dragSnapshot?.isDragging ? '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)' : '',
        ...dragProps?.dragProvided?.draggableProps?.style,
      }}
      // style={css`
      //   // background: dragProps?.dragSnapshot?.isDragging ? '#263B4A' : 'unset',
      //   // boxShadow: 'none',
      //   border: ${() => dragProps?.dragSnapshot?.isDragging ? '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)' : ''};
      //   ${...dragProps?.dragProvided?.draggableProps?.style};
      //   `}
      // {...(!dragDisabled && {
      //   ref: dragProps?.dragProvided?.innerRef,
      //   // ...dragProps?.dragProvided?.draggableProps,
      //   // ...dragProps?.dragProvided?.dragHandleProps,
      //   dragDisabled,
      //   dragProps,
      //   style: {
      //     // background: dragProps?.dragSnapshot.isDragging ? '#263B4A' : 'unset',
      //     border: dragProps?.dragSnapshot?.isDragging ? '1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4)' : 'none',
      //     ...dragProps?.dragProvided?.draggableProps?.style,
      //   },
      // })}
      expanded={isExpanded}
      {...rest}
    >
      {expanded => {
        setExpanded(expanded);
        return typeof children === 'function' ? children(isExpanded) : children;
      }}
    </ExpansionPanel>
  );
  return isClient ? <VerticalTimelineItem {...timeline}>{itm}</VerticalTimelineItem> : itm;
};

export const LiveSessionContentCard = props => {
  const { title = '', icon = <></>, onClick = null, selected = false, isClient = false, id = null } = props;
  const [isSelected, setSelected] = useState(selected);
  const { isCoach } = useAccount();
  const contribution = useContribution();
  const { isDarkModeEnabled } = contribution;
  const {
    themedBackgroundColor,
    newThemedBackgroundColor,
    themedCardBackgroundColor,
    newThemedCardColor,
    themedCardOutlineColor,
    themedColor,
    newThemedTextColor,
  } = getThemedColors(contribution);
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  useEffect(() => setSelected(selected), [selected]);
  const mediaIconStyles = useMemo(() => {
    const color = accentColor ? `rgba(${hexToRgba(accentColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)';
    return css`
      svg {
        color: ${() => color};
        path {
          fill: ${() => color};
        }
      }
    `;
  }, [accentColor]);
  const onSelection = () => {
    setSelected(!isSelected);
    if (onClick) {
      onClick();
    }
  };
  return (
    <SessionCardContentWrapper
      id={id}
      className={clsx(isSelected && 'active')}
      onClick={onSelection}
      isClient={isClient}
      backgroundColorActive={isDarkModeEnabled && (isCoach ? themedBackgroundColor : newThemedBackgroundColor)}
      backgroundColor={isDarkModeEnabled && (isCoach ? themedCardBackgroundColor : newThemedCardColor)}
      borderColor={isDarkModeEnabled ? themedCardOutlineColor : 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'}
    >
      <SessionCardContent>
        <Right>
          <SessionCardTitle textColor={isDarkModeEnabled && (isCoach ? themedColor : newThemedTextColor)}>
            {title}
          </SessionCardTitle>
          <SessionCardContentTypeIcon styles={mediaIconStyles}>{icon}</SessionCardContentTypeIcon>
        </Right>
      </SessionCardContent>
    </SessionCardContentWrapper>
  );
};
export const SelfPacedContentCard = props => {
  const {
    title = '',
    icon = <></>,
    iconStyles = null,
    onClick = null,
    disableDrag = false,
    dragProps = null,
    selected = false,
    isClient = false,
    timeline = {},
    id = null,
  } = props;
  const { isCoach } = useAccount();
  const [isSelected, setSelected] = useState(selected);
  const contribution = useContribution();
  const { mobileView } = useScreenView();
  const { isDarkModeEnabled } = contribution;
  const colorToUse = determineColorToUse(contribution);
  const {
    themedBackgroundColor,
    newThemedBackgroundColor,
    themedCardBackgroundColor,
    newThemedCardColor,
    themedCardOutlineColor,
    themedColor,
    newThemedTextColor,
  } = getThemedColors(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  useEffect(() => setSelected(selected), [selected]);
  const onSelection = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSelected(!isSelected);
    if (onClick) {
      onClick();
    }
  };
  const dragDisabled = disableDrag || isEmpty(dragProps);
  const mediaIconStyles = useMemo(() => {
    const color = accentColor ? `rgba(${hexToRgba(accentColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)';
    return css`
      svg {
        color: ${() => color};
        path {
          fill: ${() => color};
        }
      }
      ${iconStyles}
    `;
  }, [accentColor]);
  const MainContainer = isClient ? SessionCardContentWrapperTimelineItem : SessionCardContentWrapper;
  return (
    <MainContainer
      id={id}
      className={clsx(isSelected && 'active')}
      onClick={onSelection}
      disableUserSelect
      {...(!dragDisabled && {
        ref: dragProps?.dragProvided?.innerRef,
        ...dragProps?.dragProvided?.draggableProps,
        // ...dragProps?.dragProvided?.dragHandleProps,
        style: {
          // background: dragProps?.dragSnapshot.isDragging ? '#263B4A' : 'unset',
          ...dragProps?.dragProvided?.draggableProps?.style,
        },
      })}
      isClient={isClient}
      {...(isClient && timeline)}
      backgroundColorActive={isDarkModeEnabled && (isCoach ? themedBackgroundColor : newThemedBackgroundColor)}
      backgroundColor={isDarkModeEnabled && (isCoach ? themedCardBackgroundColor : newThemedCardColor)}
      borderColor={isDarkModeEnabled ? themedCardOutlineColor : 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'}
    >
      <SessionCardContent>
        {!dragDisabled && isCoach && (
          <Left
            dragIconColor={accentColor}
            {...{ dragDisabled, isDragging: dragProps?.dragSnapshot.isDragging }}
            {...dragProps?.dragProvided?.dragHandleProps}
          >
            <DragIndicatorIcon className="drag-icon" />
          </Left>
        )}
        <Right isSelfPaced {...{ isCoach, isClient, mobileView }}>
          <SessionCardTitle
            isSelfPaced
            {...{ isCoach, isClient, mobileView }}
            textColor={isDarkModeEnabled && (isCoach ? themedColor : newThemedTextColor)}
          >
            {title}
          </SessionCardTitle>
          <SessionCardContentTypeIcon styles={mediaIconStyles}>{icon}</SessionCardContentTypeIcon>
        </Right>
      </SessionCardContent>
    </MainContainer>
  );
  // return (
  //   <SessionCardContentWrapper
  //     className={clsx(isSelected && 'active')}
  //     onClick={onSelection}
  //     disableUserSelect
  //     {...(!dragDisabled && {
  //       ref: dragProps?.dragProvided?.innerRef,
  //       ...dragProps?.dragProvided?.draggableProps,
  //       // ...dragProps?.dragProvided?.dragHandleProps,
  //       style: {
  //         // background: dragProps?.dragSnapshot.isDragging ? '#263B4A' : 'unset',
  //         ...dragProps?.dragProvided?.draggableProps?.style,
  //       },
  //     })}
  //     isClient={isClient}
  //   >
  //     <SessionCardContent>
  //       <>
  //         {isClient ? (
  //           <VerticalTimelineItem {...timeline}>
  //             {/* <Left></Left> */}
  //             <Right>
  //               <SessionCardTitle>{title}</SessionCardTitle>
  //               <SessionCardContentTypeIcon styles={mediaIconStyles}>{icon}</SessionCardContentTypeIcon>
  //             </Right>
  //           </VerticalTimelineItem>
  //         ) : (
  //           <>
  //             {!dragDisabled && !isClient && (
  //               <Left
  //                 dragIconColor={accentColor}
  //                 {...{ dragDisabled, isDragging: dragProps?.dragSnapshot.isDragging }}
  //                 {...dragProps?.dragProvided?.dragHandleProps}
  //               >
  //                 <DragIndicatorIcon className="drag-icon" />
  //               </Left>
  //             )}
  //             <Right>
  //               <SessionCardTitle>{title}</SessionCardTitle>
  //               <SessionCardContentTypeIcon styles={mediaIconStyles}>{icon}</SessionCardContentTypeIcon>
  //             </Right>
  //           </>
  //         )}
  //       </>
  //     </SessionCardContent>
  //   </SessionCardContentWrapper>
  // );
  // const cnt = (
  //   <SessionCardContent>
  //     {!dragDisabled && !isClient && (
  //       <Left
  //         dragIconColor={accentColor}
  //         {...{ dragDisabled, isDragging: dragProps?.dragSnapshot.isDragging }}
  //         {...dragProps?.dragProvided?.dragHandleProps}
  //       >
  //         <DragIndicatorIcon className="drag-icon" />
  //       </Left>
  //     )}
  //     {isClient && <Left isClient={isClient}></Left>}
  //     <Right>
  //       <SessionCardTitle>{title}</SessionCardTitle>
  //       <SessionCardContentTypeIcon styles={mediaIconStyles}>{icon}</SessionCardContentTypeIcon>
  //     </Right>
  //   </SessionCardContent>
  // );
  // const itm = (
  //   <SessionCardContentWrapper
  //     className={clsx(isSelected && 'active')}
  //     onClick={onSelection}
  //     disableUserSelect
  //     {...(!dragDisabled && {
  //       ref: dragProps?.dragProvided?.innerRef,
  //       ...dragProps?.dragProvided?.draggableProps,
  //       // ...dragProps?.dragProvided?.dragHandleProps,
  //       style: {
  //         // background: dragProps?.dragSnapshot.isDragging ? '#263B4A' : 'unset',
  //         ...dragProps?.dragProvided?.draggableProps?.style,
  //       },
  //     })}
  //     isClient={isClient}
  //   >
  //     {isClient ? <VerticalTimelineItem {...timeline}>{cnt}</VerticalTimelineItem> : cnt}
  //   </SessionCardContentWrapper>
  // );
  // // return isClient ? <VerticalTimelineItem {...timeline}>{itm}</VerticalTimelineItem> : itm;
  // return itm;
};

export const LiveSessionTimeCard = props => {
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  const iconStyles = css`
    svg {
      width: 1.125rem;
      height: 0.75rem;

      /* fill: ${accentColor ? `rgba(${(accentColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)'}; */
    }
  `;

  return (
    <LiveSessionContentCard
      {...{
        icon: <MediaIconMovie />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedVideoContentCard = props => {
  // const StyledMediaIcon = styled(MediaIconVideo)`
  //   width: 3rem !important;
  //   height: 3rem !important;
  //   .svg-icon {
  //     /* width: 2.5rem !important;
  //     height: 2.5rem !important; */
  //     width: 100%;
  //     height: 100%;
  //     flex-shrink: 0;
  //     fill: var(--Cohere-Bluish-Grey-Tint-04, #b2cae0);
  //   }
  //   .MuiSvgIcon-root {
  //     width: 2rem !important;
  //     height: 2rem !important;
  //   }
  //   /* .svg-icon < div {
  //     width: 100px !important;
  //   } */
  // `;

  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  const iconStyles = css`
    svg {
      width: 1rem;
      height: 1rem;

      /* fill: ${accentColor ? `rgba(${(accentColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)'}; */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconVideo />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedImageContentCard = props => {
  const iconStyles = css`
    svg {
      width: 1rem !important;
      height: 1rem !important;
      flex-shrink: 0;

      /* fill: var(--Cohere-Bluish-Grey-Tint-04, #b2cae0); */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconImage />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedDocumentContentCard = props => {
  const iconStyles = css`
    svg {
      width: 0.84375rem;
      height: 1.125rem;

      /* fill: var(--Cohere-Bluish-Grey-Tint-04, #b2cae0); */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconDocument />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedMusicContentCard = props => {
  const iconStyles = css`
    svg {
      width: 0.9rem;
      height: 1.125rem;

      /* fill: var(--Cohere-Bluish-Grey-Tint-04, #b2cae0); */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconMusic />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedQuizContentCard = props => {
  const iconStyles = css`
    svg {
      width: 1.125rem;
      height: 0.89588rem;

      /* fill: var(--Cohere-Bluish-Grey-Tint-04, #b2cae0); */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconQuiz />,
        iconStyles,
        ...props,
      }}
    />
  );
};
export const SelfPacedEmbeddedLinkContentCard = props => {
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const primaryColor = colorToUse?.PrimaryColorCode;
  const accentColor = colorToUse?.AccentColorCode;
  const iconStyles = css`
    svg {
      /* width: 1.5rem;
      height: 1rem; */
      width: fit-content;
      height: fit-content;

      /* fill: ${accentColor ? `rgba(${(accentColor, 0.4)})` : 'var(--Cohere-Bluish-Grey-Tint-04, #b2cae0)'}; */
    }
  `;
  return (
    <SelfPacedContentCard
      {...{
        icon: <MediaIconLink />,
        iconStyles,
        ...props,
      }}
    />
  );
};
