import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CKEditor } from 'ckeditor4-react';
import TextArea from 'components/FormUI/TextArea';
import styled, { css } from 'styled-components';
import { useStyles } from './hooks/useStyles';
import Modal from 'components/UI/Modal';
import { convertToPlainText } from 'utils/utils';

const StyledEditorWrapper = styled.div`
  .cke {
    ${({ mainBorder }) =>
      mainBorder
        ? css`
            border: ${mainBorder};
          `
        : css``}
  }
  .cke_chrome {
    border-radius: 0 0 4px 4px;
    /* border-bottom: none; */
  }
  .cke_inner {
    .cke_contents {
      border-radius: 0 0 4px 4px;
    }
    .cke_bottom {
      display: none;
    }
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_top {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_reset_all * {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }

  .cke_button_icon {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
    ${({ isDarkThemeEnabled }) =>
      isDarkThemeEnabled &&
      css`
        filter: invert(100%);
      `}
  }

  .cke_button {
    &:hover {
      .cke_button_icon {
        ${({ isDarkThemeEnabled }) =>
          isDarkThemeEnabled &&
          css`
            filter: none;
          `}
      }
    }
  }

  .cke_combo_off a.cke_combo_button:hover {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
  .cke_combo_arrow {
    border-top-color: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit'};
  }
  .cke_editable {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }
  .cke_bottom {
    /* visibility: hidden; */
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
`;

export const SessionMoreInfo = props => {
  const { isDisabled, onChange, value, title, isSessionTime } = props;
  const classNames = useStyles();
  const [editPopUp, setEditPopUp] = useState(false);
  const [textValue, setTextValue] = useState(value);
  const ref = useRef();
  const handleCloseModal = () => {
    onChange({ target: { name: 'moreInfo', value: textValue } });
    setEditPopUp(false);
  };

  const handleEditorChange = event => {
    const editorContent = convertToPlainText(event.editor.getData().trim());
    setTextValue(event.editor.getData());
  };

  const toolbarConfig = useMemo(
    () => [
      // { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
      {
        name: 'paragraph',
        items: [
          'Blockquote',
          'CreateDiv',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl',
          'Language',
        ],
      },
      { name: 'undo', items: ['Undo', 'Redo'] },
      { name: 'styles', items: ['Font', 'FontSize', '-'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'expand', items: ['Maximize'] },
    ],
    [],
  );

  return (
    <>
      <Grid alignItems="flex-start" container justify="flex-start">
        <Accordion className={classNames.accordion}>
          <AccordionSummary
            className={isSessionTime == 'true' ? classNames.summarySessionTime : classNames.summary}
            classes={{ content: classNames.summaryContent }}
            expandIcon={<ExpandMoreIcon style={{ color: isSessionTime == true ? 'black' : 'black' }} />}
          >
            <div style={{ fontFamily: 'Avenir', size: '17px', color: '#000000DE' }}>{title}</div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px' }}>
            <div
              onClick={() => {
                setEditPopUp(true);
              }}
              style={{
                padding: '15px 10px',
                border: '1px solid #eaf0f2',
                width: '100%',
                // marginTop: '20px',
                cursor: 'pointer',
                minHeight: '100px',
              }}
              dangerouslySetInnerHTML={{ __html: value }}
            ></div>
            {/* <TextArea
            disabled={}
            fullWidth
            name="moreInfo"
            onChange={onChange}
            rows={3}
            textAreaClassNames={isSessionTime == 'true' ? classNames.textAreaSessionTime : classNames.textArea}
            value={value}
          /> */}
          </AccordionDetails>
        </Accordion>
      </Grid>
      {editPopUp === true && (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          isOpen={editPopUp}
          onSubmit={handleCloseModal}
          title={'Add More Info'}
          hiddenCancel
          submitTitle={'Save'}
          onCancel={handleCloseModal}
          widthRequired={false}
          widthRequiredIs={false}
        >
          <div style={{ marginBottom: '20px', width: '750px' }}>
            <StyledEditorWrapper>
              <CKEditor
                ref={ref}
                config={{
                  allowedContent: true,
                  height: '350px',

                  toolbar: toolbarConfig,
                  pasteImage: true,
                  extraPlugins: [
                    'font',
                    'colorbutton',
                    'justify',
                    'colordialog',
                    'uploadimage',
                    'filebrowser',
                    'image2',
                    'selectall',
                  ],
                }}
                initData={textValue}
                onChange={handleEditorChange}
              />
            </StyledEditorWrapper>
            {/* <TextArea
              fullWidth
              name="moreInfo"
              onChange={e => {
                setTextValue(e.target.value);
              }}
              rows={3}
              textAreaClassNames={isSessionTime == 'true' ? classNames.textAreaSessionTime : classNames.textArea}
              value={textValue}
            /> */}
          </div>
        </Modal>
      )}
    </>
  );
};

SessionMoreInfo.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SessionMoreInfo.defaultProps = {
  onChange: null,
  value: null,
  isDisabled: false,
};
