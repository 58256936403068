import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions, useRouter } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classes from './LeadsTable.module.scss';
import Lead from '../Lead/Lead';
import { updateClientNotes } from 'services/clients.service';
import NotesModal from 'components/Notes/NotesModal';
import useClientNote from 'hooks/useClientNote';
import { useDispatch, useSelector } from 'react-redux';
import useSaveOnTypping from 'pages/ContributionView/components/NoteModal/hooks/useSaveOnTypping';
import { setCloseDisabled, setNotesStatus } from 'actions/contributions';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const LeadsTable = ({ leads = [], isChecked, onCheckClicked, handleAddLeadClick, searchedKeyword }) => {
  const [popupText, setPopupText] = useState(null);
  const [openNotesOf, setOpenNotesOf] = useState(null);
  const { request } = useHttp();
  const [leadWithOpenDropDown, setLeadWithOpenDropDown] = useState(null);
  const theme = useTheme();
  const { user } = useAccount();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { saveOnTyping } = useSaveOnTypping();
  const dispatch = useDispatch();
  const closeDisabled = useSelector(state => state?.contributions?.closeDisabled);
  const {
    location: { pathname },
  } = useRouter();
  const isCreateOrEditCampaignPage = pathname.includes('create-campaign') || pathname.includes('edit-campaign');
  const setId = id => {
    setLeadWithOpenDropDown(id);
  };

  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    isNoteExist,
  } = useClientNote(openNotesOf);

  const handleSubmit = () => {
    setLoadingNotes(true);
    updateClientNotes({ clientId: openNotesOf, userId: user.id, note: editorDataUpdated || '' }).finally(() => {
      setLoadingNotes(false);
      setOpenNotesOf(null);
      setShowNotes(false);
      dispatch(setNotesStatus(''));
      dispatch(setCloseDisabled(false));
    });
  };

  useEffect(() => {
    if (closeDisabled) {
      saveOnTyping({ clientId: openNotesOf, userId: user.id, note: editorDataUpdated });
    }
  }, [editorDataUpdated]);

  return leads?.length === 0 && searchedKeyword.length > 0 ? (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
    </>
  ) : leads?.length === 0 && searchedKeyword.length === 0 ? (
    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '400' }}>
      No leads yet.{' '}
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontWeight: '400',
          cursor: 'pointer',
          textDecoration: 'underline',
          marginLeft: '3px',
        }}
        onClick={handleAddLeadClick}
      >
        click here to add a lead
      </span>
    </div>
  ) : (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Full Name</BoldTableCell>
              <BoldTableCell>Email</BoldTableCell>
              <BoldTableCell>Source</BoldTableCell>
              <BoldTableCell>Subscribed</BoldTableCell>
              {!isCreateOrEditCampaignPage && <BoldTableCell>Tags</BoldTableCell>}
              {!isCreateOrEditCampaignPage && <BoldTableCell align="center">Notes</BoldTableCell>}
              {!isCreateOrEditCampaignPage && <BoldTableCell>Action</BoldTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {leads?.map((row, index) => (
              <Lead
                key={index}
                index={index}
                row={row}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                leadWithOpenDropDown={leadWithOpenDropDown}
                setId={setId}
                setOpenNotesOf={setOpenNotesOf}
                totalCount={leads?.length}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setLoading={setLoadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={setOpenNotesOf}
        onSave={() => {
          handleSubmit();
        }}
        isNoteExist={isNoteExist}
      />
    </>
  );
};

export default LeadsTable;
