import Modal from 'components/UI/Modal';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import ExternalCalendarSettingsPopupContent from './ExternalCalendarSettingsPopupContent';
import * as yup from 'yup';
import { Form, Formik, useFormik } from 'formik';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import * as calendarsActions from 'actions/calendars';
import moment from 'moment';
import { isArray } from 'lodash';

const ExternalCalendarSettingsPopup = ({
  connectedCalendar = null,
  isModalOpen,
  onCloseModal,
  showExternalCalendarConnectionCompleteStepsModal = { showStep1: false, showStep2: false },
  setShowExternalCalendarConnectionCompleteStepsModal,
  addOrUpdateExternalCalendar,
  getNylasAccountForCohereByEmail,
  resetLastConnectedEmailAccount,
  calendars,
  isOneToOneContributionPage = false,
  isMasterCalendarPage = false,
  getBusyTime,
  oneToOneStartDate,
  oneToOneEndDate,
  onSuccessSave,
  closeJoyRide = () => {},
  setExternalCalendarSetToDefault = () => {},
}) => {
  const [showConfirmationMakeDefaultForGroup, setShowConfirmationMakeDefaultForGroup] = useState(false);
  const [showConfirmationMakeDefaultForOneToOne, setShowConfirmationMakeDefaultForOneToOne] = useState(false);
  const dispatch = useDispatch();
  const { loadingUpdateAccount, updateAccountError } = useSelector(s => s.calendars);
  const [isLoading, setLoading] = useState(false);
  const [currentCalendar, setCurrentCalendar] = useState(null);
  useEffect(() => {
    // TODO: here fetch settings for the account from API, if required fresh settings
    if (connectedCalendar?.emailAddress) {
      getNylasAccountForCohereByEmail(connectedCalendar?.emailAddress);
    }
  }, []);
  useEffect(() => {
    if (!loadingUpdateAccount) {
      const current = calendars?.find(k => k.emailAddress === connectedCalendar?.emailAddress);
      setCurrentCalendar(current);
    }
  }, [calendars, loadingUpdateAccount]);

  useEffect(() => {
    if (isModalOpen) {
      closeJoyRide();
    }
  }, [isModalOpen]);

  const inStepsMode =
    showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
    showExternalCalendarConnectionCompleteStepsModal.showStep2;
  const formikSchema = yup.object({
    subCalendars: yup.array(),
    preventBookingConflict: yup.boolean(),
    defaultForOneToOne: yup.boolean(),
    defaultForGroup: yup.boolean(),
    showEventsInInternalCalendar: yup.boolean(),
  });
  const formikInitialValues = useMemo(
    () => ({
      subCalendars: (currentCalendar?.subCalendars || [])?.sort((a, b) => b?.is_primary - a?.is_primary),
      preventBookingConflict: currentCalendar?.isCheckConflictsEnabled || false,
      defaultForOneToOne: currentCalendar?.isDefault || false,
      defaultForGroup: currentCalendar?.isDefaultForGroupCourseInvites || false,
      showEventsInInternalCalendar: currentCalendar?.isPreviewMasterCalendarEvents || false,
      isDefaultForAllOneToOneContributions: currentCalendar?.isDefaultForAllOneToOneContributions || false,
    }),
    [currentCalendar],
  );
  const onFormSubmit = (values, formikActions) => {
    const { setSubmitting } = formikActions;
    if (inStepsMode) {
      if (showExternalCalendarConnectionCompleteStepsModal.showStep1) {
        setShowExternalCalendarConnectionCompleteStepsModal({
          ...showExternalCalendarConnectionCompleteStepsModal,
          showStep1: false,
          showStep2: true,
        });
        setSubmitting(false);
        return;
      }
      if (showExternalCalendarConnectionCompleteStepsModal.showStep2) {
        setShowExternalCalendarConnectionCompleteStepsModal({
          ...showExternalCalendarConnectionCompleteStepsModal,
          showStep1: false,
          showStep2: false,
        });
      }
    }
    // TODO: here update API called (for both, step-wise and settings)
    const payload = {
      // ...connectedCalendar,
      email: currentCalendar?.emailAddress || '',
      isCheckConflictsEnabled: values?.preventBookingConflict || false,
      isPreviewMasterCalendarEvents: values?.showEventsInInternalCalendar || false,
      isDefaultForOneToOneInvites: values?.defaultForOneToOne || false,
      isDefaultForGroupCourseInvites: values?.defaultForGroup || false,
      subCalendars: values?.subCalendars?.filter(k => k.isSelected) || [],
      isDefaultForAllOneToOneContributions: values?.isDefaultForAllOneToOneContributions || false,
    };
    // dispatch(addOrUpdateExternalCalendar(payload));

    addOrUpdateExternalCalendar(payload)
      .then(() => {
        resetLastConnectedEmailAccount();
        setExternalCalendarSetToDefault(true);
        // call to fetch latest events for updated calendar (may be sub-calendars selected/deselected, etc)
        if (isMasterCalendarPage) {
          if (onSuccessSave) return onSuccessSave(currentCalendar?.emailAddress);
        }
        // here call API to get busy times list
        if (isOneToOneContributionPage) {
          return getBusyTime(
            moment(oneToOneStartDate).format('YYYY-MM-DD'),
            moment(oneToOneEndDate).format('YYYY-MM-DD'),
          );
        }
      })
      .then(() => {
        if (onCloseModal) onCloseModal();
      })
      // .catch(error => {
      //   // pipe(get('response'), get('data'), getBusytimeActions.error, dispatch)(error);
      // });
      .finally(() => {
        // setSubmitting(false);
        // setLoading(false);
      });
  };
  // const formik = useFormik({
  //   initialValues: formikInitialValues,
  //   validationSchema: formikSchema,
  //   onSubmit: onFormSubmit,
  // });
  // console.info('formik:', formik);
  // const { values, handleChange, setFieldValue, handleSubmit, submitForm, isSubmitting, handleReset } = formik;
  // useEffect(() => {
  //   setLoading(isSubmitting || loadingUpdateAccount);
  // }, [isSubmitting || loadingUpdateAccount]);
  return (
    <>
      {isLoading && <Loader />}
      <Formik
        enableReinitialize
        initialValues={formikInitialValues}
        onSubmit={onFormSubmit}
        validationSchema={formikSchema}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, submitForm, isSubmitting, handleReset }) => {
          setLoading(isSubmitting || loadingUpdateAccount);
          return (
            <>
              <Form>
                <Modal
                  applyTheming={false}
                  isDarkModeSelected={false}
                  isCreatingContribution
                  brandingColor={false}
                  isOpen={isModalOpen}
                  title={inStepsMode ? `Finish adding ${currentCalendar?.emailAddress}` : 'Settings'}
                  form={!inStepsMode || (inStepsMode && showExternalCalendarConnectionCompleteStepsModal.showStep2)}
                  onSubmit={handleSubmit}
                  submitTitle={
                    inStepsMode
                      ? (showExternalCalendarConnectionCompleteStepsModal.showStep1 && 'Next') ||
                        (showExternalCalendarConnectionCompleteStepsModal.showStep2 && 'Setup calendar') ||
                        'Save'
                      : 'Save'
                  }
                  // disableSubmitClick={loadingSlots}
                  onCancel={() => {
                    if (onCloseModal) onCloseModal();
                  }}
                  isBackButton={inStepsMode}
                  onBack={() => {
                    if (showExternalCalendarConnectionCompleteStepsModal.showStep1) {
                      setShowExternalCalendarConnectionCompleteStepsModal({
                        ...showExternalCalendarConnectionCompleteStepsModal,
                        showStep1: false,
                        showStep2: false,
                      });
                      if (onCloseModal) onCloseModal();
                    } else if (showExternalCalendarConnectionCompleteStepsModal.showStep2) {
                      setShowExternalCalendarConnectionCompleteStepsModal({
                        ...showExternalCalendarConnectionCompleteStepsModal,
                        showStep1: true,
                        showStep2: false,
                      });
                    }
                  }}
                  // onClose={(_, reason) => {
                  //   console.info('reason:', reason)
                  //   if (reason === 'backdropClick') {
                  //     if (onCloseModal) onCloseModal();
                  //   }
                  // }}
                  disableOverlayClick={inStepsMode}
                  cancelTitle={
                    inStepsMode
                      ? (showExternalCalendarConnectionCompleteStepsModal.showStep1 && 'Cancel') ||
                        (showExternalCalendarConnectionCompleteStepsModal.showStep2 && 'Back') ||
                        'Cancel'
                      : 'Cancel'
                  }
                  className={classnames('external-calendar-settings-modal', inStepsMode && 'steps-mode')}
                >
                  <div
                    className="connected-calendar-container"
                    style={inStepsMode ? { border: 'none' } : {}}
                    // onSubmit={handleSubmit}
                  >
                    <ExternalCalendarSettingsPopupContent
                      {...{
                        connectedCalendar: currentCalendar,
                        showConfirmationMakeDefaultForGroup,
                        setShowConfirmationMakeDefaultForGroup,
                        showConfirmationMakeDefaultForOneToOne,
                        setShowConfirmationMakeDefaultForOneToOne,
                        showExternalCalendarConnectionCompleteStepsModal,
                        setShowExternalCalendarConnectionCompleteStepsModal,
                        formikProps: {
                          values,
                          handleChange,
                          setFieldValue,
                          handleReset,
                        },
                        loading: isLoading,
                        calendars,
                        oneToOneDefaultExists: isArray(calendars)
                          ? calendars?.some(k => k.isDefault && k.emailAddress !== currentCalendar?.emailAddress)
                          : false,
                      }}
                    />
                  </div>
                </Modal>
                {showConfirmationMakeDefaultForGroup && (
                  <Modal
                    isOpen={showConfirmationMakeDefaultForGroup}
                    title="Are you sure you want to check this?"
                    submitTitle="Yes"
                    onSubmit={() => {
                      setShowConfirmationMakeDefaultForGroup(false);
                      setFieldValue('defaultForGroup', true);
                    }}
                    onCancel={() => {
                      setShowConfirmationMakeDefaultForGroup(false);
                    }}
                    className="confirmation-modal"
                  >
                    <p>
                      This is not usually recommended, your clients will see each other&apos;s emails. Are you sure you
                      wish to proceed?
                    </p>
                  </Modal>
                )}
                {showConfirmationMakeDefaultForOneToOne && (
                  <Modal
                    isOpen={showConfirmationMakeDefaultForOneToOne}
                    title="Are you sure you want to make this your default calendar to send 1:1 invites?"
                    submitTitle="Yes"
                    onSubmit={() => {
                      setShowConfirmationMakeDefaultForOneToOne(false);
                      setFieldValue('defaultForOneToOne', true);
                    }}
                    onCancel={() => {
                      setShowConfirmationMakeDefaultForOneToOne(false);
                    }}
                    isCreatingContribution={true}
                    brandingColor={false}
                    className="confirmation-modal"
                  >
                    <div>
                      This will mean your previously selected default calendar [
                      {(calendars || []).find(k => k.isDefault)?.emailAddress}] will no longer send invites, are you
                      sure?
                    </div>
                  </Modal>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const stateToProps = ({ calendars, oneToOneData }) => ({
  calendars: calendars.calendarsAccounts,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
});

const actions = {
  getBusyTime: calendarsActions.getBusyTime,
  addOrUpdateExternalCalendar: calendarsActions.addOrUpdateExternalCalendar,
  getNylasAccountForCohereByEmail: calendarsActions.getNylasAccountForCohereByEmail,
  resetLastConnectedEmailAccount: () => calendarsActions.calendarSignInCallbackActions.success(null),
};

export default connect(stateToProps, actions)(ExternalCalendarSettingsPopup);
