import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledCircularProgress = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const CircularProgressWithLabel = props => {
  return (
    <StyledCircularProgress className={props?.className || ''}>
      <CircularProgress
        {...props}
        style={{ color: props.progressColor || props.color || props?.colorToUse?.AccentColorCode }}
        variant="determinate"
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.mobileView === false && (
          <Typography
            style={{ color: props.textColor || props.color }}
            variant="caption"
            component="div"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </StyledCircularProgress>
  );
};
