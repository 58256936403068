import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FieldArray } from 'formik';
import { Icon } from '@mdi/react';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { isUndefined, isInteger } from 'lodash';
import moment from 'moment';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tooltip from '@material-ui/core/Tooltip';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import * as oneToOneDataActions from 'actions/oneToOneData';
import * as contributionActions from 'actions/contributions';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { generateOneToOneSlots } from 'pages/CreateContribution/utils';
import Input from 'components/FormUI/Input';
import Select from 'components/UI/Select';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import { colors, toRem } from 'utils/styles';
import { TOOLTIP } from 'constants.js';
import useVideoPlayer from 'hooks/useVideoPlayer';
import { SessionTime } from './SessionTime';
import { SessionTimeOneToOne, StyledLink } from './SessionTimeOneToOne';
import { useCheckSessionDuration } from '../hooks/useCheckSessionDuration';
import Dropzone from '../../../components/FormUI/Dropzone';
import { useVideoDurationParse } from '../hooks/useVideoDurationParse';
import Button from 'components/FormUI/Button';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import Modal from 'components/UI/Modal';
import DownIcon from 'components/Icons/DownIcon';
import UpIcon from 'components/Icons/UpSvg';
import { getSuffix } from '../utils/generalHelpers';
import { Switch, StyledSlider, StyledInput, SlimSwitch, SlimStyledSlider } from 'components/switch/style';
import { setShowCreateModal } from 'actions/quizes';

const useStyles = makeStyles(() => ({
  input_root: {
    fontFamily: 'Avenir',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '25.28px',
    texAlign: 'left',
    color: '#000000DE',
  },
}));

const StyledTitle = styled.h3`
  font-size: ${toRem(18)};
  font-weight: 700;
  line-height: 1.33;
  // letter-spacing: 0.12px;
  color: #000000;
  margin: 0;
  display: flex;
  align-items: baseline;

  ${({ mobileView }) =>
    mobileView &&
    `
    margin: 0px 10px;
  `}
  ${({ isLive }) =>
    isLive &&
    `
    width: 75%;
  `}
  ${({ isCompleted }) =>
    isCompleted &&
    `
  width: 65%;
  `}
`;

const StyledContainer = styled.div`
  margin-bottom: 22px;

  ${({ isPrerecorded }) =>
    !isPrerecorded &&
    `
    padding: 10px 16px !important;
    margin-top: 22px;
  `}
  // border: 1px solid #e7e7e7;
  // border-radius: 4px;
  border-bottom: 1px solid #e7e7e7;
  ${({ mobileView }) =>
    mobileView &&
    `
     margin-top: 50px;
  `}
  ${({ isSessionOpen, isPrerecorded }) =>
    isSessionOpen &&
    isPrerecorded &&
    `
     border: none;
     padding: 15px 0px;
     
  `} 
 
  ${({ isSessionOpen, isPrerecorded }) =>
    isSessionOpen && isPrerecorded
      ? ``
      : `
      // padding: 10px 16px !important
      `}
`;

const StyledHeader = styled.div`
  width: 100%;
  // border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  ${({ isLive }) =>
    isLive
      ? `
   justify-content: flex-start;
  `
      : `
   padding: 0px 13px 0px 0px;
  `}
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledType = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #215c73;
  color: #215c73;
  line-height: 30px;
  border-radius: 4px;
  padding: 0px 10px;
  // margin-left: 10px;
`;

const StyledVideo = styled.video`
  width: 100%;
  max-height: 200px;
  margin-top: 20px;
`;

const StyledSubHeading = styled.div`
  padding: ${({ isPrerecorded }) => (isPrerecorded ? '15px 0px 20px 56px' : '15px 0px 20px 58px')};
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.28px;
  letter-spacing: 0.16px;
`;

const StyledBadge = styled.span`
  background-color: ${colors.darkOceanBlue};
  color: white;
  padding: 4px 12px;
  font-size: ${toRem(11)};
  font-weight: 900;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 8px;
`;

const StyledSessionTimesContainer = styled.div`
  padding: 0 16px;

  ${mobileView => mobileView && `padding: 0`}
`;

const AutoCompleteText = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 19.5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0px 8px 0px 10px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledHeading = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 22.12px;
  text-align: left;
  color: #000000de;
  padding-bottom: 6px;
`;
const StyledAddQuizFormBtn = styled(Button)`
  background-color: #f5f5f5;
  color: #215c73;
  padding: 9px 8px;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e7e7e7;
  letter-spacing: 0px;
  margin-top: 10px;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const durationsOneToOne = [
  { title: '30 minutes', value: 30 },
  { title: '45 minutes', value: 45 },
  { title: '1 hour', value: 60 },
  { title: '90 minutes', value: 90 },
  { title: '2 hours', value: 120 },
  { title: '3 hours', value: 180 },
  { title: '4 hours', value: 240 },
];

const durationValues = {
  tenDays: 10,
  thirtyDays: 30,
  sixtyDays: 60,
  ninetyDays: 90,
  customDaysValue: 'custom',
};

const dateRangeOneToOne = [
  { title: '10 days out', value: durationValues.tenDays },
  { title: '30 days out', value: durationValues.thirtyDays },
  { title: '60 days out', value: durationValues.sixtyDays },
  { title: '90 days out', value: durationValues.ninetyDays },
  { title: 'Custom', value: durationValues.customDaysValue },
];

function Session({
  form,
  index,
  timesCount,
  oneToOne,
  onDelete,
  setStartDate,
  setEndDate,
  oneToOneStartDate,
  oneToOneEndDate,
  setOneToDateRange,
  oneToDateRange,
  sessionDuration,
  setSessionDuration,
  otherEvents,
  alreadyBooked,
  selectedWeeks,
  sessionDurationError,
  getEditContributionTimes,
  editContributionTimes,
  setSlots,
  oneToOneData,
  calculatedSlots,
  calculateSlots,
  session,
  providerName,
  isPrerecorded,
  setFieldValue,
  values,
  enrollment,
  dragging,
  blockedDates,
  setBlockedDates,
  startTimeIncrementDuration,
  startTimeIncrementSlots,
  newSlots,
  isLastIndex,
  isSessionOpen,
  openSessionIndexes,
  setOpenSessionIndexes,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false);
  // const { bufferTimeBefore, bufferTimeAfter } = values;
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const { uploadVideo, videoDuration, videoSrc, loading, progress, error } = useVideoDurationParse();
  const { getPreRecordedVideoLink, link } = useVideoPlayer({});
  const [indexSelected, setIndexSelected] = useState(null);
  const [isAutoCompleteOn, setIsAutoCompleteOn] = useState(session?.isAutoCompleteOn);
  const [newBlockedDates, setNewBlockedDates] = useState();
  const calenderRef = useRef();
  const firstSessionTime = session?.sessionTimes[0];
  // const dispatch = useDispatch();
  const computeDifference = useCallback((startTime, endTime) => {
    if (!startTime || !endTime) {
      return null;
    }
    const start = moment(startTime);
    const end = moment(endTime);

    const duration = moment.duration(end.diff(start));
    let minutes = Math.round(duration.asMinutes() % 60);
    let hours = Math.floor(duration.asMinutes() / 60);

    if (hours < 0) {
      hours = 24 + hours;
    }
    if (minutes < 0) {
      minutes = 60 + minutes;
    }

    return `${hours}h ${minutes}m`;
  }, []);

  const contribution = useContribution();
  // commented for api call
  useEffect(() => {
    if (!link && isPrerecorded) {
      getPreRecordedVideoLink({ contributionId: contribution.id, sessionId: session.id });
    }
  }, [session]);

  useEffect(() => {
    setNewBlockedDates(
      blockedDates?.map(date => {
        return new Date(date);
      }),
    );
  }, [blockedDates]);

  useEffect(() => {
    if (oneToOne) {
      const data = {
        startDay: moment(oneToOneStartDate).utcOffset(0, true).startOf('day').toISOString(),
        endDay: moment(oneToOneEndDate).utcOffset(0, true).startOf('day').toISOString(),
        duration: `${oneToDateRange}`,
        sessionDuration,
        selectedWeeks,
        blockedDates,
        bufferTimeBefore: values?.bufferTimeBefore,
        bufferTimeAfter: values?.bufferTimeAfter,
      };
      if (contribution.id) {
        // getEditContributionTimes(contribution.id, data, true);
        getEditContributionTimes(
          contribution.id,
          data,
          {
            ...(!isUndefined(startTimeIncrementDuration) &&
              isInteger(startTimeIncrementDuration) && { offset: startTimeIncrementDuration }),
          },
          contribution?.durations?.[0],
        );
      } else {
        calculateSlots(data);
      }
    }
  }, [
    oneToOneStartDate,
    values?.bufferTimeBefore,
    values?.bufferTimeAfter,
    oneToOneEndDate,
    oneToDateRange,
    sessionDuration,
    selectedWeeks,
    startTimeIncrementDuration,
    contribution,
    blockedDates,
    oneToOne,
    newSlots,
  ]);

  useEffect(() => {
    const { availabilityTimes } = contribution;
    const calculatedTimes = contribution.id ? editContributionTimes : calculatedSlots;
    const { slots } = generateOneToOneSlots(oneToOneData, availabilityTimes || [], otherEvents, calculatedTimes);
    setSlots(slots);
  }, [editContributionTimes, setSlots, calculatedSlots]);

  useEffect(() => {
    if (oneToDateRange === 'custom') {
      return;
    }
    setStartDate(moment(new Date()));
    setEndDate(moment(new Date()).add(oneToDateRange, 'days'));
  }, [oneToDateRange]);

  useEffect(() => {
    const diff = moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days');
    if (
      !(
        diff === durationValues.tenDays ||
        diff === durationValues.thirtyDays ||
        diff === durationValues.sixtyDays ||
        diff === durationValues.ninetyDays
      )
    ) {
      setOneToDateRange(durationValues.customDaysValue);
    }
  }, [oneToOneStartDate, oneToOneEndDate]);

  const onChangeSessionDuration = e => {
    const { value } = e.target;

    setSessionDuration(value);
  };

  const handleSendVideo = useCallback(files => {
    uploadVideo(files, form, contribution, index);
  }, []);
  useCheckSessionDuration();

  const handleSubmit = () => {
    onDelete(indexSelected, false);
    setDeleteModal(false);
  };

  const IconComponent = isSessionOpen ? UpIcon : DownIcon;
  const IconClickHandler = () => {
    if (isSessionOpen) {
      setOpenSessionIndexes(openSessionIndexes.filter(idx => idx !== index));
    } else {
      setOpenSessionIndexes([...openSessionIndexes, index]);
    }
  };

  return oneToOne ? (
    <></>
  ) : (
    <StyledContainer
      id={session?.id}
      mobileView={mobileView}
      isSessionOpen={isSessionOpen}
      isPrerecorded={isPrerecorded}
    >
      <StyledBody>
        {isPrerecorded && (
          <Grid container>
            {/* Session title */}
            <Grid item md={12} xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {/* <StyledHeader>
                    <StyledTitle>
                      <StyledBadge>{index + 1}</StyledBadge>&nbsp;{' '}
                      <Input style={{ flexGrow: 1, verticalAlign: 'middle' }} name={`sessions[${index}].name`} />
                    </StyledTitle>
                    {session.isCompleted ? (
                      <p className="course-session-summary__status mr-3">Completed</p>
                    ) : (
                      <StyledIcon path={mdiDelete} size={1} onClick={() => onDelete(index, false)} />
                    )}
                    <StyledTitle>Live Session</StyledTitle>
                  </StyledHeader> */}

                  <HeaderWrapper>
                    <StyledHeader isLive={false}>
                      <StyledTitle isLive={true} isCompleted={session?.isCompleted}>
                        <StyledBadge>{index + 1}</StyledBadge>&nbsp;{' '}
                        <Input
                          style={{
                            flexGrow: 1,
                            verticalAlign: 'middle',
                            width: mobileView ? '100%' : session?.isCompleted ? '85%' : '88%',
                          }}
                          name={`sessions[${index}].name`}
                          inputClasses={{
                            root: classes.input_root,
                          }}
                        />
                      </StyledTitle>
                      <StyledActionsWrapper>
                        {/* {session.isCompleted ? ( */}
                        {session.isCompleted && <p className="course-session-summary__status mr-3">Completed</p>}
                        {/* ) : ( */}
                        <StyledType mobileView={mobileView}>Self-Paced Content</StyledType>
                        <StyledIcon path={mdiDelete} size={1} onClick={() => onDelete(index, true)} />
                        {/* )} */}
                      </StyledActionsWrapper>
                    </StyledHeader>
                    <Grid
                      item
                      lg={1}
                      md={1}
                      xs={6}
                      style={
                        isPrerecorded
                          ? { display: 'flex', justifyContent: 'end' }
                          : { display: 'flex', justifyContent: 'end', alignItems: 'center' }
                      }
                    >
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <IconComponent onClick={IconClickHandler} />
                        <DragIndicatorIcon sx={{ color: 'rgba(0,0,0, 0.15)' }} />
                      </div>
                    </Grid>
                  </HeaderWrapper>

                  {!isSessionOpen && (
                    <StyledSubHeading isPrerecorded={isPrerecorded}>
                      {session.isPrerecorded &&
                      !session?.sessionTimes[0]?.videoSource &&
                      !session?.sessionTimes[0]?.prerecordedSession &&
                      !session?.sessionTimes[0]?.embeddedVideoUrl &&
                      (session?.sessionTimes[0]?.selfPacedContentType?.includes('Video') ||
                        session?.sessionTimes[0]?.selfPacedContentType === undefined ||
                        session?.sessionTimes[0]?.selfPacedContentType === null ||
                        session?.sessionTimes[0]?.selfPacedContentType?.length === 0)
                        ? 'No content added'
                        : session?.sessionTimes.length > 0 &&
                          (session?.sessionTimes[0]?.selfPacedContentType?.includes('Video') ||
                            session?.sessionTimes[0]?.selfPacedContentType === undefined ||
                            session?.sessionTimes[0]?.selfPacedContentType === null ||
                            session?.sessionTimes[0]?.selfPacedContentType?.length === 0)
                        ? `Module includes ${session?.sessionTimes.length} ${
                            session?.sessionTimes.length === 1 ? 'piece' : 'pieces'
                          } of content`
                        : session?.sessionTimes[0]?.selfPacedContentType?.includes('Text') &&
                          (session?.sessionTimes[0]?.selfPacedContentAsHtml?.length === 0 ||
                            session?.sessionTimes[0]?.selfPacedContentAsHtml === undefined)
                        ? 'No content added'
                        : `Module includes ${session?.sessionTimes.length} ${
                            session?.sessionTimes.length === 1 ? 'piece' : 'pieces'
                          } of content`}
                    </StyledSubHeading>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
              item
              lg={1}
              md={1}
              xs={6}
              style={
                isPrerecorded
                  ? { display: 'flex', justifyContent: 'end', marginTop: '20px' }
                  : { display: 'flex', justifyContent: 'end', alignItems: 'center' }
              }
            >
              <div style={{ display: 'flex', gap: '5px' }}>
                <IconComponent onClick={IconClickHandler} />
                <DragIndicatorIcon sx={{ color: 'rgba(0,0,0, 0.15)' }} />
              </div>
            </Grid> */}
            {/* Session time container */}
            <Grid direction="row" container>
              <Grid xs={12}>
                <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
                  {({ push, remove, form }) => {
                    return (
                      <StyledSessionTimesContainer mobileView={mobileView}>
                        <SessionTime
                          dragging={dragging}
                          form={form}
                          push={push}
                          remove={remove}
                          isPrerecorded={isPrerecorded}
                          enrollment={enrollment}
                          videoDuration={videoDuration}
                          sessionIdx={index}
                          timesCount={timesCount}
                          computeDifference={computeDifference}
                          isSessionOpen={isSessionOpen}
                        />
                      </StyledSessionTimesContainer>
                    );
                  }}
                </FieldArray>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={4}>
          {/* {oneToOne && !isPrerecorded && (
            <Grid item md={6} xs={12}>
              <Select
                label="How long are your sessions?"
                onChange={onChangeSessionDuration}
                value={sessionDuration}
                fullWidth
                items={durationsOneToOne.map(({ title, value }) => ({
                  title,
                  value,
                }))}
                disabled={alreadyBooked}
                error={sessionDurationError}
                errorHeight={50}
              />
            </Grid>
          )} */}

          {!oneToOne && !isPrerecorded && (
            <>
              <Grid item md={12} xs={12}>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    style={
                      !isPrerecorded && !isSessionOpen
                        ? {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '16px 8px 0px 16px',
                          }
                        : { width: '100%', display: 'flex', justifyContent: 'space-between' }
                    }
                  >
                    {!oneToOne && (
                      <>
                        <div style={{ width: '92%' }}>
                          <StyledHeader isLive={true}>
                            <StyledTitle isLive={true} isCompleted={session?.isCompleted}>
                              <StyledBadge>{index + 1}</StyledBadge>&nbsp;{' '}
                              <Input
                                style={{
                                  flexGrow: 1,
                                  verticalAlign: 'middle',
                                  width: mobileView ? '100%' : session?.isCompleted ? '85%' : '88%',
                                }}
                                name={`sessions[${index}].name`}
                                title={session?.name}
                                inputClasses={{
                                  root: classes.input_root,
                                }}
                              />
                            </StyledTitle>
                            <StyledActionsWrapper>
                              {/* {session.isCompleted ? ( */}
                              {session.isCompleted && <p className="course-session-summary__status mr-3">Completed</p>}
                              {/* ) : ( */}
                              <StyledType mobileView={mobileView}>Live Session</StyledType>
                              <div>
                                <StyledIcon
                                  path={mdiDelete}
                                  size={1}
                                  onClick={() => {
                                    setIndexSelected(index);
                                    setDeleteModal(true);
                                  }}
                                  isLive={!mobileView}
                                />
                              </div>
                              {/* )} */}
                            </StyledActionsWrapper>
                          </StyledHeader>
                        </div>
                        <div style={{ width: '8%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          <IconComponent onClick={IconClickHandler} />
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!isSessionOpen && (
                <StyledSubHeading>
                  Session on {moment(firstSessionTime?.startTime).format('MMMM Do')}
                  {getSuffix(moment(firstSessionTime?.startTime).date())},{' '}
                  {moment(firstSessionTime?.startTime).format('h:mm a')} -{' '}
                  {moment(firstSessionTime?.endTime).format('h:mm a')}{' '}
                  {session?.sessionTimes.length > 2
                    ? `&  ${session?.sessionTimes.length - 1} additional session times`
                    : session?.sessionTimes.length > 1
                    ? `&  ${session?.sessionTimes.length - 1} additional session time`
                    : ''}
                </StyledSubHeading>
              )}
              {isSessionOpen && isPrerecorded && (
                <Grid item md={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {!error && (link || videoSrc) && !loading && (link || progress === 100 || !progress) ? (
                        <StyledVideo controls>
                          <source src={link ?? videoSrc} />
                        </StyledVideo>
                      ) : (
                        <Dropzone
                          action={handleSendVideo}
                          type="video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime,video/*"
                          icon={<VideoCallIcon fontSize="inherit" />}
                          progress={progress}
                          loading={loading}
                          placeholder="Drag and drop your video, audio, or course content here or click on this box to select a file to upload. Max file size is 3GB"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>

        {oneToOne ? (
          <>
            {/* <PageSubtitle style={{ marginTop: '0.5rem' }}>Set Your Booking Availability</PageSubtitle> */}
            {/* <Grid
              container
              direction={smView ? 'column' : 'row'}
              justify="space-between"
              style={{ marginBottom: '20px' }}
            >
              <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6}>
                <Grid item md={10} xs={12}>
                  <Select
                    style={{ fontFamily: 'Brandon Text' }}
                    label={
                      <div style={{ fontFamily: 'Brandon Text' }}>
                        Date range: How far out can client book?
                        <Tooltip
                          title="This allows your calendar availability to always have the next indicated number of days available for client booking. You can also select custom dates."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                    labelTop={mobileView ? '32px' : '24px'}
                    name={`sessions[${index}].daterange`}
                    value={oneToDateRange}
                    onChange={e => {
                      setOneToDateRange(e.target.value);
                    }}
                    fullWidth
                    items={dateRangeOneToOne.map(({ title, value }) => ({
                      title,
                      value,
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6} container direction="row" justify="space-around">
                <Grid item sm="5" xs="6">
                  <DateTimePicker
                    disabled={oneToDateRange != 'custom'}
                    label={<div style={{ fontFamily: 'Brandon Text' }}>Start Day</div>}
                    type="date"
                    fullWidth
                    name={`sessions[${index}].startDate`}
                    value={oneToOneStartDate}
                    onChange={momentDate => {
                      setStartDate(momentDate);
                    }}
                    format="MMMM Do"
                  />
                </Grid>
                <Grid item sm="5" xs="6">
                  <DateTimePicker
                    disabled={oneToDateRange != 'custom'}
                    label={<div style={{ fontFamily: 'Brandon Text' }}>End Day</div>}
                    type="date"
                    fullWidth
                    name={`sessions[${index}].endDate`}
                    value={oneToOneEndDate}
                    onChange={momentDate => {
                      setEndDate(momentDate);
                    }}
                    format="MMMM Do"
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
              {({ push, remove, form }) => {
                return (
                  <></>
                  // <StyledSessionTimesContainer mobileView={mobileView}>
                  //   <SessionTimeOneToOne
                  //     form={form}
                  //     push={push}
                  //     remove={remove}
                  //     isOneToOne={oneToOne}
                  //     sessionIdx={index}
                  //     timesCount={timesCount}
                  //     bufferTimeBefore={values?.bufferTimeBefore}
                  //     bufferTimeAfter={values?.bufferTimeAfter}
                  //     oneToOneStartDate={oneToOneStartDate}
                  //     oneToOneEndDate={oneToOneEndDate}
                  //     oneToDateRange={oneToDateRange}
                  //     otherEvents={otherEvents}
                  //   />
                  // </StyledSessionTimesContainer>
                );
              }}
            </FieldArray>
            {/* <div style={{ position: 'relative', marginBottom: '20px', marginTop: '20px' }}>
              <DatePicker
                ref={calenderRef}
                value={newBlockedDates}
                onChange={val => setNewBlockedDates(val.map(date => new Date(date.unix * 1000)))}
                multiple
                minDate={moment(oneToOneStartDate).toDate()}
                maxDate={moment(oneToOneEndDate).toDate()}
                sort
                highlightToday={false}
                onClose={() => {
                  setBlockedDates(newBlockedDates.map(date => date.toISOString()));
                }}
                plugins={[
                  <DatePanel
                    header="Blocked Days"
                    formatFunction={({ format }) => {
                      return moment(format).format(DATE_FORMATS.DAY);
                    }}
                  />,
                ]}
              />
              <StyledLink
                style={{ position: 'absolute', left: 0, top: '0', backgroundColor: 'white', width: '247px' }}
                onClick={e => {
                  e.preventDefault();
                  calenderRef.current.openCalendar();
                }}
              >
                <Icon path={mdiPlus} size={1} />
                Block Single Days ({blockedDates?.length || 0})
              </StyledLink>
            </div> */}
          </>
        ) : (
          <>
            {!isPrerecorded && isSessionOpen && (
              <>
                <StyledHeading style={{ marginTop: '15px' }}>Sessions</StyledHeading>
                <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
                  {({ push, remove, form }) => {
                    return (
                      <StyledSessionTimesContainer mobileView={mobileView}>
                        <SessionTime
                          form={form}
                          push={push}
                          remove={remove}
                          sessionIdx={index}
                          timesCount={timesCount}
                          computeDifference={computeDifference}
                          isSessionOpen={isSessionOpen}
                        />
                      </StyledSessionTimesContainer>
                    );
                  }}
                </FieldArray>
              </>
            )}
          </>
        )}
        {isSessionOpen && !isPrerecorded && (
          <Grid style={{ marginTop: '15px', marginBottom: '10px' }} container md={12} xs={12} spacing={1}>
            <Grid item lg={6} xs={12}>
              <Grid item md={12} xs={12}>
                <StyledHeading style={{ margin: '0px' }}>Settings</StyledHeading>
              </Grid>
              <Grid container display="flex" item md={12} xs={12} spacing={2}>
                <Grid item md={6} xs={12}>
                  <Input
                    fullWidth
                    type="number"
                    name={`sessions[${index}].minParticipantsNumber`}
                    label="Min Number of Participants"
                    sessionInput={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    error={session?.maxParticipantsNumber > 50 && providerName === 'Cohere'}
                    helperText={
                      session?.maxParticipantsNumber > 50 &&
                      providerName === 'Cohere' &&
                      `Cohere’s live video service currently supports up to 50 participants. If you expect to have more than 50, please change the live video service provider using the “Live Video” settings below. For example, use a private Zoom room link, or equivalent.`
                    }
                    fullWidth
                    type="number"
                    name={`sessions[${index}].maxParticipantsNumber`}
                    label="Max Number of Participants"
                    sessionInput={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item lg={6} xs={12}>
              <StyledHeading style={{ margin: '0px' }}>Quiz/Form:</StyledHeading>
              <StyledAddQuizFormBtn
                autoWidth
                onClick={() => {
                  dispatch(setShowCreateModal(true));
                }}
              >
                Add Quiz/Form
              </StyledAddQuizFormBtn>
            </Grid> */}
          </Grid>
        )}
      </StyledBody>
      <Modal
        title="Delete Session"
        isOpen={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onSubmit={handleSubmit}
        submitTitle="Yes"
        widthRequiredIs
        isCreatingContribution={true}
        brandingColor={false}
      >
        Are you sure you want to Delete this session?
      </Modal>
    </StyledContainer>
  );
}

Session.propTypes = {
  index: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  oneToOne: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  alreadyBooked: PropTypes.bool,
  selectedWeeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sessionDurationError: PropTypes.string,
};

Session.defaultProps = {
  oneToOne: false,
  alreadyBooked: false,
  sessionDurationError: undefined,
  isPrerecorded: false,
};

const mapStateToProps = ({
  oneToOneData,
  contributions: { updating, loading, editContributionTimes, calculatedSlots },
}) => ({
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  selectedWeeks: oneToOneData?.selectedWeeks,
  oneToDateRange: oneToOneData?.duration,
  sessionDuration: oneToOneData?.sessionDuration,
  blockedDates: oneToOneData?.blockedDates,
  startTimeIncrementDuration: oneToOneData?.startTimeIncrementDuration,
  startTimeIncrementSlots: oneToOneData?.startTimeIncrementSlots,
  sessionDurationError: oneToOneData?.sessionDurationError,
  oneToOneData,
  updating,
  loading,
  editContributionTimes,
  calculatedSlots,
});

const actions = {
  setStartDate: oneToOneDataActions.setStartDate,
  setBlockedDates: oneToOneDataActions.setBlockedDates,
  setEndDate: oneToOneDataActions.setEndDate,
  setOneToDateRange: oneToOneDataActions.setDuration,
  setSessionDuration: oneToOneDataActions.setSessionDuration,
  getEditContributionTimes: contributionActions.fetchEditContributionTimes,

  setSlots: oneToOneDataActions.setSlots,
  calculateSlots: contributionActions.calculateCreateSlots,
};

export default connect(mapStateToProps, actions)(Session);
