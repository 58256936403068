import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useHttp } from 'hooks';
import Loader from 'components/UI/Loader';
import NotesModal from 'components/Notes/NotesModal';
import useSessionNotes from 'hooks/useSessionNotes';
import { setCloseDisabled, setNotesStatus, setShowSavingAnimation } from 'actions/contributions';
import useSaveOnTypping from './hooks/useSaveOnTypping';

const NoteModalContainer = ({
  noteProps: { title, classId, subClassId, contributionId, isPrerecorded },
  onModalClose,
  onConfirm,
}) => {
  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    notesMeta,
    isNoteExist,
  } = useSessionNotes({ contributionId, classId, subClassId, isPrerecorded });
  const { saveNotesOnTyping } = useSaveOnTypping();
  const { request } = useHttp();
  const dispatch = useDispatch();
  const closeDisabled = useSelector(state => state?.contributions?.closeDisabled);
  const handleSubmit = useCallback(() => {
    setLoadingNotes(true);
    if (isPrerecorded) {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          });

      noteRequest
        .then(onConfirm)
        .catch(error => {
          onModalClose();
        })
        .finally(() => {
          setLoadingNotes(false);
          setShowNotes(false);
          dispatch(setNotesStatus(''));
          dispatch(setCloseDisabled(false));
        });
    } else {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
          });

      noteRequest
        .then(onConfirm)
        .catch(error => {
          onModalClose();
        })
        .finally(() => {
          setLoadingNotes(false);
          setShowNotes(false);
          dispatch(setNotesStatus(''));
          dispatch(setCloseDisabled(false));
        });
    }
  }, [request, contributionId, classId, title, onConfirm, notesMeta, editorDataUpdated]);

  useEffect(() => {
    if (closeDisabled) {
      saveNotesOnTyping({ isPrerecorded, notesMeta, title, editorDataUpdated, contributionId, classId, subClassId });
    }
  }, [editorDataUpdated]);
  return (
    <>
      {loadingNotes && <Loader />}
      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={() => {}}
        setLoading={setLoadingNotes}
        onModalClose={onModalClose}
        applyTheming
        onSave={() => {
          handleSubmit();
        }}
        isNoteExist={isNoteExist}
      />
    </>
  );
};

NoteModalContainer.propTypes = {
  noteProps: PropTypes.shape({
    title: PropTypes.string,
    classId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    subClassId: PropTypes.string.isRequired,
    isPrerecorded: PropTypes.bool.isRequired,
  }).isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
const mapStateToProps = ({ contributions }) => ({
  contribution: contributions,
});

export default connect(mapStateToProps)(NoteModalContainer);
