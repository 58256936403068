import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  useAccount,
  useChatClientChannel,
  ChatClientChannelConnectionStatus,
  useChatClientChannelAttributes,
  useChatClientChannelUtilities,
  useChatClientChannelMembers,
  useChatClientChannelMessages,
} from 'hooks';
import { LabelText } from 'components/UI/Text/TextStyles';
import Chat from './Chat';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import './Chat.scss';
import { UserRoles, ContributionType } from 'helpers/constants';
import { getThemedColors } from 'services/contributions.service';

const ChatContainer = ({ compact, channelId, videoCallChat, ...restProps }) => {
  const [chatReload, setChatReload] = useState(false);
  const { connectionStatus, channel } = useChatClientChannel(channelId, chatReload);
  const { members, myMember, typingMembers, getMemberBySid } = useChatClientChannelMembers(channel);
  const { messages, hasMoreMessages, isLoadingMoreMessages, loadMoreMessages } = useChatClientChannelMessages(channel);
  const { title, iconUrl } = useChatClientChannelAttributes(channel, members, myMember);
  const { sendTextMessage, sendMediaMessage, readMessage, onTyping } = useChatClientChannelUtilities(channel);
  const contribution = useContribution();
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  // const membersReady = !!myMember;
  const chatReady = connectionStatus === ChatClientChannelConnectionStatus.connected;
  // && membersReady;
  const { themedColor } = getThemedColors(contribution);

  const onLoadMoreMessages = () => {
    if (hasMoreMessages && !isLoadingMoreMessages) {
      loadMoreMessages();
    }
  };
  const reload = () => window.location.reload();
  return (
    <div className={compact ? 'chat-container-video' : 'chat-container'}>
      {(connectionStatus === ChatClientChannelConnectionStatus.pending ||
        connectionStatus === ChatClientChannelConnectionStatus.connecting) && <CircularProgress />}
      {connectionStatus === ChatClientChannelConnectionStatus.connectionError && (
        <LabelText style={{ color: themedColor, display: 'flex' }}>
          {videoCallChat ? (
            `Could not connect to chat.`
          ) : (
            <div
              onClick={reload}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontFamily: 'Avenir',
                fontStyle: 'normal',
                fontWeight: '500',
                marginLeft: '5px',
                color: '#215c73',
              }}
            >
              Click here to reload chat
            </div>
          )}
        </LabelText>
      )}
      {chatReady && (
        <Chat
          {...restProps}
          title={title}
          iconUrl={iconUrl}
          members={members}
          myMember={myMember}
          typingMembers={typingMembers}
          memberProvider={getMemberBySid}
          messages={messages}
          isLoadingMoreMessages={isLoadingMoreMessages}
          onLoadMoreMessages={onLoadMoreMessages}
          onReadMessage={readMessage}
          sendTextMessage={sendTextMessage}
          sendMediaMessage={sendMediaMessage}
          onTyping={onTyping}
          contribution={contribution}
          isCoach={isCoach}
          channel={channel}
        />
      )}
    </div>
  );
};

ChatContainer.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChatContainer;
