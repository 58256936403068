import React from 'react';

const SavedIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_96)">
        <rect width="14" height="14" fill="white" fill-opacity="0.01" />
        <g clip-path="url(#clip1_2_96)">
          <path
            d="M9.92183 4.51526H9.18902C9.02964 4.51526 8.87808 4.59182 8.78433 4.72307L6.32808 8.12932L5.21558 6.58557C5.12183 6.45588 4.97183 6.37776 4.81089 6.37776H4.07808C3.97652 6.37776 3.91714 6.49338 3.97652 6.5762L5.92339 9.2762C5.96938 9.34039 6.03001 9.39269 6.10026 9.42878C6.1705 9.46486 6.24833 9.48369 6.3273 9.48369C6.40627 9.48369 6.4841 9.46486 6.55434 9.42878C6.62458 9.39269 6.68521 9.34039 6.7312 9.2762L10.0218 4.7137C10.0828 4.63088 10.0234 4.51526 9.92183 4.51526Z"
            fill="#4A4A4A"
          />
          <path
            d="M7 0.00012207C3.13437 0.00012207 0 3.1345 0 7.00012C0 10.8657 3.13437 14.0001 7 14.0001C10.8656 14.0001 14 10.8657 14 7.00012C14 3.1345 10.8656 0.00012207 7 0.00012207ZM7 12.8126C3.79063 12.8126 1.1875 10.2095 1.1875 7.00012C1.1875 3.79075 3.79063 1.18762 7 1.18762C10.2094 1.18762 12.8125 3.79075 12.8125 7.00012C12.8125 10.2095 10.2094 12.8126 7 12.8126Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_96">
          <rect width="14" height="14" fill="white" />
        </clipPath>
        <clipPath id="clip1_2_96">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SavedIcon;
