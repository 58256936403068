import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import * as userActions from 'actions/user';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import { updateUser } from 'actions/update-user';
import * as contributionActions from 'actions/contributions';
import * as pageActions from 'actions/page';
import { toRem } from 'utils/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { position } from 'polished';
import styled from 'styled-components';
import Logo from 'components/FormUI/Logo';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Modal from 'components/UI/Modal';
import Avatar from '@material-ui/core/Avatar';
import { ContributionType, SIGNUP_TYPES, UserRoles, ContributionThemedColors } from 'helpers/constants';
import usePaidTier from 'hooks/usePaidTier';
import useAccount from 'hooks/useAccount';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import * as R from 'ramda';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'utils/styles';
import ProgressBar from '@ramonak/react-progress-bar';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CoachImage from 'assets/chatlogo.png';
import ListItem from './ListItem';
import { Steps } from 'helpers/constants/steps';
import { ListItemTypes } from 'helpers/constants/listItemTypes';
import { useHistory } from 'react-router-dom';
import {
  getContribution,
  getContributions,
  getLatestApproved,
  getUpcomingCreated,
} from 'services/contributions.service';
import { useDispatch } from 'react-redux';
import { UpdateUserProgressBarData } from 'services/user.service';
import * as paidTier from 'selectors/paidTier';
import { PAID_TIER_TITLES } from '../../../../../constants';
import { setCohereAcademyStatus } from 'actions/update-user';
import howToVideo from '../../../../../assets/HowToVideo.svg';

const StyledLogoAndBtn = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
`;

const WelcomeMesssage = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
`;

const StyledCohere = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #106482;
`;

const WalkthroughsMessage = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 6px;
`;

const StyledListing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const MinimizeBtn = styled.div`
  postion: absolute;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  ${({ last }) =>
    last &&
    `
  margin-bottom: 0px;
  `}
`;
export const StyledDropDownItem = styled(Link)`
  font-size: ${toRem(16)};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  min-width: 100px;
  display: block;
  text-align: end;
  margin-top: -8px;

  &.disableMe {
    opacity: 1;
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
    text-align: end;
  }

  &:hover {
    text-decoration: underline;
  }
`;
const StyledDescription = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-left: 5px;
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
`;

const AvatarComponent = styled(Avatar)`
  width: 50px;
  height: 50px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 40px;
        height: 40px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const GreetingSection = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  width: 100%
  height: 32px;
  color: ${colors?.lightBrown};
  margin-top: 5px;
  margin-bottom: 1px;
  // font-size: 16px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  color: gray;
  cursor: pointer;
`;
const StyledImage = styled.img`
  width: 100%;
  cursor: pointer;
`;
const VideoWrapper = styled.div`
  padding: 0px 0px 50px 0px;
`;
const StyledVideo = styled.video`
  width: 100%;
  border-radius: 8px;
`;

const bull = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
);

const getInitialSymbol = R.compose(R.toUpper, R.head);

const TypeOfStep = (currentStep, prevStep, data, isFirstStep) => {
  if (data && data[currentStep]) {
    return ListItemTypes.completed;
  } else if (data && !data[currentStep] && (isFirstStep || data[prevStep])) {
    return ListItemTypes.nextToBeDone;
  } else {
    return ListItemTypes.uncompleted;
  }
};

export default function GetStartedCard(props) {
  const { hideCardFunc } = props;
  const { user } = useAccount();
  const dispatch = useDispatch();
  const userData = user?.userProgressbarData;
  const [contibId, setContibId] = useState(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const getstarted = 'getstarted';

  const { currentRole, user: signupType, loggedInUser } = useAccount();
  // const { isLaunchPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  // Correct behavior - 100%
  // useEffect(() => {
  //   dispatch(contributionActions.setPopUp(false));
  // }, [])

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;
  const disable =
    (shouldBeDisabled && currentRole === UserRoles.cohealer) ||
    (isLaunchPlan && currentRole === UserRoles.cohealer && user.isPartnerCoach === false);
  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    userActions.getProfile(user.id, dispatch);
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, [contibId]);

  const updateUserF = async item => {
    let obj;

    obj = {
      id: user.id,
      userProgressbarData: {
        IsPlanPurchased: true,
        IsProfileUploaded: true,
        IntegrationsDone: true,
        FirstContributionDone: true,
        InviteLink: true,
        IsWebsiteAdded: true,
        IsBankAccountConnected: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      togglePopup();
    });
  };

  const toggleVideoPopup = () => {
    setShowVideoPopup(!showVideoPopup);
  };
  return (
    <>
      <Card
        sx={{
          minWidth: mobileView ? '350px' : '345px',
          maxWidth: mobileView ? '350px' : '345px',
          position: 'fixed',
          bottom: 105,
          right: !mobileView ? 60 : 45,
          cursor: 'default',
          // boxShadow: '0 0px 5px 0 rgba(157, 157, 157, 0.3)',
          boxShadow: '0px 2px 32px 0px #00000029 !important',
        }}
      >
        <CardContent sx={{ padding: '5px 10px 5px 10px !important' }}>
          <StyledLogoAndBtn>
            <div>
              <Logo startlogo />
            </div>
            <StyledCohere>COHERE</StyledCohere>
            <IconWrapper onClick={hideCardFunc}>
              <MinimizeIcon style={{ color: 'gray' }} />
            </IconWrapper>
          </StyledLogoAndBtn>
          {/* <div style={{ flexDirection: 'row', display: 'flex', marginBottom: '10px' }}>
            <AvatarComponent alt={`${user?.firstName} ${user?.lastName}`} src={CoachImage} mobileView={mobileView}>
              {`${user?.firstName && getInitialSymbol(user?.firstName)}${
                user?.lastName && getInitialSymbol(user?.lastName)
              }`}
            </AvatarComponent>
            <Typography style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
              Anette Oran <br />
              Co-founder
            </Typography>
          </div> */}
          <StyledImage src={howToVideo} alt="how-To-VideoImg" onClick={toggleVideoPopup} />
          <GreetingSection>
            Hi {user?.firstName?.charAt(0).toUpperCase() + user?.firstName?.slice(1)} 👋
          </GreetingSection>
          <WelcomeMesssage>Welcome to Cohere</WelcomeMesssage>
          <WalkthroughsMessage>Here's a step-by-step guide to help you get started!</WalkthroughsMessage>
          <ProgressBar completed={user?.progressBarPercentage} bgColor={colors?.lightBrown} labelAlignment="left" />
          <StyledListing>
            <ListItem
              type={TypeOfStep(Steps.IsPlanPurchased, Steps.IsPlanPurchased, userData, true)}
              description="Verify Your Information"
              pointNum="1."
              onClick={() => {
                if (disable === false && user.userProgressbarData.IsPlanPurchased === false) {
                  history.push('/account/payment', { from: 'verify' });
                } else if (disable === false && user.userProgressbarData.IsPlanPurchased === true) {
                  dispatch(contributionActions.setShowCompletedGetStart('verifyRefresh'));
                  history.push('/account/payment', { from: 'verifyRefresh' });
                }
              }}
            />
            <ListItem
              type={TypeOfStep(Steps.IsBankAccountConnected, Steps.IsPlanPurchased, userData, false)}
              description="Connect Your Bank"
              pointNum="2."
              onClick={() => {
                hideCardFunc();
                dispatch(contributionActions.setPopUpBank(true));
                if (disable === false && user.userProgressbarData.IntegrationsDone === false) {
                  history.push('/account/payment', { from: 'bankAccount' });
                } else if (disable === false && user.userProgressbarData.IntegrationsDone === true) {
                  dispatch(contributionActions.setShowCompletedGetStart('bankAccountRefresh'));
                  history.push('/account/payment', { from: 'bankAccountRefresh' });
                }
              }}
            />
            <ListItem
              type={TypeOfStep(Steps.IntegrationsDone, Steps.IsBankAccountConnected, userData, false)}
              description="Add Integrations"
              pointNum="3."
              onClick={() => {
                if (disable === false && user.userProgressbarData.IntegrationsDone === false) {
                  history.push('/account/integrations', { from: 'bankAccount' });
                } else if (disable === false && user.userProgressbarData.IntegrationsDone === true) {
                  dispatch(contributionActions.setShowCompletedGetStart('bankAccountRefresh'));
                  history.push('/account/integrations', { from: 'bankAccountRefresh' });
                }
              }}
            />
            <ListItem
              type={TypeOfStep(Steps?.IsProfileUploaded, Steps.IntegrationsDone, userData, false)}
              description="Setup Your Profile"
              pointNum="4."
              onClick={() => {
                if (disable === false && user.userProgressbarData?.IsProfileUploaded === false) {
                  history.push('/account/profile', { from: 'profile' });
                } else if (disable === false && user.userProgressbarData.IsProfileUploaded === true) {
                  dispatch(contributionActions.setShowCompletedGetStart('profileRefresh'));
                  history.push('/account/profile', { from: 'profileRefresh' });
                }
              }}
            />
            <ListItem
              type={TypeOfStep(Steps.FirstContributionDone, Steps?.IsProfileUploaded, userData, false)}
              description="Launch Your First Service"
              pointNum="5."
              onClick={() => {
                hideCardFunc();
                dispatch(pageActions.toggleMobileSidebar());
                if (disable === false && user.userProgressbarData.FirstContributionDone === false) {
                  dispatch(contributionActions.setShowCompletedGetStart('create'));
                  dispatch(contributionActions.setPopUp(true));
                  history.push('/dashboard');
                } else if (disable === false && user.userProgressbarData.FirstContributionDone === true) {
                  dispatch(contributionActions.setPopUp(true));
                  dispatch(contributionActions.setShowCompletedGetStart('createRefresh'));
                  history.push('/dashboard');
                }
              }}
            />
            {/* <ListItem
              type={TypeOfStep(Steps.IsWebsiteAdded, Steps.InviteLink, userData, false)}
              description="6. (Optional) Create Your Services Website"
              onClick={() => {
                if (disable === false && user.userProgressbarData.IsWebsiteAdded === false) {
                  history.push('/account/profile', { from: 'Manage Your Website Information' });
                } else if (disable === false && user.userProgressbarData.IsWebsiteAdded === true) {
                  history.push('/account/profile', { from: 'refreshWebsite' });
                }
              }}
            /> */}
            <ListItem
              type={TypeOfStep(Steps.InviteLink, Steps.IsWebsiteAdded, userData, false)}
              description="Invite New Clients & Impact Lives!"
              pointNum="6."
              onClick={() => {
                if (disable === false && user.userProgressbarData.InviteLink === false) {
                  contibId === null ? console.log('Helloo') : history.push(`/contribution-view/${contibId}/about`);
                } else if (disable === false && user.userProgressbarData.InviteLink === true) {
                  dispatch(contributionActions.setShowCompletedGetStart('refreshInvite'));
                  contibId === null
                    ? console.log('Helloo')
                    : history.push(`/contribution-view/${contibId}/about`, { from: 'refreshInvite' });
                }
              }}
            />
          </StyledListing>
          <StyledDropDownItem
            className={user?.progressBarPercentage == '100' && 'disableMe'}
            onClick={() => {
              user?.progressBarPercentage == '100' ? console.log('first') : togglePopup();
            }}
          >
            Mark All Complete
          </StyledDropDownItem>
        </CardContent>
      </Card>
      <Modal
        title="Mark All Complete"
        isOpen={showModal}
        submitTitle="Yes"
        cancelTitle="No"
        onSubmit={() => {
          updateUserF();
        }}
        onCancel={togglePopup}
      >
        Are you sure you want all steps to complete and close getting started guide?
      </Modal>
      <Modal isOpen={showVideoPopup} onCancel={toggleVideoPopup} disableFooter={true}>
        <VideoWrapper>
          <StyledVideo controls="controls" preload="metadata">
            <source src={`https://coherepublic-prod.s3.amazonaws.com/marketing/cohere+dashboard+w+1to1.mp4`} />
          </StyledVideo>
        </VideoWrapper>
      </Modal>
    </>
  );
}
