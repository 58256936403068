import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { determineColorToUse } from 'services/contributions.service';

import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import { fetchClientContribution, fetchContributionActions, updateSubscriptionInfo } from 'actions/contributions';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import { ContributionType, PaymentStatus, UserRoles } from 'helpers/constants';
import { useAccount, useRouter, useHttp, useApplicationStatus } from 'hooks';
import * as accountPreferences from 'services/accountPreferences.service';
import * as userService from 'services/user.service';
import { roleChange } from 'actions/user';
import { joinContribution } from 'services/contributions.service';
import usePaidTier from 'hooks/usePaidTier';
import SmallLoader from 'components/UI/SmallLoader';
import { AgreeToTermsAndConditions } from 'components/Modals/AgreeToTermsAndConditions';
import PurchaseModal from './PurchaseModal';
import useContribution from '../hooks/useContribution';
import ProceedModal from './ProceedModal';
import PurchaseMembershipModal from './PurchaseMembershipModal';
import { MEMBERSHIP_STATUSES, ROUTES, JOIN_CONTRIBUTION_STATUS } from '../../../constants';
import { InfoModal } from '../../../components/Modals/InfoModal';
import { TemplateType } from 'helpers/constants/templateType';
import axiosInstance from 'utils/axiosInstance';
import { connect } from 'react-redux';
import { getIpGlobal, lightOrDark } from 'utils/utils';
import { redirectTo } from 'services/links';

const ButtonWrapper = styled.div`
  margin-left: 20px;

  ${({ mobileView }) =>
    mobileView &&
    `
      margin: 0 10px;
      // margin: 0 0px 10px 0px;
      width: 100%;
  `}
  ${({ textColor }) =>
    textColor &&
    `
      color: ${textColor};
  `}
  button {
    min-width: 128px;
    max-width: ${({ noMaxWidth }) => (noMaxWidth ? '' : '150px')};
    ${({ mobileView, joinBtn }) =>
      mobileView &&
      joinBtn &&
      `
      // min-width: 75px;
      // min-width : 150px;
    `}
    ${({ mobileView, joinBtnPadding }) =>
      mobileView &&
      joinBtnPadding &&
      `
      padding: 8px 15px;
    `}
    ${({ textColor }) =>
      textColor &&
      `
        color: ${textColor};
    `}
      ${({ mobileView }) =>
      mobileView &&
      `
      width: 100%;
      // min-width: 18rem;
      max-width: 350px;
    `}
      ${({ mobileView, joinBtn }) =>
      joinBtn &&
      mobileView &&
      `
      padding:  15px 8px;
      font-size: 12px;
    `}
      ${({ headerMobile }) =>
      headerMobile &&
      `
      min-width: 100px;
    `};

    ${({ disableJoinBtn }) =>
      disableJoinBtn &&
      `
     background-color: #bdbdbd;
     cursor: not-allowed;
     &:hover{
      background-color: #bdbdbd;
     }
     `}
  }

  ${({ headerMobile }) =>
    headerMobile &&
    `
        margin-left: 0px;
        margin: 0px;
    `}

  ${({ PaymentBlock, mobileView }) =>
    PaymentBlock &&
    mobileView &&
    `
     margin: 0px;
    `}

    ${({ backgroundColor }) =>
    backgroundColor &&
    `
        color: ${backgroundColor}
        background-color: ${backgroundColor};
    `}

    ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    `
    pointer-events: none;
  `}
`;

const TypeOfCourse = ({
  contribution,
  handleUrlModal,
  headerMobile,
  mobileView,
  activeContribution,
  PaymentBlock,
  clientPreviewMode,
}) => {
  let colorToUse = determineColorToUse(contribution);
  const dispatch = useDispatch();
  const purchasingDummy = useSelector(s => s.account.purchasingDummy);
  const activeTemplate = activeContribution?.activeTemplate || TemplateType.TemplateThree;
  const PrimaryColor = activeContribution.isCustomBrandingColorsActive
    ? activeContribution.brandingColors.PrimaryColorCode
    : '';
  const { academyContributions, activeAcademyContributions, updateContibutionViewState } = usePaidTier();

  const { location, push, query } = useRouter();

  const { id, type, purchaseStatus, isPurchased } = useContribution();
  const { user, currentRole } = useAccount();
  const {
    applicationRequiredButNotApproved,
    isApplicationNeedsToBeSubmitted,
    isApplicationResponsePending,
    isLoadingApplicationStatus,
  } = useApplicationStatus({ contribution, user });

  let contributionGroupOrMEmbershipButtonText = 'Join';
  let contributionOneToOneButtonText = 'Book Single Session';
  if (isApplicationNeedsToBeSubmitted) {
    contributionGroupOrMEmbershipButtonText = 'Apply';
    contributionOneToOneButtonText = 'Apply';
  }

  if (isApplicationResponsePending) {
    contributionGroupOrMEmbershipButtonText = 'Application Pending';
    contributionOneToOneButtonText = 'Application Pending';
  }
  const titles = {
    [ContributionType.contributionCourse]: {
      button: contributionGroupOrMEmbershipButtonText,
      confirm: 'Proceed Purchasing',
      proceedSubscription: 'Proceed Subscription Payment',
      join: 'Join Now',
    },
    [ContributionType.contributionOneToOne]: {
      button: contributionOneToOneButtonText,
    },
    [ContributionType.contributionMembership]: {
      button: contributionGroupOrMEmbershipButtonText,
      join: 'Join Now',
    },
    [ContributionType.contributionCommunity]: {
      button: contributionGroupOrMEmbershipButtonText,
      join: 'Join Now',
    },
    [ContributionType.contributionLocalEvent]: {
      button: '',
    },
  };

  const {
    paymentInfo: { paymentOptions },
  } = useContribution();

  const isCoach = currentRole === UserRoles.cohealer;

  const bgColor = colorToUse?.PrimaryColorCode;
  const disableJoinBtn = contribution?.enrollment?.anyTime === true ? false : contribution?.isEnrollmentClosed;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const shouldScroll =
    isCoach ||
    (!isCoach && activeTemplate === TemplateType.TemplateOne) ||
    (!isCoach && activeTemplate === TemplateType.TemplateTwo) ||
    (!isCoach && activeTemplate === TemplateType.TemplateThree) ||
    (!isCoach && type === ContributionType.contributionOneToOne);

  const [academyMembershipSubscribing, setAcademyMembershipSubscribing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const [purchaseMembershipModalOpen, setPurchaseMembershipModalOpen] = useState(false);
  const [infoModalType, setInfoModalType] = useState(null);
  const [isUpgrade, setIsUpgrade] = useState(false);
  // const [isInviteToJoin, setIsInviteToJoin] = useState(true);
  const [showTerms, setShowTerms] = useState(false);

  const isMembership = contribution.type === ContributionType.contributionMembership;

  const isCanPurchaseMembership = isMembership && !isCoach && !contribution.subscriptionStatus;
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const priceId = useSelector(state => state.contributions.priceCode);
  const code = isInviteToJoin || priceId;
  const isCanUpgradeMembership =
    isMembership &&
    !isCoach &&
    contribution?.subscriptionStatus &&
    contribution?.subscriptionStatus?.status !== MEMBERSHIP_STATUSES.UPGRADE &&
    contribution?.subscriptionStatus?.status === MEMBERSHIP_STATUSES.ACTIVE &&
    paymentOptions.length !== 1;

  const handleUpgradeMembershipClick = useCallback(
    nextPaymentOption => {
      dispatch(
        updateSubscriptionInfo({
          ...contribution?.subscriptionStatus,
          nextPaymentOption,
          status: MEMBERSHIP_STATUSES.UPGRADE,
        }),
      );
      setPurchaseMembershipModalOpen(false);
    },
    [infoModalType, purchaseMembershipModalOpen],
  );

  const handleUpgradeSubscription = useCallback(() => {
    setIsUpgrade(true);
    setPurchaseMembershipModalOpen(true);
  }, [isUpgrade, purchaseMembershipModalOpen, setIsUpgrade, setPurchaseMembershipModalOpen]);

  const handleInfoModalClose = useCallback(() => setInfoModalType(null), [infoModalType]);

  const closeModalHandler = useCallback(() => {
    setShowModal(false);
    setShowConfirmModal(false);
  }, []);

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const submitModalHandler = useCallback(async () => {
    const userIP = await getIpGlobal();
    setShowModal(false);
    setShowConfirmModal(false);
    toggleSuccessPurchaseModal();
    dispatch(fetchClientContribution(id, userIP));

    push({
      pathname: `/contribution-view/${id}/sessions`,
    });
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }, [dispatch, id]);

  const showOnlyProseedModal = () => {
    setShowModal(false);
    setShowConfirmModal(true);
  };

  let purchaseButton = null;

  const onJoinClick = () => {
    joinContribution({ id, accessCode: isInviteToJoin }).then(() => {
      if (type === ContributionType.contributionCourse || type === ContributionType.contributionMembership) {
        setTimeout(() => {
          window.scroll({
            top: 5000,
            left: 0,
            behavior: 'smooth',
          });
        }, 1000);
      } else {
        setShowModal(true);
      }
      setShowTerms(false);
      dispatch(
        fetchContributionActions.success({
          ...contribution,
          isPurchased: true,
          purchaseStatus: 'succeeded',
          subscriptionStatus: { status: 'active' },
        }),
      );
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    });
  };

  const joinButton = (
    <Button
      TCModal
      activeTemplate={activeTemplate}
      textColor={textColor}
      backgroundColor={bgColor}
      onClick={onJoinClick}
    >
      {titles[type].join}
    </Button>
  );
  // const joinButton = <joinBtnUs onClick={onJoinClick}>{titles[type].join}</joinBtnUs>;

  const handleShowModal = () => {
    if (isInviteToJoin) {
      if (isEmpty(user)) {
        if (
          type === ContributionType.contributionCourse ||
          type === ContributionType.contributionCommunity ||
          type === ContributionType.contributionMembership
        ) {
          push(`/contribution-view/${id}/${isInviteToJoin}/about`);
          if (shouldScroll) {
            setTimeout(() => {
              window.scroll({
                top:
                  activeTemplate === TemplateType.TemplateTwo
                    ? 600
                    : activeTemplate === TemplateType.TemplateOne
                    ? 600
                    : 5000,
                left: 0,
                behavior: 'smooth',
              });
            }, 1000);
          }
        } else {
          setShowModal(true);
        }
      } else {
        setShowTerms(true);
      }
    } else if (
      type === ContributionType.contributionCourse ||
      type === ContributionType.contributionCommunity ||
      type === ContributionType.contributionMembership
    ) {
      push(`/contribution-view/${id}/about`);
      if (shouldScroll) {
        // setTimeout(() => {
        //   window.scroll({
        //     top:
        //       activeTemplate === TemplateType.TemplateTwo
        //         ? 600
        //         : activeTemplate === TemplateType.TemplateOne
        //         ? 600
        //         : 5000,
        //     left: 0,
        //     behavior: 'smooth',
        //   });
        // }, 1000);
        setTimeout(() => {
          const element = document.getElementById('paymentCard');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 1000);
      }
    } else {
      setShowModal(true);
    }
  };

  if (
    [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased, PaymentStatus.proceedSubscription].includes(
      purchaseStatus,
    )
  ) {
    const purchaseButtonTitle =
      purchaseStatus === PaymentStatus.proceedSubscription
        ? titles[type].proceedSubscription
        : isInviteToJoin
        ? titles[type].join
        : titles[type].button;
    purchaseButton = (
      <Button
        textColor={textColor}
        Brdius={true}
        backgroundColor={bgColor}
        activeTemplate={activeTemplate}
        mobileView={mobileView}
        onClick={handleShowModal}
        PaymentBlock={PaymentBlock}
        joinNow
      >
        {activeContribution?.id === '646d143d28ca862ab8aab522' ? 'Download Book' : purchaseButtonTitle}
      </Button>
    );
  }

  if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus)) {
    purchaseButton = (
      <Button
        textColor={textColor}
        backgroundColor={bgColor}
        mobileView={mobileView}
        onClick={() => setShowConfirmModal(true)}
        PaymentBlock={PaymentBlock}
      >
        {titles[type].confirm}
      </Button>
    );
  }

  if (
    type === ContributionType.contributionOneToOne &&
    [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(purchaseStatus)
  ) {
    purchaseButton = (
      <Button textColor={textColor} backgroundColor={bgColor} mobileView={mobileView} PaymentBlock={PaymentBlock}>
        {titles[type].button}
      </Button>
    );
  }
  if (
    type === ContributionType.contributionOneToOne &&
    [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus)
  ) {
    purchaseButton = (
      <Button textColor={textColor} backgroundColor={bgColor} mobileView={mobileView} PaymentBlock={PaymentBlock}>
        {titles[type].confirm}
      </Button>
    );
  }
  if (type === ContributionType.contributionOneToOne) {
    const isActiveSessionPath = location.pathname.includes('session');
    purchaseButton = isActiveSessionPath ? (
      <a href="#contribution-view-calendar">{purchaseButton}</a>
    ) : (
      <Link
        to={
          isInviteToJoin ? `/contribution-view/${id}/${isInviteToJoin}/sessions` : `/contribution-view/${id}/sessions`
        }
        className="purchase-btn_mobileView"
      >
        {purchaseButton}
      </Link>
    );
  }

  if (
    type === ContributionType.contributionOneToOne &&
    contribution?.paymentInfo?.paymentOptions?.includes('Free') &&
    isInviteToJoin &&
    purchaseStatus === PaymentStatus.succeeded
  ) {
    purchaseButton = null;
  }

  const redirectToPaidTierPage = () => {
    Promise.all([
      userService.switchFromClientToCoach({}),
      accountPreferences.setAccountPreferences({ userView: UserRoles.cohealer }),
    ]).then(([auth, preferences]) => {
      push(ROUTES.DASHBOARD);
      dispatch(roleChange(preferences.userView));
      push(ROUTES.ACCOUNT_COACH_BILLING);
    });
  };

  useEffect(() => {
    setAcademyMembershipSubscribing(true);
    updateContibutionViewState(id).then(() => {
      setAcademyMembershipSubscribing(false);
    });
  }, []);

  // const isActiveAcademyMembership = useMemo(() => {
  //   return activeAcademyContributions?.find(item => item?.id === id);
  // }, [id, activeAcademyContributions]);

  const isCurrentContributionFromAcademy = useMemo(() => {
    return academyContributions?.find(item => item?.id === id);
  }, [id, academyContributions]);

  // const showSubscribeForAcademyMembership = useMemo(() => {
  //   return !isActiveAcademyMembership && isCurrentContributionFromAcademy;
  // }, [isActiveAcademyMembership, isCurrentContributionFromAcademy]);

  if (isLoadingApplicationStatus) {
    return <></>;
  }

  if (applicationRequiredButNotApproved && !isPurchased && !isCoach) {
    return (
      <ButtonWrapper mobileView={mobileView} noMaxWidth>
        <Button
          activeTemplate={activeTemplate}
          textColor={textColor}
          backgroundColor={bgColor}
          mobileView={mobileView}
          onClick={() => {
            redirectTo(`/application-form/${id}${code ? `/${code}` : ''}`);
          }}
          disabled={isApplicationResponsePending || disableJoinBtn}
          disableJoinBtn={disableJoinBtn}
        >
          {titles[type].button}
        </Button>
      </ButtonWrapper>
    );
  }

  return (
    <>
      {isCanPurchaseMembership && !isCurrentContributionFromAcademy && !isInviteToJoin && (
        <ButtonWrapper joinBtn joinBtnPadding mobileView={mobileView}>
          <Button
            backgroundColor={bgColor}
            activeTemplate={activeTemplate}
            mobileView={mobileView}
            onClick={handleShowModal}
            textColor={textColor}
          >
            Join
          </Button>
        </ButtonWrapper>
      )}
      {/* 
      {showSubscribeForAcademyMembership && !academyMembershipSubscribing && (
        <ButtonWrapper mobileView={mobileView}>
          <Button
            textColor={textColor}
            backgroundColor={bgColor}
            mobileView={mobileView}
            onClick={() => purchaseMembershipFromAcademy(id)}
          >
            Subscribe
          </Button>
        </ButtonWrapper>
      )} */}

      {!isPurchased && isCanPurchaseMembership && !isCurrentContributionFromAcademy && isInviteToJoin && (
        <ButtonWrapper>
          <Button
            backgroundColor={bgColor}
            activeTemplate={activeTemplate}
            mobileView={mobileView}
            onClick={handleShowModal}
            textColor={textColor}
          >
            Join
          </Button>
        </ButtonWrapper>
      )}

      {/* {academyMembershipSubscribing && isCurrentContributionFromAcademy && <SmallLoader size={20} border={2} />} */}

      {/* {!isActiveAcademyMembership && isCurrentContributionFromAcademy && isInviteToJoin && ( */}
      {/*  <ButtonWrapper> */}
      {/*    <Button mobileView={mobileView} onClick={handleShowModal}> */}
      {/*      Join */}
      {/*    </Button> */}
      {/*  </ButtonWrapper> */}
      {/* )} */}

      {/* TODO https://cohereonline.atlassian.net/browse/CMP1-2207 */}
      {/* {isCanUpgradeMembership && ( */}
      {/*  <ButtonWrapper> */}
      {/*    <Button mobileView={mobileView} onClick={handleUpgradeSubscription}> */}
      {/*      Upgrade Subscription */}
      {/*    </Button> */}
      {/*  </ButtonWrapper> */}
      {/* )} */}

      {infoModalType && <InfoModal type={infoModalType} handleClose={handleInfoModalClose()} />}

      {purchaseMembershipModalOpen && (
        <PurchaseMembershipModal
          isOpen={purchaseMembershipModalOpen}
          onClose={() => setPurchaseMembershipModalOpen(false)}
          isUpgrade={isUpgrade}
          handleUrlModal={handleUrlModal}
          currentSubscription={contribution?.subscriptionStatus?.paymentOption}
          onUpgrade={handleUpgradeMembershipClick}
          contribution={contribution}
        />
      )}

      {(type === ContributionType.contributionCourse ||
        type === ContributionType.contributionCommunity ||
        (type === ContributionType.contributionOneToOne &&
          contribution?.paymentInfo?.paymentOptions?.includes('Free')) ||
        contribution?.paymentInfo?.paymentOptions?.includes('PerSession')) &&
        purchasingDummy && (
          <>
            {!isEmpty(user) ? (
              <Can
                roleName="Client"
                yes={() => (
                  <ButtonWrapper
                    backgroundColor={bgColor}
                    headerMobile={headerMobile}
                    mobileView={mobileView}
                    PaymentBlock={PaymentBlock}
                    textColor={textColor}
                    disableJoinBtn={disableJoinBtn}
                  >
                    {purchaseButton}
                  </ButtonWrapper>
                )}
              />
            ) : (
              <ButtonWrapper
                backgroundColor={bgColor}
                joinBtn
                headerMobile={headerMobile}
                mobileView={mobileView}
                PaymentBlock={PaymentBlock}
                textColor={textColor}
                disableJoinBtn={disableJoinBtn}
              >
                {purchaseButton}
              </ButtonWrapper>
            )}
            {clientPreviewMode && (
              <ButtonWrapper
                backgroundColor={bgColor}
                joinBtn
                headerMobile={headerMobile}
                mobileView={mobileView}
                PaymentBlock={PaymentBlock}
                clientPreviewMode={clientPreviewMode}
                textColor={textColor}
              >
                {purchaseButton}
              </ButtonWrapper>
            )}
          </>
        )}

      {showModal && (
        <PurchaseModal
          isInviteToJoin={isInviteToJoin}
          isOpen={showModal}
          onClose={closeModalHandler}
          onSubmit={submitModalHandler}
          showOnlyProseedModal={showOnlyProseedModal}
          isCouponAvailable={contribution?.isCouponAvailable}
        />
      )}

      {showConfirmModal && (
        <ProceedModal isOpen={showConfirmModal} onClose={closeModalHandler} onSubmit={submitModalHandler} />
      )}

      <AgreeToTermsAndConditions
        actionButtonTitle={titles[type].join}
        onSubmitClick={onJoinClick}
        agreeButton={joinButton}
        showTerms={showTerms}
        onCancel={() => setShowTerms(false)}
      />

      {isOpenSuccessPurchaseModal && (
        <SuccessfulPurchaseModal
          isOpen={isOpenSuccessPurchaseModal}
          handleOpen={toggleSuccessPurchaseModal}
          handleClose={toggleSuccessPurchaseModal}
        />
      )}
    </>
  );
};
TypeOfCourse.propTypes = {
  contribution: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    paymentInfo: PropTypes.shape({
      splitNumbers: PropTypes.number,
      splitPeriod: PropTypes.string,
      paymentOptions: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

TypeOfCourse.defaultProps = {};

const mapStateToProps = ({ contributions: { activeContribution } }) => ({
  activeContribution: activeContribution,
});

export default connect(mapStateToProps)(TypeOfCourse);
