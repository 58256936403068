import React, { useCallback, useState, useRef } from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components';
import { useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import * as paidTier from 'selectors/paidTier';
import Button from 'components/FormUI/Button';
import usePaidTier from 'hooks/usePaidTier';
import { PageTitle, PageTitleGettingStarted, PageTitleVideo, PageTitleVideoText } from 'components/UI/Text/TextStyles';
import { toRem } from 'utils/styles';
import { IconUsersClass } from 'components/Icons/IconUsersClass';
import { IconUsdCircle } from 'components/Icons/IconUsdCircle';
import useShallowEqualSelector from '../../../../hooks/useShallowEqualSelector';
import { IconHeart } from 'components/Icons/IconHeart';
import GettingStartedGuide from '../../../../components/App/GettingStarted';
import { StyledVideo } from 'components/App/GettingStarted/GettingStarted.styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TemplateOne from 'assets/weeklyproducttraining.png';
import TemplateTwo from 'assets/monthlycoachingandmentorship.png';
import TemplateThree from 'assets/newkickstartgrouponboarding.png';
import Overlay from 'assets/coach-overlay.png';
import VideoCam from 'assets/videocam.png';
import { openInNewWindow } from 'services/links';
import { editUserSpecificAttribute } from 'services/user.service';
import { getProfile } from 'actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { PAID_TIER_TITLES } from '../../../../constants';

const StyledContainer = styled.div`
  background-color: white;
  ${({ mobileView }) => (mobileView ? ` text-align: left;` : ` text-align: center;`)}
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  ${({ mobileView }) => (mobileView ? `` : ` padding: 5px 24px;`)}
  border-color: #dfe3e4;
  ${({ expand }) => (expand ? ` padding: 5px 24px;` : ` padding: 24px 24px;`)}
`;

const StyledCardContainder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px -16px 10px -16px;

  ${({ mobileView }) => mobileView && `flex-direction: column`}
`;

const StyledCard = styled.div`
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  flex: 1 1 0;
  ${({ mobileView }) => mobileView && `margin-top: 10px;`}
`;

const StyledCardHeader = styled.h3`
  font-size: 18px;
  font-weight: 800;
  line-height: 1.56;
  letter-spacing: 0.1px;
  text-align: center;
  color: black;
  font-family: Avenir;
  margin-top: 10px;
`;

const StyledCardDescription = styled.p`
  font-size: 14px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0.22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  width: 80%;
  display: flex;
  min-height: 55px;
  margin-top: 20px;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 300px;
  height: 200px;
  display: inline-block;
  align-items: center;
  // z-index: 1;
  position: relative;
`;

const StyledImgCongratsOverlay = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 550px;
  height: 315px;
  ${({ mobileView }) =>
    mobileView &&
    `
  width: 350px;
  height: 315px;
`};
  display: inline-block;
  align-items: center;
  // z-index: 1;
  position: relative;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
  align-items: center;
  z-index: 1;
  position: relative;
`;
function IntroScreen({ resources = false }) {
  const {
    user: { firstName, isGettingStartedCollapsed },
  } = useAccount();
  const { user } = useAccount();
  const theme = useTheme();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isGetStartedOpen, setIsGetStartedOpen] = useState(false);
  const handeleCloseGetStartedGuide = useCallback(() => setIsGetStartedOpen(false), [isGetStartedOpen]);
  const handeleOpenGetStartedGuide = useCallback(() => setIsGetStartedOpen(true), [isGetStartedOpen]);
  const imagesSize = mobileView ? '130px' : '155px';
  // const { isLaunchPlan } = usePaidTier();
  const [expand, setExpand] = useState(!isGettingStartedCollapsed);
  const [showVideo, setShowVideo] = useState(false);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;
  const isImpactPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact;
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const togglePlay = () => {
    setShowVideo(true);
    setTimeout(() => {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }, [2000]);
  };
  return resources ? (
    <>
      {user?.id !== '65cc390b6c651039c09ae644' && (
        <StyledContainer mobileView={mobileView} style={{ marginTop: '10px' }} expand={!expand}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              const data = {
                id: user.id,
                isGettingStartedCollapsed: !isGettingStartedCollapsed,
              };
              setExpand(!expand);
              editUserSpecificAttribute(data).then(() => {
                setTimeout(dispatch(getProfile(user.id)), 3000);
              });
            }}
          >
            <PageTitleGettingStarted mobileView={mobileView}>{`Getting Started Resources`}</PageTitleGettingStarted>
            {expand ? (
              <KeyboardArrowDownIcon
                onClick={e => {
                  e.stopPropagation();
                  const data = {
                    id: user.id,
                    isGettingStartedCollapsed: !isGettingStartedCollapsed,
                  };
                  setExpand(!expand);
                  editUserSpecificAttribute(data).then(() => {
                    setTimeout(dispatch(getProfile(user.id)), 3000);
                  });
                }}
                fontSize="small"
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <ExpandLessIcon
                onClick={e => {
                  e.stopPropagation();
                  const data = {
                    id: user.id,
                    isGettingStartedCollapsed: !isGettingStartedCollapsed,
                  };
                  setExpand(!expand);
                  editUserSpecificAttribute(data).then(() => {
                    setTimeout(dispatch(getProfile(user.id)), 3000);
                  });
                }}
                fontSize="small"
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>

          {expand && (
            <StyledCardContainder mobileView={mobileView}>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateThree}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <StyledCardHeader>Book 1:1 Set Up & Launch Call</StyledCardHeader> */}
                  <StyledCardDescription>
                    Book your 1:1 call to set up your services and begin enrolling clients.
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      isScalePlan
                        ? openInNewWindow('https://www.cohere.live/hasialaunchcall')
                        : isImpactPlan
                        ? openInNewWindow('https://www.cohere.live/alexlaunchcall1')
                        : openInNewWindow(
                            'https://cohereacademy.cohere.live/contribution-view/6560d47e50bbf52ab67b256b/66d0fdb0-5b8d-45ba-bab8-9d5f26503962/about',
                          );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateOne}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <StyledCardHeader>Weekly Platform Trainings</StyledCardHeader> */}
                  <StyledCardDescription>
                    Join weekly LIVE group platform trainings & Q+A's to learn new features on Cohere!
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      openInNewWindow(
                        'https://app.cohere.live/contribution-view/650dfff62ed8a540032e75b2/3132c5b5-a2fe-41bb-b037-e22bd56db54e/about',
                      );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateTwo}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <IconHeart
            style={{
              width: imagesSize,
              height: imagesSize,
              color: '#116582',
            }}
          /> */}
                  {/* <StyledCardHeader>The Cohere Academy</StyledCardHeader> */}
                  <StyledCardDescription>
                    Join LIVE monthly Masterclasses & Coaching Sessions to grow your impact and income online!
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      openInNewWindow(
                        'https://app.cohere.live/contribution-view/63c60aecf56b8e177d6a1c09/1f58d1fd-3951-485d-972d-41e273fead9b/about',
                      );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
            </StyledCardContainder>
          )}
        </StyledContainer>
      )}
    </>
  ) : (
    <>
      <StyledContainer
        mobileView={mobileView}
        expand={false}
        style={{
          marginBottom: '20px',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignContent: 'center',
        }}
      >
        <PageTitleVideo mobileView={mobileView}>{`Welcome to Cohere!`}</PageTitleVideo>
        <PageTitleVideoText mobileView={mobileView}>
          Congrats on this powerful step. We’re excited to see your growth! Watch this short video to get started.
        </PageTitleVideoText>
        {showVideo ? (
          <StyledVideo
            mobileView={mobileView}
            ref={videoRef}
            style={{ marginTop: '20px' }}
            controls="controls"
            preload="metadata"
          >
            <source src={`https://coherepublic-prod.s3.amazonaws.com/marketing/cohere+dashboard+w+1to1.mp4`} />
          </StyledVideo>
        ) : (
          <div style={{ marginTop: '20px' }} onClick={togglePlay}>
            <StyledImgCongratsOverlay mobileView={mobileView} previewImg={Overlay}></StyledImgCongratsOverlay>
          </div>
        )}
      </StyledContainer>
      {user?.id !== '65cc390b6c651039c09ae644' && (
        <StyledContainer expand={!expand}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <PageTitleGettingStarted mobileView={mobileView}>{`Getting Started Resources`}</PageTitleGettingStarted>
            {expand ? (
              <KeyboardArrowDownIcon
                onClick={() => {
                  setExpand(!expand);
                }}
                fontSize="small"
              />
            ) : (
              <ExpandLessIcon
                onClick={() => {
                  setExpand(!expand);
                }}
                fontSize="small"
              />
            )}
          </div>

          {expand && (
            <StyledCardContainder mobileView={mobileView}>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateThree}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <StyledCardHeader>Book 1:1 Set Up & Launch Call</StyledCardHeader> */}
                  <StyledCardDescription>
                    Book your 1:1 call to set up your services and begin enrolling clients.
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      isScalePlan
                        ? openInNewWindow('https://www.cohere.live/hasialaunchcall')
                        : isImpactPlan
                        ? openInNewWindow('https://www.cohere.live/alexlaunchcall1')
                        : openInNewWindow(
                            'https://cohereacademy.cohere.live/contribution-view/6560d47e50bbf52ab67b256b/66d0fdb0-5b8d-45ba-bab8-9d5f26503962/about',
                          );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateOne}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <StyledCardHeader>Weekly Platform Trainings</StyledCardHeader> */}
                  <StyledCardDescription>
                    Join weekly LIVE group platform trainings & Q+A's to learn new features on Cohere!
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      openInNewWindow(
                        'https://app.cohere.live/contribution-view/650dfff62ed8a540032e75b2/3132c5b5-a2fe-41bb-b037-e22bd56db54e/about',
                      );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
              <StyledCard mobileView={mobileView}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledImgCongrats previewImg={TemplateTwo}>
                    <div
                      style={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '14px',
                        backgroundColor: '#E1BB62',
                        position: 'absolute',
                        bottom: -15,
                        right: -15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <StyledImg previewImg={VideoCam}></StyledImg>
                    </div>
                  </StyledImgCongrats>
                  {/* <IconHeart
              style={{
                width: imagesSize,
                height: imagesSize,
                color: '#116582',
              }}
            /> */}
                  {/* <StyledCardHeader>The Cohere Academy</StyledCardHeader> */}
                  <StyledCardDescription>
                    Join LIVE monthly Masterclasses & Coaching Sessions to grow your impact and income online!
                  </StyledCardDescription>
                  <Button
                    invert
                    onClick={() => {
                      openInNewWindow(
                        'https://app.cohere.live/contribution-view/63c60aecf56b8e177d6a1c09/1f58d1fd-3951-485d-972d-41e273fead9b/about',
                      );
                    }}
                    style={{
                      borderColor: '#C9B382',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#C9B382',
                      minWidth: '10rem',
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </StyledCard>
            </StyledCardContainder>
          )}
        </StyledContainer>
      )}
      <GettingStartedGuide isOpen={isGetStartedOpen} closeHandler={handeleCloseGetStartedGuide} />
    </>
  );
}

// IntroScreen.propTypes = {};

export default IntroScreen;
