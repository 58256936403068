import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { useAccount, useEndVideoChat, useRouter, useShallowEqualSelector } from 'hooks';
import * as contributionActions from 'actions/contributions';
import { PAYMENTS_STATUS } from 'pages/ContributionView/components/PurchaseMembershipModal/PurchaseModal.constants';
import Banner from 'components/Banner/Banner';
import { fetchClientContribution } from 'actions/contributions';
import styled from 'styled-components';
import CustomPurchaseBlock from '../components/PurchaseBlock/CustomPurchaseBlock';
import fbImg from '../../../assets/facebook1.png';
import linkedImg from '../../../assets/linkedin1.png';
import instaImg from '../../../assets/instagram1.png';
import youtube from '../../../assets/youtube1.png';
import tiktok from '../../../assets/tiktok1.png';
import website from '../../../assets/website1.png';
import { BorderDiv } from '../components/AboutMainInfoBlock/AboutMainInfo.styles';
import { ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';

import TestimonialsBlock from 'pages/ContributionView/components/TestimonialsBlock';
import { fetchTestimonials } from 'actions/testimonials';
import { isTestimonialsVisible } from 'selectors/testimonials';
import { isNil } from 'ramda';

import AboutMainInfoBlock from '../components/AboutMainInfoBlock/AboutMainInfoTemOne';
import AboutGeneralInfoBlock from '../components/AboutGeneralInfoBlockTemOne';
import AboutDetailsBlock from '../components/AboutDetailsBlockTemOne';
import EnrollmentBlock from '../components/EnrollmentBlock';
import AboutPaymentBlock from '../components/AboutPaymentBlock';
import PurchaseBlock from '../components/PurchaseBlock/PurchaseBlock';
import { PAYMENT_OPTIONS, ROUTES } from '../../../constants';
import MeetYourCoach from '../components/AboutMainInfoBlock/MeetYourCoach';
import TestimonialTemOne from '../components/TestimonialTemOne';

import PaymentCard from '../components/PaymentCard';
import OptinPageMetaPixelContrib from 'components/AdsComponents/OptinPageContrib';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
import ClarityScript from 'components/AdsComponents/ClarityScript';
import MetaPixel from 'components/AdsComponents/MetaPixel';
import { extractGtmId, extractMetaPixelId } from 'utils/utils';
import GTMComponent from 'components/AdsComponents/GoogleTagCommon';

const RightLinks = styled.div`
  display: flex;
  align-items: flex-start;
  // justify-content: 'flex-start';
  justify-content: ${({ mobileView }) => (mobileView ? 'flex-start' : 'flex-end')};
  width: ${({ mobileView }) => (mobileView ? '100%' : '30%')};
  ${({ mobileView }) =>
    mobileView &&
    `
    margin-bottom: 20px;
    margin-top: 10px;
  `}
`;

const PaymentCardWrapper = styled.div`
  position: sticky;
  top: 130px;
  margin-left: 15px;
  // margin-top: 35px;
`;

const HyperLink = styled.a.attrs({ target: '_blank' })``;

function AboutContribution({ contribution, getClientContribution, getCohealerContribution, currentRole }) {
  const {
    id,
    previewContentUrls,
    categories,
    serviceProviderName,
    bio,
    coachCountry,
    gender,
    minAge,
    languageCodes,
    whoIAm,
    whatWeDo,
    purpose,
    preparation,
    earnedRevenue,
    paymentInfo,
    enrollment,
    closestClassForBanner,
    contributionPartners,
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    tiktokUrl,
    facebookUrl,
    websiteUrl,
    whoIAmLabel,
    whatWeDoLabel,
    purposeLabel,
    preparationLabel,
    isPurchased,
    type,
    viewPreparation,
    viewPurpose,
    viewWhatWeDo,
    viewWhoIAm,
    whoIAmIcon,
    whatWeDoIcon,
    purposeIcon,
    preparationIcon,
    title,
    customTitleForMeetYourCoach,
    contributionTagline,
    testimonials,
    isCustomBrandingColorsActive,
    brandingColors,
    isDarkModeEnabled,
    metaPixel,
    googleTags,
  } = contribution;
  const urls = {
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
    tiktokUrl,
  };

  const isCoach = currentRole == UserRoles.cohealer;
  const colorToUse = determineColorToUse(contribution);
  const { history, query, location } = useRouter();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { userId } = useShallowEqualSelector(state => state?.contributions?.activeContribution || '');
  const { user } = useAccount();
  const isTestimonialsEnabled = useSelector(isTestimonialsVisible);
  const dispatch = useDispatch();
  // const hasAnyValue = !viewWhoIAm || !viewWhatWeDo || !viewPurpose || !viewPreparation;
  const hasAnyValue =
    (!viewWhoIAm && !!whoIAm) ||
    (!viewWhatWeDo && !!whatWeDo) ||
    (!viewPurpose && !!purpose) ||
    (!viewPreparation && !!preparation);

  // useEffect(() => {
  //   const sessionsRefresher = setInterval(() => {
  //     if (!isPurchased && query.payment === PAYMENTS_STATUS.SUCCESS) {
  //       dispatch(fetchClientContribution(id));
  //     }
  //   }, 10000);

  //   return () => {
  //     clearInterval(sessionsRefresher);
  //   };
  // }, [isPurchased]);

  const refreshContribution = useCallback(() => {
    if (currentRole === UserRoles.client) {
      getClientContribution(id);
    } else if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);

  const { Popup } = useEndVideoChat(refreshContribution);

  const isEnrollmentVisible = enrollment && !enrollment?.anyTime;
  const isPurchaseBlock = [
    ContributionType.contributionCourse,
    ContributionType.contributionMembership,
    ContributionType.contributionCommunity,
  ].includes(type);

  // redirect after joining
  useEffect(() => {
    if (query?.isPurchased) {
      if (currentRole === UserRoles?.client) {
        const anss = contribution?.contributionPartners?.filter(x => x?.userId === user?.id);
        if (anss?.length > 0) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
        }
      }
    }

    if (isPurchased) {
      if (query.payment === PAYMENTS_STATUS.SUCCESS) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
        }
      }

      if (shouldRedirect) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          dispatch(contributionActions.setInviteCode(null));
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 1000);
        }
      }
    }
  }, [dispatch, history, id, isPurchased, query.payment, shouldRedirect, query.code]);

  // useEffect(() => {
  //   if (!isNil(query?.code)) {
  //     dispatch(contributionActions.setInviteCode(query?.code));
  //   }
  //   // else {
  //   //   dispatch(contributionActions.setInviteCode(null));
  //   // }
  // }, [dispatch, query?.code]);

  useEffect(() => {
    dispatch(fetchTestimonials(id));
  }, [dispatch, id]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const oneToOneType = type === ContributionType.contributionOneToOne;

  return (
    !isEmpty(contribution) && (
      <>
        {id === '66466c79ee9e6c514da9d7f0' && <GoogleAnalytics />}
        {metaPixel?.landingPageEnabled === true && (
          <MetaPixel pixelId={extractMetaPixelId(metaPixel?.landingPagePixelId)} />
        )}
        {googleTags?.landingPageEnabled === true && (
          <GTMComponent pixelId={extractGtmId(googleTags?.landingPageTagId)} />
        )}
        {id === '66466c79ee9e6c514da9d7f0' && <OptinPageMetaPixelContrib />}
        {id === '66466c79ee9e6c514da9d7f0' && <ClarityScript />}
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <Grid container spacing={oneToOneType ? 1 : 4} className="Temp-One-Page-Styling">
          <Grid item sm={oneToOneType ? 8 : 12} xs={12} className="main-about-div">
            <Grid item xs={12}>
              <AboutMainInfoBlock
                id={id}
                user={user}
                userId={userId}
                previewContentUrls={previewContentUrls}
                serviceProviderName={serviceProviderName}
                contributionPartners={contributionPartners}
                socialUrls={urls}
                categories={categories}
                title={title}
                contribution={contribution}
                earnedRevenue={earnedRevenue}
                paymentInfo={paymentInfo}
                isCoach={isCoach}
                isPurchased={isPurchased}
                gender={gender}
                minAge={minAge}
                languageCodes={languageCodes}
                contributionTagline={contributionTagline}
                setShouldRedirect={setShouldRedirect}
                isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                brandingColors={colorToUse}
                type={type}
              />
            </Grid>
            {/* {mobileView && (facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) && (
              <Grid item xs={12}>
                <RightLinks mobileView={mobileView}>
                  {facebookUrl && (
                    <div>
                      <HyperLink href={facebookUrl}>
                        <img style={{ padding: '0px 5px' }} src={fbImg}></img>
                      </HyperLink>
                    </div>
                  )}
                  {linkedInUrl && (
                    <div>
                      <HyperLink href={linkedInUrl}>
                        <img style={{ padding: '0px 5px' }} src={linkedImg}></img>
                      </HyperLink>
                    </div>
                  )}
                  {instagramUrl && (
                    <div>
                      <HyperLink href={instagramUrl}>
                        <img style={{ padding: '0px 5px' }} src={instaImg}></img>
                      </HyperLink>
                    </div>
                  )}
                  {youtubeUrl && (
                    <div>
                      <HyperLink href={youtubeUrl}>
                        <img style={{ padding: '0px 5px' }} src={youtube}></img>
                      </HyperLink>
                    </div>
                  )}
                  {websiteUrl && (
                    <div>
                      <HyperLink href={websiteUrl}>
                        <img style={{ padding: '0px 5px' }} src={website}></img>
                      </HyperLink>
                    </div>
                  )}
                  {tiktokUrl && (
                    <div>
                      <HyperLink href={tiktokUrl}>
                        <img style={{ padding: '0px 5px' }} src={tiktok}></img>
                      </HyperLink>
                    </div>
                  )}
                </RightLinks>
              </Grid>
            )} */}
            {smView && (!isPurchased || contribution?.type === ContributionType.contributionOneToOne) && (
              <Grid item xs={12}>
                <div style={{ marginTop: '15px' }}>
                  <PaymentCard
                    contribution={contribution}
                    type={type}
                    isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                    brandingColors={brandingColors}
                    onJoin={setShouldRedirect}
                  />
                </div>
              </Grid>
            )}
            {hasAnyValue && (
              <>
                {!mobileView && (
                  <Grid item xs={12}>
                    <AboutDetailsBlock
                      whoIAm={whoIAm}
                      whatWeDo={whatWeDo}
                      purpose={purpose}
                      preparation={preparation}
                      whoIAmLabel={whoIAmLabel}
                      whatWeDoLabel={whatWeDoLabel}
                      purposeLabel={purposeLabel}
                      preparationLabel={preparationLabel}
                      viewPreparation={viewPreparation}
                      viewPurpose={viewPurpose}
                      viewWhatWeDo={viewWhatWeDo}
                      viewWhoIAm={viewWhoIAm}
                      whoIAmIcon={whoIAmIcon}
                      whatWeDoIcon={whatWeDoIcon}
                      purposeIcon={purposeIcon}
                      preparationIcon={preparationIcon}
                      brandingColors={brandingColors}
                    />
                  </Grid>
                )}
                {mobileView && (
                  <Grid item xs={12}>
                    <AboutDetailsBlock
                      whoIAm={whoIAm}
                      whatWeDo={whatWeDo}
                      purpose={purpose}
                      preparation={preparation}
                      whoIAmLabel={whoIAmLabel}
                      whatWeDoLabel={whatWeDoLabel}
                      purposeLabel={purposeLabel}
                      preparationLabel={preparationLabel}
                      viewPreparation={viewPreparation}
                      viewPurpose={viewPurpose}
                      viewWhatWeDo={viewWhatWeDo}
                      viewWhoIAm={viewWhoIAm}
                      whoIAmIcon={whoIAmIcon}
                      whatWeDoIcon={whatWeDoIcon}
                      purposeIcon={purposeIcon}
                      preparationIcon={preparationIcon}
                      brandingColors={brandingColors}
                    />
                  </Grid>
                )}
              </>
            )}
            {!mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  id={id}
                  user={user}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach}
                  userId={userId}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName}
                  bio={bio}
                  coachCountry={coachCountry}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                  isDarkModeEnabled={isDarkModeEnabled}
                />
              </Grid>
            )}
            {mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  id={id}
                  user={user}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach}
                  userId={userId}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName}
                  bio={bio}
                  coachCountry={coachCountry}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={colorToUse}
                  isDarkModeEnabled={isDarkModeEnabled}
                />
              </Grid>
            )}
            {!mobileView && (
              <Grid item md={12} xs={12}>
                <TestimonialTemOne testimonials={testimonials} />
              </Grid>
            )}
            {mobileView && (
              <Grid item xs={12} md={12} sm={12}>
                <TestimonialTemOne testimonials={testimonials} />
              </Grid>
            )}
          </Grid>
          {!smView && oneToOneType && (
            <Grid item sm={4} xs={12}>
              <PaymentCardWrapper>
                <PaymentCard
                  contribution={contribution}
                  type={type}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                  onJoin={setShouldRedirect}
                />
              </PaymentCardWrapper>
            </Grid>
          )}
        </Grid>
        {currentRole === UserRoles.cohealer && <Popup applyTheming />}
      </>
    )
  );
}

const mapStateToProps = ({ contributions: { activeContribution, loading }, account }) => ({
  contribution: activeContribution || {},
  loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

AboutContribution.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  contribution: PropTypes.objectOf(PropTypes.any),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
};

AboutContribution.defaultProps = {
  contribution: {},
};

export default connect(mapStateToProps, actions)(AboutContribution);
