import React from 'react';
import '../../ViewAsCoachPage.scss';
import { StyledSection } from './StyledElements';
import { Link } from 'react-router-dom';

function Footer({ theme, data, brandingColors, links, currentPage, setCurrentPage }) {
  const color = theme === 'dark' ? 'white' : 'black';
  const avatarUrl = data?.customLogo ?? null;

  const mappedLink = [];
  links.forEach(link => {
    switch (link.name) {
      case 'ContributionCommunity':
        mappedLink.push({ name: 'Communities', path: link.path });
        return;
      case 'ContributionCourse':
        mappedLink.push({ name: 'Group Courses', path: link.path });
        return;
      case 'ContributionMembership':
        mappedLink.push({ name: 'Memberships', path: link.path });
        return;
      case 'Workshops':
        mappedLink.push({ name: 'Workshops', path: link.path });
        return;
      case 'ContributionOneToOne':
        mappedLink.push({ name: '1:1 Services', path: link.path });
        return;
      default:
    }
  });

  return (
    <>
      <StyledSection color={color} style={{ marginTop: '80px' }} className="text-center">
        <div className={`footer-container ${theme === 'dark' ? 'dark-mode' : ''}`}>
          {avatarUrl && (
            <picture>
              <img className="avatar" src={avatarUrl} alt="" />
            </picture>
          )}
          <div className="privary-policy">
            {data?.profilePageViewModel?.privacyPolicyUrl && (
              <p
                className="privacy-policy-link"
                style={{
                  cursor: 'pointer',
                  fontSize: '16px',
                  color: theme === 'dark' ? 'white' : 'rgba(74, 74, 74, 1)',
                }}
                onClick={() => window.open(data?.profilePageViewModel?.privacyPolicyUrl, '_blank')}
              >
                Privacy Policy
              </p>
            )}

            {mappedLink?.map(link => {
              return (
                <Link
                  isActive
                  onClick={() => {
                    setCurrentPage(link?.path);
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: theme === 'dark' ? 'white' : 'rgba(74, 74, 74, 1)',
                  }}
                  to={link?.path}
                >
                  {link?.name}
                </Link>
              );
            })}
          </div>
        </div>
      </StyledSection>
    </>
  );
}

export default Footer;
