import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import * as userActions from 'actions/user';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import Modal from 'components/UI/Modal';
import TableCell from '@material-ui/core/TableCell';
import { StyledLink } from '../../CreateContribution/style';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { editUserSpecificAttribute, getUserProfile } from 'services/user.service';
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from 'utils/axiosInstance';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { Spinner } from 'react-activity';
import { getProfile } from 'actions/user';
import 'react-activity/dist/library.css';
import { connect } from 'react-redux';
import * as calendarsActions from 'actions/calendars';
import { useHttp } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { getDomainIcon } from 'utils/calendar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo } from 'services/links';
import Loader from 'components/UI/Loader';
import { makeStyles } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { editUserProfile } from 'services/user.service';
import { RemoveCalendarModal, DefaultCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';
import EmailComponent from './Email';
import { flexbox } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MuiTableCell from '@material-ui/core/TableCell';
import styled, { css } from 'styled-components';
import { tableCellClasses } from '@mui/material/TableCell';
import { padding, position } from 'polished';
import { BodyText } from 'components/UI/Text/TextStyles';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const StyledButton = styled(Button)`
  margin-right: 0px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const CalendarInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => (!props.isDefault ? 'pointer' : 'default')};
`;

const EmptyCalendarWrapper = styled.div`
  padding: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PaymentProcessors = ({ getProfile }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [cofirmTaxEnable, setCofirmTaxEnable] = useState(false);
  const { loading } = useHttp();
  const history = useHistory();
  const [advancedSetupShowHide, setadvancedSetupShowHide] = useState(null);
  const [customSetupShowHide, setcustomSetupShowHide] = useState(null);
  const [isRescheduleModalOpen, setRescheduleModal] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector(state => state.account.user);
  useEffect(() => {
    setadvancedSetupShowHide(userDetail.isStandardAccount);
    setcustomSetupShowHide(userDetail.connectedStripeAccountId);
  }, []);
  const handleSubmit = () => {
    setRescheduleModal(false);
    setCofirmTaxEnable(true);
  };

  const handleSubmitEnable = () => {
    onAutoTaxChange(userDetail.isStandardTaxEnabled ? false : true);
  };
  const useSelectStyle = makeStyles(() => ({
    container: {
      paddingTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      margin: '0px 16px',
    },
    checkboxLabel: {
      marginBottom: '0px',

      '& > span:first-child': {
        padding: '0px 9px',
      },
      '& > span:nth-child(2)': {
        padding: '0px 9px',
      },
    },
    setupDescription: {
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontWeight: '350',
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    section: {
      width: '100%',
      border: '1px solid #E7E7E7',
      borderRadius: '4px',
      padding: '16px',
    },

    description: {
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontWeight: '350',
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.87)',
      margin: '5px 0px',
      padding: '10px 16px',
    },
    btnBgColor: {
      borderRadius: '4px',
      backgroundColor: '#C9B382',
      border: 'none',
      padding: '10px 14px',
      color: '#fff',
      marginTop: '18px',
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontWeight: '800',
      lineHeight: '24px',
      width: '25%',
    },
    txtUnderline: {
      fontFamily: 'Avenir',
      fontWeight: '800',
      fontSize: '16px',
      color: '#4A4A4A',
      margin: '0px',
    },
    line: {
      width: '100px',
      borderBottom: '1px solid #DFE3E4',
      marginTop: '-7px',
    },
    line2: {
      width: '120px',
      borderBottom: '1px solid #DFE3E4',
      marginTop: '-7px',
    },
    line5: {
      width: '120px',
      borderBottom: '1px solid #DFE3E4',
      marginTop: '-14px',
    },
  }));

  const StyledButton = styled(Button)`
    margin-right: 0px;
  `;
  const TableCell = withStyles({
    root: {
      borderBottom: 'none',
    },
  })(MuiTableCell);
  const classNames = useSelectStyle();
  const CardMainDiv = styled.div``;
  const CardMainContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 4px;
    padding: 0px 0px 16px 0px;
  `;
  const CardHeading = styled.h2`
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    align-items: center;
    color: #000000;
    padding: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  `;
  const StepsMainContainer = styled.div`
    display: flex;
  `;
  const SimpleStep = styled.div``;
  const StyledDiv = styled.div`
    display: flex;
    align-items: center;
  `;
  const onSelectedChange = checked => {
    const userData = {
      id: userDetail.id,
      defaultPaymentMethod: checked,
    };
    editUserSpecificAttribute(userData).then(getProfile(userDetail.id));
  };

  const onAutoTaxChange = checked => {
    const userData = {
      id: userDetail.id,
      isStandardTaxEnabled: checked,
    };
    editUserSpecificAttribute(userData)
      .then(getProfile(userDetail.id))
      .then(() => {
        setRescheduleModal(false);
      });
  };
  return (
    <>
      <CardMainContainer id="integration2">
        <CardHeading>Payment Processors</CardHeading>
        {/* <StepsMainContainer>
          <SimpleStep></SimpleStep>
          <AdvancedStep></AdvancedStep>
        </StepsMainContainer> */}
        <div className={classNames.description}>
          You can integrate with a payment processor to start accepting payment for your services! Once you start
          accepting payment, you will receive your funds directly from your payment processor.
        </div>
        <CardMainDiv className={classNames.container} mobileView={mobileView}>
          <div className={classNames.section}>
            {!customSetupShowHide && (
              <>
                <Container>
                  <StyledDiv>
                    <h5 className={classNames.txtUnderline}>Simple Setup</h5>
                    <div>
                      <Tooltip
                        title="We recommend using this if you want a simpler approach with managing payments."
                        arrow
                        className="ml-2"
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </Tooltip>
                    </div>
                  </StyledDiv>
                  <div className={classNames.setupDescription}>
                    Cohere provides an easy ready-to-go payment processor that takes minutes to set up.
                  </div>

                  {/* <div className={classNames.line2}></div> */}
                  <button
                    onClick={() => {
                      axiosInstance.post(`/User/CreateStripeCustomAccount`).then(res => {
                        redirectTo(res.data);
                        userActions.getProfile(userDetail.id, dispatch);
                      });
                    }}
                    className={classNames.btnBgColor}
                  >
                    Connect with Custom
                  </button>
                </Container>
              </>
            )}
            {customSetupShowHide && (
              <Container>
                <StyledDiv>
                  <h5 className={classNames.txtUnderline}>Simple Setup</h5>
                  <div>
                    <Tooltip
                      title="We recommend using this if you want a simpler approach with managing payments."
                      arrow
                      className="ml-2"
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                </StyledDiv>
                <div className={classNames.setupDescription}>
                  Cohere provides an easy ready-to-go payment processor that takes minutes to set up.
                </div>
                {/* <div className={classNames.line}></div> */}
                <FormGroup style={{ gap: '16px' }}>
                  <FormControlLabel
                    style={{
                      color: '#000',
                      fontFamily: 'Avenir',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      fontSize: '14px',
                    }}
                    className={classNames.checkboxLabel}
                    control={<Checkbox disabled={true} defaultChecked />}
                    label="Cohere Payment Processor"
                  />
                  <FormControlLabel
                    style={{
                      color: '#4A4A4A',
                      fontFamily: 'Avenir',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      fontSize: '14px',
                    }}
                    className={classNames.checkboxLabel}
                    value="female"
                    control={
                      <Radio
                        style={{ color: colors.darkOceanBlue }}
                        onClick={() => {
                          onSelectedChange('Simple');
                        }}
                        checked={userDetail.defaultPaymentMethod === 'Simple' ? true : false}
                      />
                    }
                    label="Mark as Default"
                  />
                </FormGroup>
              </Container>
            )}
          </div>
          <div className={classNames.section}>
            {!advancedSetupShowHide && (
              <>
                <Container>
                  <StyledDiv>
                    <h5 className={classNames.txtUnderline}>Advanced Setup</h5>
                    <div>
                      <Tooltip
                        title="Contact support@cohere.live if you need any assistance removing this account."
                        arrow
                        className="ml-2"
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </Tooltip>
                    </div>
                  </StyledDiv>
                  <div className={classNames.setupDescription}>
                    By default, cohere lets you connect your own stripe account that you control and manage externally
                    from Cohere.
                  </div>
                  {/* <div className={classNames.line2}></div> */}
                  <button
                    onClick={() => {
                      axiosInstance.post(`/User/CreateStripeStandardAccount`).then(res => {
                        redirectTo(res.data);
                        getProfile(userDetail.id);
                        // userActions.getProfile(userDetail.id, dispatch);
                      });
                    }}
                    className={classNames.btnBgColor}
                  >
                    Connect with Stripe
                  </button>
                </Container>
              </>
            )}
            {advancedSetupShowHide && (
              <>
                <Container>
                  <StyledDiv>
                    <h5 className={classNames.txtUnderline}>Advanced Setup</h5>
                    <div>
                      <Tooltip
                        title="This is recommended if you wish to manage and process your own 
                        refunds and have complete oversight and control over all advanced 
                        Stripe functionality related to your payments. Furthermore, if you require 
                        tax for your services, this is the recommended payment processor."
                        arrow
                        className="ml-2"
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </Tooltip>
                    </div>
                  </StyledDiv>
                  <div className={classNames.setupDescription}>
                    By default, cohere lets you connect your own stripe account that you control and manage externally
                    from Cohere.
                  </div>
                  {/* <div className={classNames.line5}></div> */}
                  <FormGroup style={{ gap: '16px' }}>
                    <FormControlLabel
                      className={classNames.checkboxLabel}
                      control={<Checkbox disabled={userDetail.isStandardAccount} defaultChecked />}
                      label={`${
                        userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                      }'s Stripe Accounts`}
                    />
                    <FormControlLabel
                      className={classNames.checkboxLabel}
                      disabled={userDetail.isStandardTaxEnabled}
                      label={`Enable Stripe Auto-Tax`}
                      checked={userDetail.isStandardTaxEnabled}
                      onChange={() => {
                        if (userDetail.isStandardTaxEnabled === false) {
                          setRescheduleModal(true);
                        }
                      }}
                      control={
                        <Checkbox
                          defaultChecked={userDetail.isStandardTaxEnabled}
                          disabled={userDetail.isStandardTaxEnabled ? true : false}
                          style={{ color: userDetail.isStandardTaxEnabled ? 'primary' : colors.darkOceanBlue }}
                        />
                      }
                    />
                    <FormControlLabel
                      // style={{ marginTop: '-12px' }}
                      className={classNames.checkboxLabel}
                      value="female"
                      control={
                        <Radio
                          style={{ color: colors.darkOceanBlue }}
                          onClick={() => {
                            onSelectedChange('Advance');
                          }}
                          checked={userDetail.defaultPaymentMethod === 'Simple' ? false : true}
                        />
                      }
                      label="Mark as Default"
                    />
                  </FormGroup>
                </Container>
              </>
            )}
          </div>
        </CardMainDiv>
        <Modal
          isOpen={isRescheduleModalOpen}
          onCancel={() => {
            setRescheduleModal(false);
          }}
          onSubmit={handleSubmitEnable}
          title="Stripe Settings For Tax"
          submitTitle={'I am sure I want to enable tax'}
          cancelTitle={'Not at this time'}
        >
          <BodyText>
            Stripe provides an additional service to automatically calculate and collect Sales Tax for your services on
            Cohere. This can be enabled or disabled on your direct stripe account dashboard by visiting ‘More’ > ‘Tax’
            on the top nav bar
            <StyledLink href="https://dashboard.stripe.com/settings/tax" rel="noopener noreferrer" target="_blank">
              ( https://dashboard.stripe.com/settings/tax).
            </StyledLink>{' '}
            After enabled on Stripe, you can enable Sales Tax on Cohere. Please note, Cohere cannot enable or disable
            stripe settings for Tax - this must be done by you first.
            <br />
          </BodyText>
          <BodyText>Are you sure you want to enable Stripe Auto Tax?</BodyText>
        </Modal>
      </CardMainContainer>
    </>
  );
};

const mapStateToProps = () => ({});
PaymentProcessors.propTypes = {
  getProfile: PropTypes.func.isRequired,
};
const actions = {
  getProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(PaymentProcessors);
