import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useVideoChatRecording } from 'hooks/useVideoChatRecording';
import useAccount from 'hooks/useAccount';
import { getAudioInputDevices, getAudioOutputDevices, getVideoDevices } from 'utils/devices';
import { setItem, getItem, removeItem, STORAGE_KEYS } from 'services/localStorage.service';
import { startRecording, stopRecording } from 'services/video.service';
import { getCohealerContribution } from 'services/contributions.service';
import { setVideoChatRecordingStatus } from 'actions/videoChat';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import ParticipantsAudioVolumeProvider from './ParticipantsAudioVolumeProvider';
import VideoChatView from './VideoChatView';
import { Microphone, Camera, ViewMode, ViewModeType, Participants, ShareScreen, Chat, Notes, EndCall } from './Toolbar';
import StartRecord from './Toolbar/recording/Start';
import StopRecord from './Toolbar/recording/Stop';
import { ParticipantsPanel, ChatPanel, NotesPanel, SidePanelType } from './sidePanels';
import Modal from 'components/UI/Modal';
// import { DeviceType } from './Toolbar/devices/DevicesList';
import { ContributionType, UserRoles } from 'helpers/constants';
import { testAudioOutputDevice, AudioOutputTest } from '@twilio/rtc-diagnostics';
import { isEmpty } from 'lodash';
import useRouter from 'hooks/useRouter';
import { getCookieOfDomain } from 'services/cookies.service';

export const VideoChartContent = ({
  className,
  chatId,
  room: { localParticipant },
  participants,
  dominantSpeakerParticipant,
  screenShareParticipants,
  onLeaveChat,
  audioTrack,
  videoTrack,
  screenTrack,
  isTest,
}) => {
  const [viewModeType, setViewModeType] = useState(ViewModeType.speaker);
  const dispatch = useDispatch();
  const contribution = useContribution();
  const isOneToOne = contribution?.type === ContributionType.contributionOneToOne;
  const isGroupChatHidden = contribution?.isGroupChatHidden;
  useEffect(() => {
    const screenShareParticipantsAmount = screenShareParticipants.length;
    if (screenShareParticipantsAmount) {
      setViewModeType(ViewModeType.speaker);
    }
  }, [screenShareParticipants.length]);

  const [sidePanelType, setSidePanelType] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const { user, currentRole } = useAccount();

  const onSidePanelEnabledChange = (newSidePanelType, enabled) => setSidePanelType(enabled ? newSidePanelType : null);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const videoChatClassname = mobileView ? 'video-chat-mobile' : 'video-chat';

  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState();
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState();
  const { contributionId, roomId, recordingStatus, getCurrentRoomStatus } = useVideoChatRecording();
  const isCohealer = currentRole === UserRoles.cohealer;
  const [openModal, setModal] = useState(false);
  const { parentDomain } = useRouter();
  const isGuestVideoCall = getCookieOfDomain('guestVideoCall', parentDomain.split(':3000')[0]) ?? false;

  useEffect(() => {
    getCurrentRoomStatus(roomId, contributionId).then(res => {
      dispatch(setVideoChatRecordingStatus(res.payload));
    });
  }, []);

  useEffect(() => {
    getCohealerContribution(contributionId)
      .then(res => {
        setIsOwner(res.userId === user.id);
      })
      .catch(error => {
        setIsOwner(false);
      });
  }, []);

  const onSelectAudioInputDevice = useCallback(
    (deviceLabel, deviceId) => {
      audioTrack.switchAudioDevice(deviceId);
      setSelectedAudioInputDevice(deviceLabel);
      setItem(STORAGE_KEYS.AUDIO_INPUT, deviceId);
    },
    [audioTrack, setSelectedAudioInputDevice],
  );
  const onSelectAudioOutputDevice = useCallback(
    device => {
      setSelectedAudioOutputDevice(device.label);
      setItem(STORAGE_KEYS.AUDIO_OUTPUT, device.id);
    },
    [setSelectedAudioOutputDevice],
  );
  const onSelectVideoDevice = useCallback(
    (deviceLabel, deviceId) => {
      videoTrack.switchVideoDevice(deviceId);
      setItem(STORAGE_KEYS.VIDEO_INPUT, deviceId);
    },
    [videoTrack],
  );

  useEffect(() => {
    getAudioInputDevices().then(inputDevices => {
      const savedAudioInput = getItem(STORAGE_KEYS.AUDIO_INPUT);
      if (savedAudioInput) {
        removeItem(STORAGE_KEYS.AUDIO_INPUT);
      }
      if (inputDevices?.length > 0) {
        onSelectAudioInputDevice(inputDevices[0].label, inputDevices[0].id);
      }

      // Commented for now, TODO: check why it's causing iPhone mic bug
      // if (savedAudioInput) {
      //   const isConnected = inputDevices.find(device => device.id === savedAudioInput);
      //   if (isConnected) {
      //     onSelectAudioInputDevice(isConnected.label, isConnected.id);
      //     audioTrack.switchAudioDevice(savedAudioInput);
      //   } else {
      //     removeItem(STORAGE_KEYS.AUDIO_INPUT);
      //   }
      // } else {
      //   onSelectAudioInputDevice(inputDevices[0].label, inputDevices[0].id);
      // }
    });
    getAudioOutputDevices().then(outputDevices => {
      const savedAudioOutput = getItem(STORAGE_KEYS.AUDIO_OUTPUT);
      if (savedAudioOutput) {
        const connectedDevice = outputDevices.find(device => device.id === savedAudioOutput);
        if (connectedDevice) {
          setSelectedAudioOutputDevice(connectedDevice.label);
        } else {
          removeItem(STORAGE_KEYS.AUDIO_OUTPUT);
        }
      } else {
        setSelectedAudioOutputDevice(outputDevices[0]?.label);
      }
    });
    getVideoDevices().then(videoDevices => {
      const savedVideoDevice = getItem(STORAGE_KEYS.VIDEO_INPUT);
      if (savedVideoDevice) {
        const isConnected = videoDevices.find(device => device.id === savedVideoDevice);
        if (isConnected) {
          videoTrack.switchVideoDevice(savedVideoDevice);
        } else {
          removeItem(STORAGE_KEYS.VIDEO_INPUT);
        }
      }
    });
  }, []);

  const startRecordOnClick = () => {
    startRecording(roomId, contributionId).then(res => {
      dispatch(setVideoChatRecordingStatus(res));
    });
  };

  const stopRecordOnClick = () => {
    stopRecording(roomId, contributionId).then(res => {
      dispatch(setVideoChatRecordingStatus(res));
    });
  };

  return (
    <ParticipantsAudioVolumeProvider participants={participants}>
      <div className={classNames(className, `${videoChatClassname}`)}>
        <div className={`${videoChatClassname}__content`}>
          <VideoChatView
            type={viewModeType}
            localParticipant={localParticipant}
            participants={participants}
            dominantSpeakerParticipant={dominantSpeakerParticipant}
            screenShareParticipants={screenShareParticipants}
            isTest={isTest}
            videoTrack={videoTrack}
            audioTrack={audioTrack}
            screenTrack={screenTrack}
            selectedAudioOutputDevice={selectedAudioOutputDevice}
          />
        </div>
        <div className={`${videoChatClassname}__footer ${videoChatClassname}-footer`}>
          <div className={`${videoChatClassname}-footer-toolbar`}>
            <Microphone
              selectedAudioInputDevice={selectedAudioInputDevice}
              onSelectedAudioInputDeviceChange={onSelectAudioInputDevice}
              selectedAudioOutputDevice={selectedAudioOutputDevice}
              onSelectedAudioOutputDeviceChange={onSelectAudioOutputDevice}
              enabled={audioTrack.isLocalAudioEnabled}
              onEnabledChange={audioTrack.setIsLocalAudioEnabled}
            />
            {mobileView && <EndCall handleClick={onLeaveChat} enabled />}
            <Camera
              selectedDevice={videoTrack.videoDeviceLabel}
              onSelectedDeviceChange={onSelectVideoDevice}
              enabled={videoTrack.isLocalVideoEnabled}
              onEnabledChange={videoTrack.setIsLocalVideoEnabled}
            />
            {!mobileView && <ViewMode type={viewModeType} onTypeChange={setViewModeType} />}

            {isOwner && !recordingStatus && <StartRecord startRecordOnClick={startRecordOnClick} />}
            {isOwner && recordingStatus && <StopRecord stopRecordOnClick={stopRecordOnClick} />}
          </div>
          <div className={`${videoChatClassname}-footer-toolbar`}>
            <Participants
              enabled={sidePanelType === SidePanelType.participants}
              onEnabledChange={enabled => onSidePanelEnabledChange(SidePanelType.participants, enabled)}
            />
            {mobileView && <ViewMode type={viewModeType} onTypeChange={setViewModeType} />}
            {!mobileView && (
              <ShareScreen
                enabled={screenTrack.isLocalScreenEnabled}
                onEnabledChange={screenTrack.setIsLocalScreenEnabled}
              />
            )}
            {(isOneToOne || !isGroupChatHidden) && (
              <Chat
                enabled={sidePanelType === SidePanelType.chat}
                onEnabledChange={enabled => {
                  if (!isEmpty(contribution)) {
                    if (contribution.isGroupChatHidden === false) {
                      onSidePanelEnabledChange(SidePanelType.chat, enabled);
                    } else {
                      setModal(true);
                    }
                  } else {
                    onSidePanelEnabledChange(SidePanelType.chat, enabled);
                  }
                }}
              />
            )}
            {openModal && (
              <>
                <Modal
                  CrossIconHide={true}
                  isOpen={openModal}
                  widthRequiredIs
                  handleOnCancel
                  disableConfirm
                  onCancel={() => {
                    setModal(false);
                  }}
                >
                  <p style={{ fontSize: '25px' }}>Group chat has been disabled for this group.</p>
                </Modal>
              </>
            )}
            {!isGuestVideoCall && (
              <Notes
                enabled={sidePanelType === SidePanelType.notes}
                onEnabledChange={enabled => onSidePanelEnabledChange(SidePanelType.notes, enabled)}
              />
            )}
          </div>
          {!mobileView && (
            <p className={`${videoChatClassname}-footer__leave-session`}>
              <EndCall handleClick={onLeaveChat} enabled />
            </p>
          )}
        </div>
        {sidePanelType === SidePanelType.participants && (
          <ParticipantsPanel
            className={`${videoChatClassname}__side-panel`}
            localParticipant={localParticipant}
            participants={participants}
            dominantSpeakerParticipant={dominantSpeakerParticipant}
            isTest={isTest}
            videoTrack={videoTrack}
            audioTrack={audioTrack}
            screenTrack={screenTrack}
          />
        )}
        {sidePanelType === SidePanelType.chat && (
          <ChatPanel videoCallChat={true} className={`${videoChatClassname}__side-panel`} chatId={chatId} />
        )}
        {sidePanelType === SidePanelType.notes && (
          <NotesPanel
            className={`${videoChatClassname}__side-panel`}
            onEnabledChange={enabled => onSidePanelEnabledChange(SidePanelType.notes, enabled)}
          />
        )}
      </div>
    </ParticipantsAudioVolumeProvider>
  );
};

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
});

VideoChartContent.propTypes = {
  className: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  room: PropTypes.shape({
    name: PropTypes.string,
    localParticipant: participantPropType,
  }).isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
  screenShareParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  onLeaveChat: PropTypes.func.isRequired,
  screenTrack: PropTypes.shape({
    localScreenTrack: PropTypes.shape({
      attach: PropTypes.func,
      detach: PropTypes.func,
    }),
    isLocalScreenEnabled: PropTypes.bool,
    setIsLocalScreenEnabled: PropTypes.func,
  }).isRequired,
  videoTrack: PropTypes.shape({
    isLocalVideoEnabled: PropTypes.bool,
    setIsLocalVideoEnabled: PropTypes.func,
    videoDeviceLabel: PropTypes.string,
    switchVideoDevice: PropTypes.func,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
    setIsLocalAudioEnabled: PropTypes.func,
    audioDeviceLabel: PropTypes.string,
    switchAudioDevice: PropTypes.func,
    audioMediaStreamTrack: PropTypes.shape({}),
    localAudioTrack: PropTypes.shape({}),
  }).isRequired,
  isTest: PropTypes.bool,
};

VideoChartContent.defaultProps = {
  dominantSpeakerParticipant: null,
  isTest: false,
};
