import { makeStyles } from '@material-ui/core';
import { colors } from 'utils/styles';
const stylesSessionTime = theme => ({
  datePickerWrapper: {
    maxWidth: '100%',
    flexBasis: '100%',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
    },
    marginTop: 35,
  },
  deleteButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      justifyContent: 'flex-end',
      marginBottom: 12,
      maxWidth: '100%',
    },
  },
  gridhandle: {
    [theme.breakpoints.down('1200')]: {
      display: 'block',
    },
  },
  durationWrapper: {
    marginRight: 10,
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoTitle: {
    fontFamily: 'Avenir',
    fontWeight: 800,
    fontSize: 16,
    // marginBottom: 4,
    color: '#000000DE',
  },
  infoValue: {
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000DE',
  },
  infoWrapper: {
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  statusWrapper: {
    marginRight: 10,
  },
  videoWrapper: {
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      flexGrow: 1,
      maxWidth: '100%',
      order: 1,
    },
  },
  dateAvailableWrapper: {
    width: '100%', // Adjust width as needed
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  dateAvailableLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  radioWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 0px 8px 0px',
  },
  specificDateLabel: {
    width: '122px',
    height: '24px',
    gap: '4px',
  },
  numberOfDaysLabel: {
    width: '438px',
    height: '43px',
    gap: '4px',
  },
  noofDaysPicker: {
    width: '50px',
    height: '43px',
    gap: '20px',
  },
  datePicker: {
    marginRight: -22,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  datePickerIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  noofDaysRadioWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  noofdaysError: {
    color: `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
    marginBottom: '15px',
  },
  dateAvailableError: {
    color: `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
    margin: '10px 0px 5px 0px',
  },
});

export const useStyles = makeStyles(stylesSessionTime);
