import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Formik, Field, Form, yupToFormErrors } from 'formik';
import * as userActions from 'actions/user';
import { useShallowEqualSelector, useHeader, useRouter, useAccount, useHttp } from 'hooks';
import { DashboardPage } from 'pages';
import { UserRoles } from 'helpers/constants';
import { parseQuery } from 'utils/parseData';
import Button from 'components/FormUI/Button';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import MainContainer from './MainContainer';
import * as Yup from 'yup';
import { DialogActions, Box, TextField } from '@material-ui/core';
import { EN_MESSAGES } from '../../constants';
import * as R from 'ramda';

const titles = {
  [UserRoles.cohealer]: 'Dashboard',
  [UserRoles.cohealerAssistant]: 'Dashboard',
  [UserRoles.client]: 'My Journey',
  [UserRoles.admin]: 'Admin',
  [UserRoles.superAdmin]: 'Admin',
};
const initialValues = {
  Password: '',
};
function DashboardContainer({ userId, getUserProfile, match: { path } }) {
  const { location } = useRouter();
  const { user, isLoggedIn } = useAccount();

  const { request } = useHttp();
  const currentRole = useShallowEqualSelector(({ account }) => account?.currentRole);
  const isCoach = currentRole === UserRoles.cohealer;
  const [isOpenPasswordPopUp, setIsOpenPasswordPopUp] = useState(
    user?.isPasswordNotCreated || user?.account?.isPasswordNotCreated,
  );

  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false);
  const [partnerContributionName, setPartnerContributionName] = useState(null);
  const dispatch = useDispatch();
  useHeader(titles[currentRole]);

  const editHandler = useCallback(
    values => {
      const { Password } = values;
      request(`/Account/UpdatePasswordandTimezone`, 'POST', {
        password: Password,
        // id: user.accountId,
        email: user.email,
      })
        .then(() => {
          request(`/Account/${user.accountId}`).then(R.compose(dispatch, userActions.getAccount));
          setIsOpenPasswordPopUp(false);
        })
        .catch(() => {});
    },
    [user],
  );
  useEffect(() => {
    if (isCoach === false) {
      if (user?.isPasswordNotCreated === true) {
        setIsOpenPasswordPopUp(true);
      }
    }
  }, [user?.isPasswordNotCreated]);
  useEffect(() => {
    const parsedQuery = parseQuery(location.search);
    if (parsedQuery?.refreshUser) {
      getUserProfile(userId);
      userActions.getProfile(userId, dispatch);
    }
    if (parsedQuery?.success) {
      const isSuccessfully = parsedQuery.success === 'true';
      setIsAddedSuccessfully(isSuccessfully);
      setPartnerContributionName(parsedQuery.contributionName);
      setIsShowPartnerModal(true);
    }
  }, [location.search, getUserProfile, userId]);

  const getShowLoginPasswordPopup = () => {
    if (user?.isSecondaryAccount || user?.isSecondaryExisting) {
      return false;
    }
    return user?.isPasswordNotCreated === true || user?.account?.isPasswordNotCreated === true;
  };
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
  };

  return (
    <>
      <MainContainer>
        <Switch>
          <Route path={`${path}`} component={DashboardPage} />
          <Redirect to={`${path}`} />
        </Switch>
        {/* Temporarily disable this modal */}
        {/* <Can roleName="Client" yes={() => <ClientModalsPreferences />} /> */}
      </MainContainer>

      {isShowPartnerModal && (
        <Modal
          isOpen={isShowPartnerModal}
          onSubmit={handleClosePartnerMadal}
          title={isAddedSuccessfully ? 'You were added successfully' : 'Adding failed'}
          hiddenCancel
        >
          {isAddedSuccessfully ? (
            <LabelText>{`You were added to ${partnerContributionName}`}</LabelText>
          ) : (
            <LabelText>Adding you as a partner coach failed</LabelText>
          )}
        </Modal>
      )}
      {getShowLoginPasswordPopup() && isOpenPasswordPopUp && isCoach === false && (
        <Modal
          title="Create a Cohere Login Password"
          isOpen={isOpenPasswordPopUp}
          submitTitle="Close"
          hiddenCancel
          disableFooter
          onCancel={() => {
            setIsOpenPasswordPopUp(false);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setIsOpenPasswordPopUp(false);
          }}
          style={{ zIndex: '1200' }}
        >
          <p style={{ color: 'black' }}>
            Finish creating your password for account to get access to all the content shared with you.
          </p>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Password: Yup.string()
                .strict(true)
                .trim('Please remove spaces after your password')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
                // .required(<Translate id="validation.required" />),
                .required(EN_MESSAGES.validation.required),
            })}
            onSubmit={editHandler}
          >
            {({ values, errors, handleBlur, touched, handleChange }) => (
              <Form className="passowrd-popup-form">
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ width: '50%' }}>
                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      margin="normal"
                      variant="outlined"
                      name="Password"
                      type="password"
                      fullWidth
                      placeholder="Create a password"
                      PostalCode={values.Password}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                      }}
                      error={touched.Password && Boolean(errors.Password)}
                      helperText={errors.Password}
                    />
                  </span>
                </Box>
                <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                  <DialogActions>
                    <Button
                      style={{
                        height: '50px',
                        width: '130px',
                      }}
                      type="submit"
                      //   disabled={!isStripeSupportedCuntrySelected(values.CountryId) || loading}
                    >
                      Save
                      {/* {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save'} */}
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
}

DashboardContainer.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

DashboardContainer.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(DashboardContainer);
