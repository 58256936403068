import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';
import { NAVIGATION_ACTIONS } from 'actions/Navigation';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { setItem, removeItem, clearStorage } from 'services/localStorage.service';
import * as userService from 'services/user.service';
import * as userActions from 'actions/user';
// import { authError } from "utils/request";
import {
  loginUser,
  restorePassword,
  newPassword,
  registerUser,
  continueRegisterUser,
  editUserProfile,
  getUserProfile,
  getOtp,
} from 'services/user.service';
import * as accountPreferences from 'services/accountPreferences.service';
import { getRolesFromToken, getCurrentRole } from 'utils/auth';
import moment from 'moment';
import { openInNewWindow, redirectTo } from 'services/links';
import { isNil } from 'ramda';
import { ROUTES } from '../constants.js';

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const SAME_COACH_JOIN = 'SAME_COACH_JOIN';
export const LOG_OUT = 'LOG_OUT';
export const TEST_LOGIN = 'TEST_LOGIN';

export const RESTORE_PASS_REQUEST = 'RESTORE_PASS_REQUEST';
export const RESTORE_PASS_SUCCESS = 'RESTORE_PASS_SUCCESS';
export const RESTORE_PASS_FAILURE = 'RESTORE_PASS_FAILURE';

export const OTP_REQUEST = 'RESTORE_PASS_REQUEST';
export const OTP_SUCCESS = 'RESTORE_PASS_SUCCESS';
export const OTP_FAILURE = 'RESTORE_PASS_FAILURE';

export const NEW_PASS_REQUEST = 'NEW_PASS_REQUEST';
export const NEW_PASS_SUCCESS = 'NEW_PASS_SUCCESS';
export const NEW_PASS_FAILURE = 'NEW_PASS_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const PURCHASE_SUCCESSFUL = 'PURCHASE_SUCCESSFUL';

export const CONTINUE_REGISTER_REQUEST = 'CONTINUE_REGISTER_REQUEST';
export const CONTINUE_REGISTER_SUCCESS = 'CONTINUE_REGISTER_SUCCESS';
export const CONTINUE_REGISTER_FAILURE = 'CONTINUE_REGISTER_FAILURE';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const CHANGE_CURRENT_ROLE = 'CHANGE_CURRENT_ROLE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';
export const DISABLE_SHOW_CLIENT_MODALS = 'DISABLE_SHOW_CLIENT_MODALS';
export const SET_PROFILE_AVATAR = 'SET_PROFILE_AVATAR';

export const GET_ACCOUNT = 'GET_ACCOUNT';

export const HIDE_TEST_VIDEO_BUTTON = 'HIDE_TEST_VIDEO_BUTTON';

export const GETTING_STARTED_TOGGLE = 'GETTING_STARTED_TOGGLE';

export const SET_GUEST_CONTRIBUTION_ID = 'SET_GUEST_CONTRIBUTION_ID';

export const setGuestContributionId = contributionId => ({
  type: SET_GUEST_CONTRIBUTION_ID,
  payload: contributionId,
});

export const getStartedToggle = value => ({
  type: GETTING_STARTED_TOGGLE,
  payload: value,
});

export const sameAccountPopUp = value => ({
  type: SAME_COACH_JOIN,
  payload: value,
});

export const disableShowCLientModals = () => ({
  type: DISABLE_SHOW_CLIENT_MODALS,
});

export const logOut = () => {
  removeItem('token');
  removeItem('accountId');
  clearStorage();
  return { type: LOG_OUT };
};

const handleAuthenticate = actionType => ({ account, user, oAuthToken, userInfoMissing }) => {
  const roles = getRolesFromToken(oAuthToken || account.oAuthToken);

  setItem('token', oAuthToken || account.oAuthToken);
  setItem('accountId', account.id);
  return accountPreferences.getAccountPreferences().then(({ userView }) => {
    const currentRole = userView || getCurrentRole(roles);
    if (!userView) {
      accountPreferences.setAccountPreferences({ userView: currentRole });
    }
    return {
      type: actionType,
      payload: {
        account,
        user: {
          ...user,
          accountId: account.id,
        },
        userInfoMissing,
        roles,
        currentRole,
      },
    };
  });
};
const checkIsHasUser = data => {
  if (isNil(data.payload.user?.id)) {
    return redirectTo('/signup/client/continue');
  }
  return data;
};

const loginRequest = () => ({ type: LOG_IN_REQUEST });
const loginSuccess = handleAuthenticate(LOG_IN_SUCCESS);
const loginFailure = err => ({ type: LOG_IN_FAILURE, payload: err });

export const login = (
  email,
  password,
  isRoleAdding,
  type = null,
  phoneNo,
  joinSignIn,
  state = null,
  contributionUserId = null,
  otp,
  isSecondaryExisting = false,
) => dispatch => {
  dispatch(loginRequest());
  return loginUser(email, password, phoneNo, otp, isSecondaryExisting)
    .then(loginSuccess)
    .then(action => {
      if (action.payload.userInfoMissing === true) {
        if (action.payload.currentRole.includes('Client')) {
          return redirectTo(ROUTES.ADD_ROLE_CLIENT);
        } else {
          return redirectTo(ROUTES.ADD_ROLE);
        }
      } else {
        const { coachLoggedTheFirstTime, coachLoggedTheSecondTime } = action?.payload?.account?.coachLoginInfo || {};
        const isCohealer = action?.payload?.currentRole === UserRoles.cohealer;
        if (isCohealer) {
          userService.switchFromCoachToClient({});
          accountPreferences.setAccountPreferences({ userView: UserRoles.client });
          return dispatch({ ...action, payload: { ...action.payload, currentRole: UserRoles.client } });
        }
        if (isCohealer && coachLoggedTheFirstTime && !coachLoggedTheSecondTime) {
          dispatch(NAVIGATION_ACTIONS.setIsTransitionedToCoachView(true));
        }
        if (isCohealer && contributionUserId === action?.payload?.user?.id) {
          dispatch(sameAccountPopUp(true));
        }
        return dispatch(action);
      }
    })
    .then(checkIsHasUser)
    .then(({ payload }) => {
      const { account } = payload;
      if (type) {
        if (type === SIGNUP_TYPES.TYPE_A) return redirectTo(ROUTES.ACCOUNT_COACH_BILLING);
        else if (type === SIGNUP_TYPES.TYPE_B) return redirectTo(ROUTES.ACCOUNT_BILLING_EXTENDED);
      } else {
        if (account.signupType === SIGNUP_TYPES.TYPE_A) return redirectTo(ROUTES.ACCOUNT_COACH_BILLING);
        else if (account.signupType === SIGNUP_TYPES.TYPE_B) return redirectTo(ROUTES.ACCOUNT_BILLING_EXTENDED);
        else if (joinSignIn) {
          return payload;
        } else if (state?.path) {
          redirectTo(state?.path);
        } else return isRoleAdding && redirectTo(ROUTES.DASHBOARD);
      }
    })
    .catch(pipe(get('response'), get('data'), loginFailure, dispatch));
};

export const loginUserSimple = (
  email,
  password,
  isRoleAdding,
  type = null,
  phoneNo,
  joinSignIn,
  state = null,
  contributionUserId = null,
  otp,
  isSecondaryExisting = false,
  onboarding = false,
) => dispatch => {
  dispatch(loginRequest());
  return loginUser(email, password, phoneNo, otp, isSecondaryExisting)
    .then(loginSuccess)
    .then(action => {
      if (action.payload.userInfoMissing === true) {
        if (action.payload.currentRole.includes('Client')) {
          return redirectTo(ROUTES.ADD_ROLE_CLIENT);
        } else {
          return redirectTo(ROUTES.ADD_ROLE);
        }
      } else if (onboarding === true) {
        const { coachLoggedTheFirstTime, coachLoggedTheSecondTime } = action?.payload?.account?.coachLoginInfo || {};
        const isCohealer = true;

        if (isCohealer) {
          dispatch(NAVIGATION_ACTIONS.setIsTransitionedToCoachView(true));
        }
        return dispatch({
          ...action,
          payload: {
            ...action.payload,
            currentRole: 'Cohealer',
          },
        });
      } else {
        const { coachLoggedTheFirstTime, coachLoggedTheSecondTime } = action?.payload?.account?.coachLoginInfo || {};
        const isCohealer = action?.payload?.currentRole === UserRoles.cohealer;

        if (isCohealer && coachLoggedTheFirstTime && !coachLoggedTheSecondTime) {
          dispatch(NAVIGATION_ACTIONS.setIsTransitionedToCoachView(true));
        }
        if (isCohealer && contributionUserId === action?.payload?.user?.id) {
          dispatch(sameAccountPopUp(true));
        }
        return dispatch(action);
      }
    })
    .then(checkIsHasUser)
    .then(({ payload }) => {
      const { account } = payload;
      if (onboarding === true) {
        return redirectTo(ROUTES.DASHBOARD);
      } else {
        if (type) {
          if (type === SIGNUP_TYPES.TYPE_A) return redirectTo(ROUTES.ACCOUNT_COACH_BILLING);
          else if (type === SIGNUP_TYPES.TYPE_B) return redirectTo(ROUTES.ACCOUNT_BILLING_EXTENDED);
        } else {
          if (account.signupType === SIGNUP_TYPES.TYPE_A) return redirectTo(ROUTES.ACCOUNT_COACH_BILLING);
          else if (account.signupType === SIGNUP_TYPES.TYPE_B) return redirectTo(ROUTES.ACCOUNT_BILLING_EXTENDED);
          else if (joinSignIn) {
            return payload;
          } else if (state?.path) {
            redirectTo(state?.path);
          } else return isRoleAdding && redirectTo(ROUTES.DASHBOARD);
        }
      }
    })
    .catch(pipe(get('response'), get('data'), loginFailure, dispatch));
};
const restorePassRequest = () => ({ type: RESTORE_PASS_REQUEST });
const restorePassSuccess = payload => ({
  type: RESTORE_PASS_SUCCESS,
  message: payload,
});
const restorePassFailure = err => ({
  type: RESTORE_PASS_FAILURE,
  payload: err,
});

export const restorePass = email => dispatch => {
  dispatch(restorePassRequest());

  return restorePassword(email)
    .then(restorePassSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), restorePassFailure, dispatch));
};

const newPassRequest = () => ({ type: NEW_PASS_REQUEST });
const newPassSuccess = () => ({
  type: NEW_PASS_SUCCESS,
});
const newPassFailure = err => ({
  type: NEW_PASS_FAILURE,
  payload: err,
});

export const newPass = data => dispatch => {
  dispatch(newPassRequest());

  return newPassword(data)
    .then(newPassSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), newPassFailure, dispatch));
};

const otpRequest = () => ({ type: OTP_REQUEST });
const otpSuccess = () => ({
  type: OTP_SUCCESS,
});
const otpFailure = err => ({
  type: OTP_FAILURE,
  payload: err,
});

export const getOTP = data => dispatch => {
  dispatch(otpRequest());

  return getOtp(data)
    .then(otpSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), otpFailure, dispatch));
};

/* registration 1st step */
const registerRequest = () => ({ type: REGISTER_REQUEST });
const registerSuccess = (account, type) => {
  setItem('token', account.oAuthToken);

  const acc = {
    ...account,
    signupType: type,
    accountId: account.id,
    id: null,
  };

  return { type: REGISTER_SUCCESS, payload: acc };
};
const registerFailure = err => ({
  type: REGISTER_FAILURE,
  payload: err,
});

export const register = (email, password, inviteCode, onSuccess, userView, type, phoneNo) => dispatch => {
  dispatch(registerRequest());
  return registerUser(email, password, inviteCode, userView, type, null, phoneNo)
    .then(account => registerSuccess(account, type))
    .then(dispatch)
    .then(onSuccess)
    .catch(pipe(get('response'), get('data'), registerFailure, dispatch));
};
/* end registration 1st step */

/* registration 2st step */
const continueRegisterRequest = () => ({
  type: CONTINUE_REGISTER_REQUEST,
});
const continueRegisterSuccess = handleAuthenticate(CONTINUE_REGISTER_SUCCESS);
const continueRegisterFailure = err => ({
  type: CONTINUE_REGISTER_FAILURE,
  payload: err,
});

export const continueRegister = ({ fields, userView }) => dispatch => {
  dispatch(continueRegisterRequest());
  accountPreferences.setAccountPreferences({ userView });
  return continueRegisterUser(fields)
    .then(continueRegisterSuccess)
    .then(dispatch)
    .then(res => {
      if (res.payload.account.invitedBy) {
        window.location.assign('https://www.cohere.live/learnmore');
      } else {
        redirectTo('/dashboard');
      }
    })
    .catch(pipe(get('response'), get('data'), continueRegisterFailure, dispatch));
};

export const completeRegister = ({
  Email,
  Password,
  BirthDate,
  FirstName,
  LastName,
  TimeZoneId,
  userView,
  PhoneNo,
  countryId,
  stateCode,
  IsSecondaryAccount,
}) => dispatch => {
  dispatch(registerRequest());

  const onSuccess = accountId => {
    dispatch(continueRegisterRequest());
    const fields = {
      FirstName,
      HasAgreedToTerms: true,
      LastName,
      accountId,
      BirthDate: moment.utc(BirthDate).format(),
      TimeZoneId,
      PhoneNo,
      countryId,
      stateCode,
      IsSecondaryAccount,
    };
    accountPreferences.setAccountPreferences({ userView });
    return continueRegisterUser(fields)
      .then(continueRegisterSuccess)
      .then(dispatch)
      .catch(pipe(get('response'), get('data'), continueRegisterFailure, dispatch));
  };
  return registerUser(
    Email,
    Password,
    null,
    userView,
    null,
    PhoneNo,
    TimeZoneId,
    countryId,
    stateCode,
    IsSecondaryAccount,
  )
    .then(account => registerSuccess(account))
    .then(action => {
      dispatch(action);
      return action.payload.accountId;
    })
    .then(onSuccess)
    .catch(pipe(get('response'), get('data'), registerFailure, dispatch));
};
/* end registration 1st step */

/* edit profile */
const editRequest = () => ({ type: EDIT_PROFILE_REQUEST });
const editSuccess = handleAuthenticate(EDIT_PROFILE_SUCCESS);
const editFailure = err => ({
  type: EDIT_PROFILE_FAILURE,
  payload: err,
});

export const editProfile = (id, fields) => dispatch => {
  dispatch(editRequest());

  return (
    editUserProfile(id, fields)
      .then(editSuccess)
      .then(dispatch)
      // .catch(authError(dispatch, logOut))
      .catch(pipe(get('response'), get('data'), editFailure, dispatch))
  );
};
/* end edit profile */

/* role changing */
export const roleChange = role => ({
  type: CHANGE_CURRENT_ROLE,
  payload: role,
});
/* end role changing */

export const purchaseSuccessful = type => ({
  type: PURCHASE_SUCCESSFUL,
  payload: type,
});

/* clear errors */
export const clearUserErrors = () => ({ type: CLEAR_USER_ERRORS });
/* end edit profile */

const getUserRequest = () => ({ type: GET_USER_REQUEST });
export const getUserSuccess = user => ({ type: GET_USER_SUCCESS, payload: user });
const getUserFailure = err => ({
  type: GET_USER_FAILURE,
  payload: err,
});
export const getProfile = id => dispatch => {
  dispatch(getUserRequest());

  return getUserProfile(id)
    .then(getUserSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), getUserFailure, dispatch));
};

export const setProfileAvatar = createAction(SET_PROFILE_AVATAR);

export const getAccount = createAction(GET_ACCOUNT);

export const hideTestButton = () => ({ type: HIDE_TEST_VIDEO_BUTTON });
