import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHttp } from 'hooks';
import Loader from 'components/UI/Loader';
import useSessionNotes from 'hooks/useSessionNotes';
import NotesPanel from './NotesPanel';

const SideBarNotePanel = ({
  noteProps: { title, classId, subClassId, contributionId, isPrerecorded },
  setOpenNotes,
}) => {
  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    notesMeta,
    isNoteExist,
  } = useSessionNotes({ contributionId, classId, subClassId, isPrerecorded });
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { request } = useHttp();
  useEffect(() => {
    setLoadedFirstTime(true);
    if (loadedFirstTime) {
      handleAutoSubmit();
    }
  }, [editorDataUpdated]);

  const handleSubmit = useCallback(() => {
    setLoadingNotes(true);
    if (isPrerecorded) {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          });

      noteRequest
        .then(() => setOpenNotes(false))
        .catch(error => {
          setOpenNotes(false);
        })
        .finally(() => {
          setLoadingNotes(false);
        });
    } else {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
          });

      noteRequest
        .then(() => setOpenNotes(false))
        .catch(error => {
          setOpenNotes(false);
        })
        .finally(() => {
          setLoadingNotes(false);
        });
    }
  }, [request, contributionId, classId, title, notesMeta, editorDataUpdated]);

  const handleAutoSubmit = useCallback(() => {
    if (isPrerecorded) {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          });
      setSaving(true);
      noteRequest
        .then(() => {
          setOpenNotes(false);
          setSaving(false);
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 300);
        })
        .catch(error => {
          setOpenNotes(false);
          setSaving(false);
        })
        .finally(() => {
          setLoadingNotes(false);
        });
    } else {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
          });
      setSaving(true);
      noteRequest
        .then(() => {
          setSaving(false);
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 300);
        })
        .catch(error => {
          setSaving(false);
        })
        .finally(() => {});
    }
  }, [request, contributionId, classId, title, notesMeta, editorDataUpdated]);

  return (
    <>
      {loadingNotes && <Loader />}
      {showNotes && (
        <NotesPanel
          editorData={editorDataUpdated}
          loading={loadingNotes}
          setEditorDataUpdated={setEditorDataUpdated}
          setNotesId={() => {}}
          setLoading={setLoadingNotes}
          setOpenNotes={setOpenNotes}
          applyTheming
          onSave={() => {
            handleSubmit();
          }}
          isNoteExist={isNoteExist}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
          saved={saved}
          saving={saving}
        />
      )}
    </>
  );
};

SideBarNotePanel.propTypes = {
  noteProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    subClassId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    isPrerecorded: PropTypes.string,
  }).isRequired,
  setOpenNotes: PropTypes.func.isRequired,
};

export default SideBarNotePanel;
