import React from 'react';
import PropTypes from 'prop-types';
import { useAccount } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import Card from 'components/Card/Card';

import './CardSales.scss';

const CardSales = ({ card: { contributionType, netIncomeAmount, moreLink, purchaseIncomeList, type } }) => {
  const { user } = useAccount();
  const netIncome = purchaseIncomeList?.map(plist => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span className="text_bold incomed-amount">{`${
          plist.symbol + formatMoney(plist.netIncomeAmount) + ' ' + plist.currency
        } `}</span>
        {/* {user.isStandard &&  <span className="text_bold incomed-amount" style={{ paddingLeft: '30px' }}>{`${
          plist.symbol + formatMoney(plist.netIncomeAmountWithTaxIncluded) + ' ' + plist.currency
        } `}</span>} */}
      </div>
    );
  });
  const revenueCardsList = purchaseIncomeList.filter(item => item.netIncomeAmount > 0);
  return contributionType === 'Net Revenue' ? (
    <Card title={contributionType === 'WorkShop' ? 'Workshop' : contributionType} moreLink={moreLink} isSales>
      <div className="side" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="side">{netIncome}</div>
      </div>
    </Card>
  ) : (
    revenueCardsList.length > 0 && (
      <Card title={contributionType === 'WorkShop' ? 'Workshop' : contributionType} moreLink={moreLink} isSales>
        <div className="side" style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span className="text_bold incomed-amount" style={{ paddingRight: '20px' }}>
        Simple
        </span>
        {user.isStandard && <span className="text_bold incomed-amount" style={{ paddingLeft: '30px' }}>
        Advance
        </span>}
        </div> */}
          <div className="side">{netIncome}</div>
        </div>
      </Card>
    )
  );
};
CardSales.propTypes = {
  card: PropTypes.shape().isRequired,
};

export default CardSales;
