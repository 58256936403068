import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from 'ckeditor4-react';
import './NotesModal.scss';
import { convertToPlainText } from 'utils/utils';
import styled, { css } from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { useDebounce } from 'pages/CreateCampaign/Components/helper/helper';
import { useDebounceEffect } from 'pages/CreateContribution/components/Cropper/useDebounceEffect';
import { setCloseDisabled, setShowSavingAnimation, SET_SHOW_SAVING_ANIMATION } from 'actions/contributions';

const DEFAULT_CHARACTER_LIMIT = 100000;

const StyledEditorWrapper = styled.div`
  .cke {
    ${({ mainBorder }) =>
      mainBorder
        ? css`
            border: ${mainBorder};
          `
        : css``}
  }
  .cke_chrome {
    border-radius: 0 0 4px 4px;
    /* border-bottom: none; */
  }
  .cke_inner {
    .cke_contents {
      border-radius: 0 0 4px 4px;
    }
    .cke_bottom {
      display: none;
    }
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_top {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_reset_all * {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }

  .cke_button_icon {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
    ${({ isDarkThemeEnabled }) =>
      isDarkThemeEnabled &&
      css`
        filter: invert(100%);
      `}
  }

  .cke_button {
    &:hover {
      .cke_button_icon {
        ${({ isDarkThemeEnabled }) =>
          isDarkThemeEnabled &&
          css`
            filter: none;
          `}
      }
    }
  }

  .cke_combo_off a.cke_combo_button:hover {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
  .cke_combo_arrow {
    border-top-color: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit'};
  }
  .cke_editable {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }
  .cke_bottom {
    /* visibility: hidden; */
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
`;

const NotesEditor = ({
  editorData,
  setEditorDataUpdated,
  setEditor,
  characterLimit,
  characterCount,
  setCharacterCount,
  onLoaded = () => {},
  contribution,
  mainBorder = null,
}) => {
  const [data, setData] = useState(editorData);
  const ref = useRef();
  const {
    themedColor: themedTextColor,
    newThemedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    newThemedCardColor,
  } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const colorToUse = determineColorToUse(contribution);
  const dispatch = useDispatch();
  useEffect(() => {
    const editorContent = convertToPlainText(editorData);
    setCharacterCount(editorContent.length);
    setData(editorData);
    dispatch(setShowSavingAnimation(false));
  }, [editorData]);

  const handleEditorChange = useDebounce(event => {
    const editorContent = convertToPlainText(event.editor.getData().trim());
    if (editorContent.length <= characterLimit) {
      const data = event.editor.getData();
      setEditorDataUpdated(data);
    }
    setCharacterCount(editorContent.length);
  }, 500);

  const onEditorReady = evt => {
    onLoaded(false);
    setEditor(evt.editor);
  };

  const toolbarConfig = useMemo(
    () => [
      { name: 'undo', items: ['Undo', 'Redo', 'SelectAll'] },
      { name: 'styles', items: ['Styles'] },
      { name: 'format', items: ['Format'] },
      { name: 'font', items: ['Font'] },
      { name: 'fontSize', items: ['FontSize'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      '/',
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'list', items: ['NumberedList', 'BulletedList'] },
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Code', 'Strike'],
      },
      { name: 'link', items: ['Link', 'Unlink'] },
    ],
    [],
  );

  return (
    <StyledEditorWrapper
      colorToUse={{
        ...colorToUse,
        themedTextColor,
        newThemedCardColor,
        newThemedTextColor,
        oldThemedCardBackgroundColor,
      }}
      isDarkThemeEnabled={isDarkThemeEnabled}
      mainBorder={mainBorder}
    >
      <CKEditor
        ref={ref}
        config={{
          allowedContent: true,
          height: '350px',
          dialog_noConfirmCancel: true,
          toolbar: toolbarConfig,
          filebrowserUploadUrl: '/',
          removeButtons: [],
          extraPlugins: [
            'font',
            'colorbutton',
            'justify',
            'colordialog',
            'uploadimage',
            'filebrowser',
            'image2',
            'selectall',
          ],
        }}
        onLoaded={onEditorReady}
        initData={data}
        onChange={e => {
          dispatch(setShowSavingAnimation(false));
          dispatch(setCloseDisabled(true));
          handleEditorChange(e);
        }}
        style={{ borderColor: characterCount > characterLimit && 'red' }}
      />
    </StyledEditorWrapper>
  );
};
NotesEditor.propTypes = {
  editorData: PropTypes.string.isRequired,
  setEditorDataUpdated: PropTypes.func.isRequired,
  setEditor: PropTypes.func.isRequired,
  characterLimit: PropTypes.number,
  characterCount: PropTypes.number.isRequired,
  setCharacterCount: PropTypes.func.isRequired,
  onLoaded: PropTypes.func,
  contribution: PropTypes.shape({}).isRequired,
};

NotesEditor.defaultProps = {
  characterLimit: DEFAULT_CHARACTER_LIMIT,
  onLoaded: () => {},
};

const mapStateToProps = ({ contributions }) => ({
  contribution: contributions.activeContribution,
});

export default connect(mapStateToProps, null)(NotesEditor);
