import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, Radio, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useHttp, useAccount } from 'hooks';
import { Button, IconButton, makeStyles, Tooltip, CircularProgress } from '@material-ui/core';
import { colors } from 'utils/styles';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as R from 'ramda';
import InfoIcon from '@material-ui/icons/Info';
import { FiFileText } from 'react-icons/fi';
import styled from 'styled-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const PrivacyPolicyContainer = styled.div`
  display: flex;
  max-width: 154px;
  align-items: center;
  gap: 8px;
  margin-right: -7px;
`;

const PrivacyPolicyName = styled.span`
  white-space: noWrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;

const Settings = ({ values, setFieldValue, editHandler }) => {
  const profilePageViewModel = (values && values.profilePageViewModel) || {};
  const { isMessagingEnabled, messageRedirection, isFooterEnabled, privacyPolicyFileName } = profilePageViewModel;
  const { user } = useAccount();
  const { request } = useHttp();
  const [changeEmail, setChangeEmail] = useState(false);
  const [showOtherUrl, setshowOtherUrl] = useState(false);
  const [redirectOption, setredirectOption] = useState(messageRedirection?.redirectionType || 'coherechat');
  const [otherLink, setOtherLink] = useState(messageRedirection?.externalLink);
  const [buttonText, setButtonText] = useState(messageRedirection?.buttonText || 'Message');
  const [emailValue, setEmailValue] = useState(messageRedirection?.email || user?.email);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showUrlError, setShowUrlError] = useState(false);
  const [validEmailAddress, setvalidEmailAddress] = useState(messageRedirection?.email || user?.email);
  const [validUrl, setvalidUrl] = useState(messageRedirection?.externalLink);
  const uploadFooterRef = useRef(null);

  const [uploading, setUploading] = useState(false);
  const MAX_FILE_SIZE_IN_BYTES = 5 * 1024 * 1024; // 5MB in bytes
  const isFilesSizeLimitOrLess = R.all(file => file.size <= MAX_FILE_SIZE_IN_BYTES);

  const useSelectStyle = makeStyles(() => ({
    themedTextField: {
      '& input': {
        padding: '0.5px 14px',
      },
    },
  }));
  const selectStyle = useSelectStyle();

  const handleUploadFile = useCallback(
    async ({ target: { files } }) => {
      if (isFilesSizeLimitOrLess(files)) {
        if (files.length === 0) return;
        setUploading(true);
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = async () => {
            const formData = new FormData();
            formData.append('file', file);
            try {
              const response = await request('/content/AddPublicFile', 'POST', formData, {
                'Content-Type': 'multipart/form-data',
              });
              if (response) {
                setFieldValue('profilePageViewModel', {
                  ...profilePageViewModel,
                  privacyPolicyUrl: response,
                  privacyPolicyFileName: files[0].name,
                });
              }
            } catch (error) {
              setUploading(false);
            } finally {
              setUploading(false);
              editHandler();
            }
          };
          reader.readAsDataURL(file);
        }
      }
    },
    [profilePageViewModel, privacyPolicyFileName],
  );

  return (
    <>
      <Grid container item style={{ borderBottom: 'none' }} className="branding-container profile-section">
        <Grid item xs={12}>
          <div className="heading">
            <p style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}>
              How Clients Contact You
            </p>
          </div>
          <div>
            <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>
              If enabled, a 'Message' button will appear on your website, allowing clients to contact you.
            </p>
          </div>
        </Grid>
        <Grid className="message-settings" item xs={12}>
          <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Messaging Button</p>
          <ToggleButtonGroup
            className="toggle-button"
            color="primary"
            value={isMessagingEnabled ? 'enable' : 'disable'}
            exclusive
            onChange={() =>
              setFieldValue('profilePageViewModel', {
                ...profilePageViewModel,
                isMessagingEnabled: !isMessagingEnabled,
              })
            }
          >
            <ToggleButton value="enable">Enable</ToggleButton>
            <ToggleButton value="disable">Disable</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {isMessagingEnabled && (
          <>
            <Grid item xs={12}>
              <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }} className="field-title">
                Button Text
              </p>
              <TextField
                name="FirstName"
                // className="input-field"
                className={selectStyle.themedTextField}
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                InputProps={{
                  style: { height: '36px', fontSize: '16px' },
                }}
                fullWidth
                value={buttonText}
                // error={Boolean(errors?.FirstName)}
                // helperText={errors?.FirstName}
                onChange={e => {
                  setButtonText(e.target.value);
                  setFieldValue('profilePageViewModel', {
                    ...profilePageViewModel,
                    messageRedirection: {
                      ...profilePageViewModel.messageRedirection,
                      buttonText: e.target.value,
                    },
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }} className="field-title">
                Redirect
              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  value="coherechat"
                  className="modal-radio-styling"
                  control={
                    <Radio
                      style={{ color: colors.darkOceanBlue }}
                      checked={redirectOption === 'coherechat'}
                      onChange={() => {
                        setredirectOption('coherechat');
                        setFieldValue('profilePageViewModel', {
                          ...profilePageViewModel,
                          messageRedirection: {
                            ...profilePageViewModel.messageRedirection,
                            redirectionType: 'coherechat',
                          },
                        });
                      }}
                    />
                  }
                  label={<span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Cohere Chat</span>}
                />
                <FormControlLabel
                  value="email"
                  className="modal-radio-styling"
                  control={
                    <Radio
                      style={{ color: colors.darkOceanBlue }}
                      checked={redirectOption === 'email'}
                      onChange={() => {
                        setredirectOption('email');
                        setChangeEmail(false);
                        setvalidUrl(otherLink);
                        setFieldValue('profilePageViewModel', {
                          ...profilePageViewModel,
                          messageRedirection: {
                            ...profilePageViewModel.messageRedirection,
                            redirectionType: 'email',
                            email: emailValue,
                            externalLink: null,
                          },
                        });
                      }}
                    />
                  }
                  label={<span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Email</span>}
                />
                {redirectOption === 'email' && (
                  <div>
                    {!changeEmail ? (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '16px', fontWeight: '800', fontFamily: 'Avenir', color: 'black' }}>
                          {emailValue}
                        </p>
                        <p
                          onClick={() => {
                            setChangeEmail(true);
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Avenir',
                            color: 'rgba(201, 179, 130, 1)',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          Edit
                        </p>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ width: '75%' }}>
                          <TextField
                            name="FirstName"
                            className="input-field"
                            id="outlined-basic"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={emailValue}
                            // error={Boolean(errors?.FirstName)}
                            // helperText={errors?.FirstName}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                // Navigate to the new page
                                setChangeEmail(false);
                              }
                            }}
                            onChange={e => {
                              const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
                              if (emailRegex.test(e.target.value)) {
                                setvalidEmailAddress(e.target.value);
                                setShowEmailError(false);
                              } else {
                                setShowEmailError(true);
                              }
                              setEmailValue(e.target.value);
                              setFieldValue('profilePageViewModel', {
                                ...profilePageViewModel,
                                messageRedirection: {
                                  ...profilePageViewModel.messageRedirection,
                                  redirectionType: 'email',
                                  email: e.target.value,
                                  externalLink: null,
                                },
                              });
                            }}
                            InputProps={{
                              style: { height: '36px', fontSize: '12px' },
                            }}
                          />
                          {showEmailError && (
                            <CommonErrorMessage color={'red'} align="left" message="Please enter valid email address" />
                          )}
                        </div>
                        <Button
                          disabled={showEmailError}
                          onClick={() => {
                            setChangeEmail(false);
                          }}
                          style={{
                            backgroundColor: showEmailError ? '#e7e7e7' : 'rgba(201, 179, 130, 1)',
                            color: 'white',
                            fontWeight: '500',
                            fontSize: '14px',
                            width: '55px',
                            height: '36px',
                            borderRadius: '5px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {'Save'}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                <FormControlLabel
                  value="otherlink"
                  className="modal-radio-styling"
                  control={
                    <Radio
                      style={{ color: colors.darkOceanBlue }}
                      checked={redirectOption === 'otherlink'}
                      onChange={() => {
                        setEmailValue(validEmailAddress);
                        setredirectOption('otherlink');
                      }}
                    />
                  }
                  label={<span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Other Link</span>}
                />
                {redirectOption === 'otherlink' &&
                  (!showOtherUrl && messageRedirection?.externalLink === undefined ? (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ width: '75%' }}>
                        <TextField
                          name="FirstName"
                          className="input-field"
                          id="outlined-basic"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={otherLink}
                          placeholder="Please enter URL for redirection"
                          // error={Boolean(errors?.FirstName)}
                          // helperText={errors?.FirstName}
                          onChange={e => {
                            var pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

                            if (pattern.test(e.target.value)) {
                              setvalidUrl(e.target.value);
                              setShowUrlError(false);
                            } else {
                              setShowUrlError(true);
                            }
                            setOtherLink(e.target.value);
                            // setFieldValue('profilePageViewModel', {
                            //   ...profilePageViewModel,
                            //   messageRedirection: {
                            //     ...profilePageViewModel.messageRedirection,
                            //     redirectionType: 'otherlink',
                            //     email: null,
                            //     externalLink: e.target.value,
                            //   },
                            // });
                          }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              // Navigate to the new page
                              setFieldValue('profilePageViewModel', {
                                ...profilePageViewModel,
                                messageRedirection: {
                                  ...profilePageViewModel.messageRedirection,
                                  redirectionType: 'otherlink',
                                  email: null,
                                  externalLink: otherLink,
                                },
                              });
                              setshowOtherUrl(false);
                            }
                          }}
                          InputProps={{
                            style: { height: '36px', fontSize: '12px' },
                          }}
                        />
                        {showUrlError && (
                          <CommonErrorMessage color={'red'} align="left" message="Please enter valid URL" />
                        )}
                      </div>
                      <Button
                        disabled={showUrlError}
                        onClick={() => {
                          setFieldValue('profilePageViewModel', {
                            ...profilePageViewModel,
                            messageRedirection: {
                              ...profilePageViewModel.messageRedirection,
                              redirectionType: 'otherlink',
                              email: null,
                              externalLink: otherLink,
                            },
                          });
                          setshowOtherUrl(false);
                        }}
                        style={{
                          backgroundColor: showUrlError ? '#E7E7E7' : 'rgba(201, 179, 130, 1)',
                          color: 'white',
                          fontWeight: '500',
                          fontSize: '14px',
                          width: '55px',
                          height: '36px',
                          borderRadius: '5px',
                          textTransform: 'capitalize',
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  ) : messageRedirection?.externalLink != null && !showOtherUrl ? (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <p style={{ fontSize: '16px', fontWeight: '800', fontFamily: 'Avenir', color: 'black' }}>
                        {otherLink}
                      </p>
                      <p
                        onClick={() => {
                          setshowOtherUrl(true);
                        }}
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          fontFamily: 'Avenir',
                          color: 'rgba(201, 179, 130, 1)',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ width: '75%' }}>
                        <TextField
                          name="FirstName"
                          className="input-field"
                          id="outlined-basic"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={otherLink}
                          placeholder="Please enter URL for redirection"
                          // error={Boolean(errors?.FirstName)}
                          // helperText={errors?.FirstName}
                          onChange={e => {
                            var pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

                            if (pattern.test(e.target.value)) {
                              setvalidUrl(e.target.value);
                              setShowUrlError(false);
                            } else {
                              setShowUrlError(true);
                            }
                            setOtherLink(e.target.value);
                          }}
                          InputProps={{
                            style: { height: '36px', fontSize: '12px' },
                          }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              // Navigate to the new page
                              setshowOtherUrl(false);
                              setFieldValue('profilePageViewModel', {
                                ...profilePageViewModel,
                                messageRedirection: {
                                  ...profilePageViewModel.messageRedirection,
                                  redirectionType: 'otherlink',
                                  email: null,
                                  externalLink: otherLink,
                                },
                              });
                            }
                          }}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              setshowOtherUrl(false);
                              setFieldValue('profilePageViewModel', {
                                ...profilePageViewModel,
                                messageRedirection: {
                                  ...profilePageViewModel.messageRedirection,
                                  redirectionType: 'otherlink',
                                  email: null,
                                  externalLink: otherLink,
                                },
                              });
                              event.preventDefault(); // Prevent the default behavior of submitting the form
                              // Optionally, you can add your custom logic here
                            }
                          }}
                        />
                        {showUrlError && (
                          <CommonErrorMessage color={'red'} align="left" message="Please enter valid URL" />
                        )}
                      </div>
                      <Button
                        disabled={showUrlError}
                        onClick={() => {
                          setshowOtherUrl(false);
                          setFieldValue('profilePageViewModel', {
                            ...profilePageViewModel,
                            messageRedirection: {
                              ...profilePageViewModel.messageRedirection,
                              redirectionType: 'otherlink',
                              email: null,
                              externalLink: otherLink,
                            },
                          });
                        }}
                        style={{
                          backgroundColor: showUrlError ? '#E7E7E7' : 'rgba(201, 179, 130, 1)',
                          color: 'white',
                          fontWeight: '500',
                          fontSize: '14px',
                          width: '55px',
                          height: '36px',
                          borderRadius: '5px',
                          textTransform: 'capitalize',
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  ))}
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item style={{ borderBottom: 'none' }} className="branding-container profile-section">
        <Grid item xs={12}>
          <div className="heading">
            <p style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}>
              Footer & Privacy Policy
            </p>
          </div>
          <div>
            <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>
              Only services enabled on this page will appear in your footer, organized by type (e.g., 1:1, group
              courses, communities, memberships, workshops), with your privacy policy included if attached.
            </p>
          </div>
        </Grid>
        <Grid className="footer-settings" item xs={12}>
          <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Website Footer</p>
          <ToggleButtonGroup
            className="toggle-button"
            color="primary"
            value={isFooterEnabled ? 'enable' : 'disable'}
            exclusive
            onChange={() => {
              setFieldValue('profilePageViewModel', {
                ...profilePageViewModel,
                isFooterEnabled: !isFooterEnabled,
              });
            }}
          >
            <ToggleButton value="enable">Enable</ToggleButton>
            <ToggleButton value="disable">Disable</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {isFooterEnabled && (
          <>
            {privacyPolicyFileName ? (
              <Grid item xs={12} style={{ display: 'flex', marginTop: '23px', justifyContent: 'space-between' }}>
                <span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350, color: 'black' }}>
                  Privacy Policy
                  <Tooltip
                    title="Attach your privacy policy to display it in your website footer, Service landing pages, and Lead Magnets."
                    arrow
                  >
                    <InfoIcon
                      style={{ marginLeft: '5px', color: '#D1B989', height: '20px', cursor: 'pointer' }}
                      htmlColor=""
                    />
                  </Tooltip>
                </span>
                <PrivacyPolicyContainer>
                  <FiFileText style={{ width: '24px', height: '24px' }} />
                  <PrivacyPolicyName style={{ maxWidth: '56%' }} title={privacyPolicyFileName}>
                    {privacyPolicyFileName}
                  </PrivacyPolicyName>
                  <IconButton
                    style={{ padding: '0px', color: 'black', height: '9px' }}
                    onClick={() => {
                      setFieldValue('profilePageViewModel', {
                        ...profilePageViewModel,
                        privacyPolicyFileName: '',
                        privacyPolicyUrl: '',
                      });
                      editHandler();
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </PrivacyPolicyContainer>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ display: 'flex', marginTop: '1rem', gap: '4px', placeContent: 'space-between' }}
                alignItems="center"
              >
                <span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350, color: 'black' }}>
                  Privacy Policy
                  <Tooltip
                    title="Attach your privacy policy to display it in your website footer, Service landing pages, and Lead Magnets."
                    arrow
                  >
                    <InfoIcon
                      style={{ marginLeft: '5px', color: '#D1B989', height: '20px', cursor: 'pointer' }}
                      htmlColor=""
                    />
                  </Tooltip>
                </span>
                <div>
                  {!uploading && (
                    <>
                      <input type="file" style={{ marginBottom: '10px', display: 'none' }} />
                      <button
                        type="button"
                        onClick={() => uploadFooterRef.current.click()}
                        style={{
                          borderRadius: '4px',
                          border: '1px #E7E7E7 solid',
                          padding: '8px 0px',
                          height: '38px',
                          width: '144px',
                          background: 'white',
                          color: '#215C73',
                          fontWeight: '800',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          id="file-upload"
                          ref={uploadFooterRef}
                          type="file"
                          accept="application/pdf"
                          style={{ display: 'none', cursor: 'pointer' }}
                          onChange={handleUploadFile}
                        />
                        <AttachFileIcon style={{ color: '#215C73', height: '22px' }} />
                        <span>Attach a File</span>
                      </button>
                    </>
                  )}
                  {uploading && (
                    <div style={{ width: '144px', height: '38px', textAlign: 'center', alignContent: 'center' }}>
                      {' '}
                      <CircularProgress style={{ marginLeft: '10px' }} size={20} />
                    </div>
                  )}
                </div>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

Settings.propTypes = {
  values: PropTypes.shape({
    profilePageViewModel: {
      isMessagingEnabled: PropTypes.bool,
    },
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  editHandler: PropTypes.func.isRequired,
};

export default Settings;
