import { React, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCloseDisabled, setNotesStatus, setShowSavingAnimation } from 'actions/contributions';
import { updateClientNotes } from 'services/clients.service';
import { useHttp } from 'hooks/index';

const useSaveOnTypping = () => {
  const dispatch = useDispatch();
  const { request } = useHttp();

  const saveOnTyping = async ({ clientId, userId, note }) => {
    dispatch(setNotesStatus('saving'));
    dispatch(setShowSavingAnimation(true));
    dispatch(setCloseDisabled(true));

    try {
      await updateClientNotes({ clientId, userId, note: note || '' });
      setTimeout(() => {
        dispatch(setNotesStatus('saved'));
        dispatch(setCloseDisabled(false));
      }, 1000);
    } catch (error) {
      dispatch(setNotesStatus(''));
    }
  };

  const saveNotesOnTyping = ({
    isPrerecorded,
    notesMeta,
    title,
    editorDataUpdated,
    contributionId,
    classId,
    subClassId,
  }) => {
    dispatch(setNotesStatus('saving'));
    dispatch(setShowSavingAnimation(true));
    dispatch(setCloseDisabled(true));
    if (isPrerecorded) {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
            subClassId,
            isPrerecorded,
          });

      noteRequest
        .then(() => {
          setTimeout(() => {
            dispatch(setNotesStatus('saved'));
            dispatch(setCloseDisabled(false));
          }, 1000);
        })
        .catch();
    } else {
      const noteRequest = notesMeta?.id
        ? request('/api/Note', 'PUT', {
            id: notesMeta?.id,
            contributionId,
            classId,
            title: notesMeta?.title || title,
            textContent: editorDataUpdated,
          })
        : request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: editorDataUpdated,
          });

      noteRequest
        .then(() => {
          setTimeout(() => {
            dispatch(setNotesStatus('saved'));
            dispatch(setCloseDisabled(false));
          }, 1000);
        })
        .catch();
    }
  };

  return {
    saveOnTyping,
    saveNotesOnTyping,
  };
};

export default useSaveOnTypping;
