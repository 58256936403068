import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { updateClientNotes } from 'services/clients.service';
import NotesModal from 'components/Notes/NotesModal';
import useAccount from 'hooks/useAccount';
import useClientNote from 'hooks/useClientNote';
import { Client } from './Client';
import { ClientResponsive } from './ClientResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseDisabled, setNotesStatus } from 'actions/contributions';
import useSaveOnTypping from 'pages/ContributionView/components/NoteModal/hooks/useSaveOnTypping';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const Clients = ({ rows }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [clientId, setClientId] = useState();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const closeDisabled = useSelector(state => state?.contributions?.closeDisabled);
  const { saveOnTyping } = useSaveOnTypping();
  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    isNoteExist,
  } = useClientNote(clientId);
  const handleSubmit = () => {
    setLoadingNotes(true);
    updateClientNotes({ clientId, userId: user.id, note: editorDataUpdated || '' }).finally(() => {
      setLoadingNotes(false);
      setClientId(null);
      setShowNotes(false);
      dispatch(setNotesStatus(''));
      dispatch(setCloseDisabled(false));
    });
  };

  useEffect(() => {
    if (closeDisabled) {
      saveOnTyping({ clientId: clientId, userId: user.id, note: editorDataUpdated || '' });
    }
  }, [editorDataUpdated]);

  return (
    <>
      {mobileView ? (
        rows.map((row, index) => <ClientResponsive row={row} index={index} setClientId={setClientId} />)
      ) : (
        <StyledTableContainer component={Paper} mobileView={mobileView}>
          <Table className="applications-table" aria-label="applications-table">
            <TableHead>
              <TableRow>
                <BoldTableCell>Client</BoldTableCell>
                <BoldTableCell>Recent Contribution</BoldTableCell>
                <BoldTableCell>Revenue Earned</BoldTableCell>
                <BoldTableCell align="center">Chat</BoldTableCell>
                <BoldTableCell align="center">Client Notes</BoldTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <Client row={row} index={index} setClientId={setClientId} />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setLoading={setLoadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={setClientId}
        onSave={() => {
          handleSubmit();
        }}
        isNoteExist={isNoteExist}
      />
    </>
  );
};
Clients.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};
Clients.defaultProps = {
  rows: [],
};
export default Clients;
